import Plugin from "../../plugin";
import SessionStorage from './session-storage';

class SessionStoragePlugin extends Plugin {

    static install(Vue) {
        Vue.prototype.$sessionStorage = new SessionStorage();
    }

    constructor() {
        super();
    }
}

export default SessionStoragePlugin;
