export default {
  'wp-person-list': {
    name: 'Person list management',
    labels: {
      name: 'Full name',
      birthDate: 'Birth date',
      organization: 'Organization',
      drawerLabel: 'Person',
      isCurrent: 'Current person',
      placeholderSearchBar: 'Search by full name or organization',
    },
  },
}
