const PermissionsTranslations = {
  header: "Permissions",
  labels: {
    titles: {
      services: "Services",
      permissionRules: "Permission Rules",
      createRule: "Create rule",
      editRule: "Edit rule",
    },
    name: "Rule name",
    autoGenName: "Autogenerated name",
    isAllOrganizationsAvailable: "Available for all organizations",
    organization: "Available for certain organization",
    roleGroups: "Available for role groups",
    isAddedRoleWithoutGroups: "Added roles without group",
    roles: "Available for roles",
    isAllUsersAvailable: "Available for all users",
    user: "Available for certain user",
    visionType: "Vision type of entities types",
    visionTypeValues: {
      member: "Only user's organization entities",
      all: "All system entities",
      owner: "Only created by user",
    },
    action: "Available actions",
    isAllResourcesAvailable: "Available all entities of this service",
    resource: "Available certain entities of this service",
    save: "Save",
    dialogs: {
      deleteRuleDialog: {
        text: "This action can not be cancelled",
        title: "Delete rule?",
      },
      closeRuleFormDialog: {
        text: "All unsaved changes will be lost",
        title: "Close?",
      },
    },
  },
  errorMessages: {
    role_group: {
      required: "Please, fill this field or select roles or user",
    },
    role: {
      required: "Please, fill this field or select role groups or user",
    },
    user: {
      required: "Please, fill this field or select role groups or roles",
      requiredRoles: "Please, select roles of selected role groups at first",
    },
    required: "Value is required",
    maxLength: "Length must be less than {0} characters",
  },
  infoMessages: {
  },
}

export default PermissionsTranslations
