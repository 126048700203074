export default {
  'wp-vehicle-list': {
    name: 'Управление списками ТС',
    labels: {
      name: 'Транспортное средство',
      licensePlate: 'Номер',
      brand: 'Марка/Модель',
      color: 'Цвет',
      bodyType: 'Тип кузова',
      organization: 'Организация',
      drawerLabel: 'Транспортное средство',
      isCurrent: 'Текущее ТС',
      placeholderSearchBar: 'Искать по номеру, марке/модели или организации',
    },
  } 
}
