<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="color"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="color"
                :label="label"
                readonly
                :outlined="outlined"
                :clearable="clearable"
                v-bind="attrs"
                v-on="on"
                class="v-color-field"
            >
                <template v-slot:append>
                    <v-icon class="v-color-field__icon" :style="`color : ${color}`">mdi-palette</v-icon>
                </template>
            </v-text-field>
        </template>
        <div class="v-color-field__menu">
            <v-color-picker
                v-model="color"
                show-swatches
            />
            <div class="v-color-field__menu__buttons">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(color)"
                >
                    {{ $t('common.dialog.ok') }}
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                    {{ $t('common.dialog.cancel') }}
                </v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
export default {
    name : 'v-color-field',

    props : {
        value : {
            type    : String,
            default : ''
        },
        label : {
            type    : String,
            default : ''
        },
        outlined : {
            type    : Boolean,
            default : false
        },
        clearable : {
            type    : Boolean,
            default : false
        },
    },

    data() {
        return {
            menu  : false,
            color : null
        }
    },

    watch : {
        value : {
            handler(value) {
                this.color = value;
            },
            immediate : true
        },
        color : {
            handler(value) {
                this.$emit('input', value);
            },
            immediate : true
        }
    }
}
</script>

<style lang="less">
.v-color-field__menu {
    .v-color-field__menu__buttons {
        display: flex;
        background-color: white;
    }
}

.v-color-field {
    .v-input__append-inner {
        margin: auto!important;
        .v-color-field__icon {
            margin: auto!important;
            font-size: 32px!important;
        }
    }
}
</style>