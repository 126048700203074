import Vue from "vue";

const PREFIX = 'Panel';
const PANEL_EVENT_OPEN = `${PREFIX}:Open`;

class PanelApi {
  constructor() {
    this.events = {
      OPEN_COMPONENT: `${PANEL_EVENT_OPEN}:Component`,
      OPEN_WIDGET: `${PANEL_EVENT_OPEN}:Widget`,
      CLOSE: `${PREFIX}:Close`,
      CLOSE_ALL: `${PREFIX}:CloseAll`,
      SET_TITLE: `${PREFIX}:SetTitle`,
      SET_ACTIONS: `${PREFIX}:SetActions`,
      DATA_CHANGED: `${PREFIX}:DataChanged`,
    };
  }

  openComponent(component, props, settings = {}) {
    this.$eventBuss.emit(
      this.events.OPEN_COMPONENT,
      { component, props: this._getProps(component, props), settings: this._getSettings(settings) }
    );
  }

  openWidget(activeWidget, settings = {}) {
    this.$eventBuss.emit(
      this.events.OPEN_WIDGET,
      { activeWidget, settings: this._getSettings(settings) }
    );
  }

  close(force = false) {
    this.$eventBuss.emit(this.events.CLOSE, { force });
  }

  closeAll(force = false) {
    this.$eventBuss.emit(this.events.CLOSE_ALL, { force });
  }

  _setTitle(id, title) {
    this.$eventBuss.emit(this.events.SET_TITLE, { id, title })
  }

  _setActions(id, actions) {
    this.$eventBuss.emit(this.events.SET_ACTIONS, { id, actions })
  }

  _dataChanged(id, value) {
    this.$eventBuss.emit(this.events.DATA_CHANGED, { id, value })
  }

  _getProps(component, props) {
    const systemProps = {
      closePanel: () => this.close(true),
      setTitle: (title) => this._setTitle(component.name, title),
      setActions: (actions) => this._setActions(component.name, actions),
      dataChanged: (value) => this._dataChanged(component.name, value),
    };
    return { ...props, ...systemProps };
  }

  _getSettings(settings) {
    const defaultSettings = { position: 'right', size: 30 };
    return { ...defaultSettings, ...settings };
  }

  get $eventBuss() {
    return Vue.prototype.$eventBuss;
  }
}

export default PanelApi;
