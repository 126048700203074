import RolesTranslation from "./roles";
import CatalogTranslations from "./catalogs";
import EssenceTranslations from "./essences";
import IntegrationTranslations from "./integrations";
import SubscriptionTranslations from "./subscriptions";
import UserTranslations from "./users";
import DesktopTranslations from "./desktops";
import WidgetTranslations from "./widgets";
import AlgorithmsTranslations from "./algorithms";

import LogoSettingsTranslations from "./logo-settings";
import PermissionsTranslations from "./permissions";
import CheckpointsTranslations from "./checkpoints";
import IconListTranslations from "./icon-list";
import ScenariosTranslations from "./scenarios";
import EventCatalogsTranslations from "./event-catalogs";
import RoleGroupsTranslation from "./role-groups";
import OrganizationTranslations from "./organizations";
import EventReceiverSettingsTranslations from "./event-receiver-settings";
import ReportConfiguratorTranslation from "./report-configurator";
import MetadataTranslations from "./metadata";
import DesktopGroupsTranslations from "./desktopgroups";

const AdminTranslations = {
  admin: {
    inputs: {
      name: "Входные параметры",
      notSelected: "Не выбрано",
      useEvents: "Использовать события как источник",
      eventPrompt: "Выберите событие"
    },
    outputs: "Выходные параметры",
    selectIcon: "Выбрать иконку",
    roles: {
      ...RolesTranslation,
    },
    roleGroups: {
      ...RoleGroupsTranslation,
    },
    catalogs: {
      ...CatalogTranslations,
    },
    essences: {
      ...EssenceTranslations,
    },
    integrations: {
      ...IntegrationTranslations,
    },
    subscriptions: {
      ...SubscriptionTranslations,
    },
    users: {
      ...UserTranslations,
    },
    desktops: {
      ...DesktopTranslations,
    },
    desktopgroups: {
      ...DesktopGroupsTranslations,
    },
    widgets: {
      ...WidgetTranslations,
    },
    algorithms: {
      ...AlgorithmsTranslations
    },
    logoSettings: {
      ...LogoSettingsTranslations
    },
    eventReceiverSettings: {
      ...EventReceiverSettingsTranslations
    },
    permissions: {
      ...PermissionsTranslations
    },
    checkpoints: {
      ...CheckpointsTranslations
    },
    iconlist: {
      ...IconListTranslations
    },
    scenarios: {
      ...ScenariosTranslations
    },
    eventCatalogs: {
      ...EventCatalogsTranslations
    },
    organizations: {
      ...OrganizationTranslations
    },
    reportConfigurator: {
      ...ReportConfiguratorTranslation
    },
    metadata: {
      ...MetadataTranslations
    },
  }
}

export default AdminTranslations
