const EventCatalogsTranslations = {
  header: "События",
  remove: "Удалить элемент?",
  add: "Добавить элемент",
  create: "Создание",
  edit: "Редактирование",
  save: "Сохранить",
  cancel: "Отмена",
  searchPanel: {
    search: "Поиск"
  },
  columns: {
    id: "ID",
    name: "Название",
    nameRu: "Название [ru]",
    nameEn: "Название [en]",
    params: "Параметры",
    sys_name: "Системное имя",
    description: "Описание",
    alarmLevel: "Уровень тревоги",
    color: "Цвет",
    showEvent: "Показывать событие",
  },
  labels: {
    name: "Название события",
  },
  buttons: {
    save: "Сохранить",
    cancel: "Отменить",
    exit: "Выйти",
    back: "Назад",
  },
  eventTypeList: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      id: {
        text: "ID",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Редактировать тип событий {0}",
      remove: "Удалить тип событий {0}",
    },
    remove: "Удалить тип событий?",
  },
  eventList: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      params: {
        text: "Параметры",
        sortable: false,
      },
      sys_name: {
        text: "Системное имя",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Редактировать событие {0}",
      remove: "Удалить событие {0}",
    },
    remove: "Удалить событие?",
  },
  errors: {
    required: "Обязательное поле",
    alpha: "Значение должно состоять только из латинских символов",
    uniqueId: "Тип событий с таким ID уже существует",
    uniqueName: "Тип событий с таким именем уже существует",
  },
}

export default EventCatalogsTranslations

