const MetadataTranslations = {
  header: "Metadata editor",
  remove: "Remove element?",
  add: "Add element",
  create: "Create",
  edit: "Edit",
  data: "Data",
  ok: "Ok",
  save: "Save",
  cancel: "Sancel",
  view: "View",
  searchPanel: {
    search: "Search"
  },
  columns: {
    id: "ID",
    name: 'Name',
    meta_name: "Meta name",
    type_service: "Service type",
    type_object: "Object type",
    type_meta: "Metadata type",
    fields: "Fields",
    params: "Params",
    combine: "Combine",
    settings: "Form settings",
    locales: "Translations",
    ui: "UI settings",
  },
  labels: {
    name: "Name",
  },
  buttons: {
    data: "Data",
    ok: "Ok",
    save: "Save",
    cancel: "Sancel",
    exit: "Exit",
    back: "Back",
  },
  params: {},
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      view: "View",
      edit: "Edit element",
      remove: "Remove element",
    }
  },
  errors: {
    required: "Required field",
  },
}

export default MetadataTranslations
