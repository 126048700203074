import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.color,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.color=$event},"update:return-value":function($event){_vm.color=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({staticClass:"v-color-field",attrs:{"label":_vm.label,"readonly":"","outlined":_vm.outlined,"clearable":_vm.clearable},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VIcon,{staticClass:"v-color-field__icon",style:(`color : ${_vm.color}`)},[_vm._v("mdi-palette")])]},proxy:true}],null,true),model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c('div',{staticClass:"v-color-field__menu"},[_c(VColorPicker,{attrs:{"show-swatches":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}),_vm._v(" "),_c('div',{staticClass:"v-color-field__menu__buttons"},[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.color)}}},[_vm._v("\n                "+_vm._s(_vm.$t('common.dialog.ok'))+"\n            ")]),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("\n                "+_vm._s(_vm.$t('common.dialog.cancel'))+"\n            ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }