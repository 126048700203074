import Vue from 'vue';

function translate(value) {
  if (!value) {
    return '';
  }
  if (value.startsWith('$t.')) {
    return Vue.prototype.$t(value.substring(3));
  }
  return value;
}

export default { translate };
