const MetadataTranslations = {
  header: "Редактор метаданных",
  remove: "Удалить элемент?",
  add: "Добавить элемент",
  create: "Создание",
  edit: "Редактирование",
  data: "Данные",
  ok: "Ок",
  save: "Сохранить",
  cancel: "Отмена",
  view: "Просмотр",
  searchPanel: {
    search: "Поиск"
  },
  columns: {
    id: "ID",
    name: 'Название',
    meta_name: "Мета название",
    type_service: "Тип сервиса",
    type_object: "Тип объекта",
    type_meta: "Тип метаданных",
    fields: "Список полей",
    params: "Параметры",
    combine: "Сборка полей",
    settings: "Настройки формы",
    locales: "Переводы",
    ui: "Настройки UI",
  },
  labels: {
    name: "Название",
  },
  buttons: {
    data: "Данные",
    ok: "Ок",
    save: "Сохранить",
    cancel: "Отменить",
    exit: "Выйти",
    back: "Назад",
  },
  params: {},
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      view: "Просмотр",
      edit: "Редактировать элемент",
      remove: "Удалить элемент",
    }
  },
  errors: {
    required: "Обязательное поле",
  },
}

export default MetadataTranslations
