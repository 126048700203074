export default {
  "wp-controller-board": {
    name: "Controller board",
    settings: {
      cellNumber: "Cell number",
    },
    errors: {
      emptyCell: "Cell number isn't selected",
      serverError: "Server error",
      dataError: "Invalid data"
    }
  }
}
