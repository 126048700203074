const UserTranslations = {
  header: "Пользователи",
  table: {
    headers: {
      username: {
        text: "Логин",
        sortable: true,
      },
      ref_person: {
        text: "ФИО",
        sortable: true,
      },
      ref_organization: {
        text: "Организация",
        sortable: true,
      },
      email: {
        text: "Электронная почта",
        sortable: true,
      },
      ref_roles: {
        text: "Роли",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Удалить пользователя {0}",
    }
  },
  columns: {
    organization: "Организация",
    person: "Персона",
    email: "Электронная почта",
    username: "Логин",
    roles: "Роли",
    disabled: "Заблокирован",
    admin: "Администратор",
    password: "Пароль",
    person_name: "ФИО",
    firstName: "Имя",
    lastName: "Фамилия",
    middleName: "Отчество",
    fullscreen: "Полноэкранный режим",
    defaultDesktop: "Рабочий стол по умолчанию",
    trusted: "Доверенный пользователь",
  },
  add: "Создание",
  edit: "Редактирование",
  remove: "Удалить пользователя?",
  save: "Сохранить",
  cancel: "Отмена",
  searchPanel: {
    search: "Поиск"
  },
  errors: {
    required: "Обязательное поле",
    email: "Некорректный email",
    uniqueEmail: "Пользователь с таким email уже существует",
    uniqueLogin: "Пользователь с таким логином уже существует",
    uniquePerson: "Пользователь с такой персоной уже существует",
    withoutSpaces: "Не допускается использовать пробельные символы",
    validatePassword: "Длина пароля 6 - 64 символа. Пароль может содержать символы: !#@$^()_*",
    passwordNotChanged: "Не удалось изменить пароль. Пожалуйста, проверьте правильность ввода старого пароля и попробуйте еще раз",
  },
  changePassword: "Изменить пароль",
  passwordChangedSuccess: "Пароль успешно изменен",
  newPassword: "Новый пароль",
  oldPassword: "Старый пароль",
  dialogs: {
    changePerson: {
      title: "Вы уверены, что хотите изменить персону?",
      bodyMsg: "Данные с полей: {0} будут потеряны.",
    },
  },
  options: {
    itemsPerPage: "Записей на странице",
  },
}

export default UserTranslations
