export default {
  "wp-events-event-log": {
    name: "Event log",
    options: {
      itemsPerPage: "Items per page",
    },
    table: {
      unknownEvent: "Unknown event",
      format: "HH-mm, YYYY-DD-MM",
      headers: {
        time: "Date and time",
        typeEvent: "Type",
        directObj: "Object",
        subject: "Subject",
        description: "Description",
        location: "Location",
        alarm_level: "Alarm",
        file: "File",
      },
    },
    components: {
      datePicker: {
        buttons: {
          todayBtn: "Today",
          weekBtn: "Week",
          monthBtn: "Month",
          anotherPeriodBtn: "Other period",
          applyBtn: "Apply",
        },
        labels: {
          startDate: "From date",
          endDate: "To date",
          allTime: "For all time",
          from: "From",
          to: "To",
        },
        format: "YYYY-DD-MM",
      },
    },
    labels: {
      placeholderSearchBar: "Enter text for find",
    },
    settings: {
      header: "Header",
      eventSettings: 'Event settings',
      directObjs: "Objects",
      subjects: "Subjects",
      locations: "Locations",
      needToCount: "Show total count of events",
      needToCountHint: "Receiving events will slow down",
    },
    inputs: {
      refreshList: "Refresh event list",
    }
  },
};
