export default {
  "wp-contract-card": {
    name: "Contract card",
    title: {
      new: "New contract",
      update: "Update contract",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Details",
        name: "Contract name",
        number: "Contract number",
        customer: "Customer",
        contractor: "Organization",
        period: "Contract validity period",
        active: "Active contract",
        note: "Note",
        responsiblePerson: "Responsible person",
        isContractWithoutTime: "Indefinite contract",
      },
    },
    preferences: {
      dateFormat: "YYYY-DD-MM",
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        min: "The date must be no earlier than 01/01/1900",
        hasResponsiblePersonEmail: "Responsible person must have email",
      },
    },
  }
};
