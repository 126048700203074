export default {
  'wp-verification-panel': {
    name: 'Verification panel',
    settings: {
      header: 'Header',
      eventSettings: 'Event settings',
      inactiveImage: 'Image on waiting state',
      inactiveText: 'Message on waiting state',
      timeout: 'Message timeout (ms)',
      maxImageSize: 'Max image size (from 13% to 80%)',
      deviceIdList: 'List of IDs source event',
    },
    inputs: {
      refreshList: 'Refresh the list of events'
    },
    rules: {
      required: 'Required value',
      isnumber: 'Required numeric value',
      positive: 'Value must be greater than zero',
      maxOneHundred: 'The value must be no more than "80"',
      minThirteen: 'Value must be greater than "13"',
      isInteger: 'Required integer value'
    },
    fields: {
      fullName: 'Full name',
      post: 'Post',
      subdivision: 'subdivision',
      name: 'Name',
      email: 'Email',
      telephone: 'Phone',
      orgName: 'Organization',
      tin: 'TIN',
      categoryEmployee: 'Category',
      numberEmployee: 'Employer number',
      iec: 'IEC',
      depName: 'Department',

      fullNameVehicle: 'Vehicle name',
      bodyType: 'Vehicle type',
      brand: 'Vehicle brand',
      color: 'Vehicle color',
      licensePlate: 'Vehicle number',
      note: 'Comment',
      ownerName: 'Vehicle owner',
      carNumber: 'Number',
      owner: 'Owner'
    },
    permitFields: {
      name: 'Name',
      startTime: 'Valid',
      endTime: 'Valid until',
      permitType: 'Permit type',
      status: 'Status',
      enter: 'Entrance',
      permitInfo: 'Permit information',
      numberAndDateOfIssue: 'Date of issue',
      emptyNumber: '"Number is empty"',
      unknownPermitStatus: "Unknown status",
    },
    categoriesEmployee: {
      Visitor: 'Visitor',
      Contractor: 'Contractor',
      Subcontractor: 'Subcontractor',
      Employee: 'Employee',
      Security: 'Security',
      Chief: 'Chief',
      Household: 'Household'
    },
    statuses: {
      Active: 'Active',
      Block: 'Block',
      Deactivated: 'Deactivated'
    },
    accessGranted: 'Access granted',
    accessDenied: 'Access denied',
    permitLabel: 'Permit info',
    from: 'from',
    with: 'with',
    until: 'until',
    in: 'in',
    time: "Time",
    source: "Event source",
    location: "Location",
    unknownObject: "Unknown Object",
  }
}
