import Plugin from "../../plugin";
import EventBus from './event-bus';

class EventBusPlugin extends Plugin {

    static install(Vue) {
      const eventBus = new EventBus();
      Vue.prototype.$eventBuss = eventBus
      Vue.prototype.$serverRequest = async function(type, request, options = {}) {
        return await eventBus.serverRequest(type, request, options)
      }
    }

    constructor() {
        super();
    }
}

export default EventBusPlugin;
