export default {
  "wp-my-org": {
    name: "My organization",
    contractor: 'You are working on behalf of {0}',
    exit: 'Exit',
    removeTitle: 'Approve',
    buttons: {
      add: 'Add',
      select: 'Select from list'
    },
    tabs: {
      contractors: "Contractors",
      persons: "Persons",
      vehicles: "Vehicles",
      contracts: "Contracts",
      files: "Files",
    },
    table: {
      contractors: {
        headers: {
          name: {
            text: "Name",
            sortable: true,
          },
          tin: {
            text: "TIN",
            sortable: false,
          },
          contracts: {
            text: "Contracts",
            sortable: false,
          },
        },
        item: {
          remove: "Are you sure you want to unlink contractor?",
          actionsTips: {
            enterAsContractor: "Enter as contractor {0}",
            removeItem: 'Unlink {0}',
          },
          format: "MM-DD-YYYY",
          contractField: "From {0} to {1}",
          checkFailed: 'Cannot remove contractor with current contract'
        }
      },
      persons: {
        headers: {
          name: {
            text: "Full name",
            sortable: true,
          },
          hasUser: {
            text: "Has user",
            sortable: false,
          },
          position: {
            text: "Position",
            sortable: false,
          },
          securityList: {
            text: "Access list",
            sortable: false,
          },
          resident: {
            text: "Citizen of RF",
            sortable: false,
          },
        },
        item: {
          remove: "Are you sure you want to delete person?",
          removeLinkedUser: "Are you sure you want to delete user account?",
          actionsTips: {
            removeItem: "Remove {0}",
            removeUser: "Remove linked user",
            addUser: "Create user account"
          }
        }
      },
      vehicles: {
        headers: {
          licensePlate: {
            text: "License plate",
            sortable: false,
          },
          brand: {
            text: "Brand, model",
            sortable: false,
          },
          securityList: {
            text: "List mark",
            sortable: false,
          },
        },
        item: {
          remove: "Are you sure you want to delete vehicle?",
          actionsTips: {
            removeItem: "Remove {0}"
          }
        }
      },
      contracts: {
        headers: {
          name: {
            text: "Number",
            sortable: true,
          },
          customer: {
            text: "Customer",
            sortable: false,
          },
          contractor: {
            text: "Contractor",
            sortable: false,
          },
          responsiblePerson: {
            text: "Responsible person",
            sortable: false,
          },
          startDate: {
            text: "Start date",
            sortable: false,
          },
          endDate: {
            text: "End date",
            sortable: false,
          },
        },
        item: {
          remove: "Are you sure you want to archive contract?",
          format: "MM-DD-YYYY",
          actionsTips: {
            removeItem: "Archive {0}"
          }
        }
      },
      files: {
        headers: {
          image: {
            text: '',
            sortable: false,
            width : '10px',
          },
          name: {
            text: "Name",
            sortable: true,
          },
          size: {
            text: "Size",
            sortable: true,
          },
        },
        item: {
          format: "DD.MM.YYYY",
          remove: 'Are you sure you want to delete file?"',
          actionsTips: {
            editItem: "Rename {0}",
            downloadItem: "Download {0}",
            removeItem: "Delete {0}",
          }
        }
      },
    },
    components: {
      searchPanel: {
        search: "Search"
      }
    },
    errors: {
      contractors: {
        list: 'Cannot load contractors list',
        item: 'Cannot load contractor',
        update: 'Cannot update contractor',
        add: 'Cannot add contractor',
      },
      persons: {
        list: 'Cannot load persons list',
        item: 'Cannot load person',
        update: 'Cannot update person',
        add: 'Cannot add person',
        remove: 'Cannot remove person'
      },
      vehicles: {
        list: 'Cannot load vehicles list',
        item: 'Cannot load vehicle',
        update: 'Cannot update vehicle',
        add: 'Cannot add vehicle',
        remove: 'Cannot remove vehicle'
      },
      contracts: {
        list: 'Cannot load contracts list',
        item: 'Cannot load contract',
        update: 'Cannot update contract',
        add: 'Cannot add contract',
        remove: 'Cannot remove contract'
      },
      myOrgLoad: "Cannot load root organization's data"
    }
  }
};
