<template>
  <div class="eva-repeater-header">

    <div v-if="showCaption" class="eva-repeater-header__caption">
      {{ caption }}
    </div>

    <v-text-field
      v-if="showContext"
      v-model="options.filter.context"
      outlined
      dense
      clearable
      class="eva-repeater-header__context"
      append-icon="mdi-magnify"
    />

<!--    <eva-icon-btn
      icon="mdi-filter"
      color="#C3C3C3"
    />-->

    <eva-spacer/>
  </div>
</template>

<script>
export default {
  name: 'eva-repeater-header',

  props: {
    settings: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  computed: {
    caption() {
      return `${this.$eva.$t(`$t.${this.settings.prefix}.caption`)}`;
    },
    showCaption() {
      return this.settings.caption !== false;
    },
    showContext() {
      return this.settings.context !== false;
    }
  },

  watch: {
    'options.filter.context'() {
      this.options.filter.offset = 0;
    }
  }
}
</script>

<style lang="less">
.eva-repeater-header {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: @eva-header;
  gap: @eva-padding;
  align-items: center;
  .eva-repeater-header__caption {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .eva-repeater-header__context {
    max-width : 250px;
    margin: 0!important;
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 6px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;;
      min-height: 36px!important;
    }
    .v-text-field__details {
      display: none!important;;
    }
  }
}
</style>
