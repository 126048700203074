export default {
  "wp-permit-person-card": {
    name: "Пропуск ФЛ",
    notSet: "Не задано",
    title: {
      new: "Новый пропуск ФЛ",
      update: "Редактирование пропуска ФЛ",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      information: {
        topLabel: "Сведения",
        name: "Название",
        subject: "Субъект",
        status: "Статус",
        dates: "Даты действия",
      },
    },
    preferences: {
      dateFormat: "DD.MM.YYYY",
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        minLength: "Значение должно быть не менее {0} символов",
        maxLength: "Значение должно занимать не более {0} символов",
        correctNumber: "Некорректный номер",
      },
    },
  },
};
