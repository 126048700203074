import DataAccessAdapter from './DataAccessAdapter';

class DataAccess {
    constructor(Vue) {
        this.$http     = Vue.prototype.$http;
        this.$locale   = Vue.prototype.$locale;
        this._adapters = [];
    }

    registerAdapter(name, url, DataAccessAdapterConstructor = null) {
        if (!DataAccessAdapterConstructor) {
            DataAccessAdapterConstructor = DataAccessAdapter;
        }
        let adapter = new DataAccessAdapterConstructor(this, url);
        this._adapters.push(adapter);

        this[name] = adapter;
    }

    createAdapter(url) {
        return new DataAccessAdapter(this, url);
    }

    async get(url) {
        let res = await this.$http.get(url);
        return this.getResult(res);
    }

    async post(url, data = null) {
        let res = await this.$http.post(url, data);
        return this.getResult(res);
    }

    async put(url, data = null) {
        let res = await this.$http.put(url, data);
        return this.getResult(res);
    }

    getResult(res) {
        let data = res && res.data;
        if (!data) {
            return null;
        }

        if (data.error) {
            console.log(data.error);
            throw this.$locale.getErrorMessage(data.error);
        }

        return data;
    }
}

export default DataAccess;
