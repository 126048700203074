import Plugin from "../../Plugin";
import DataAccess from "./DataAccess";

class DataAccessPlugin extends Plugin {

    static install(Vue) {
        Vue.prototype.$da = new DataAccess(Vue);
    }

}

export default DataAccessPlugin;
