export default {
    "wp-task-list-action-buttons": {
      name: "Action buttons",
      actionButtons: {
        accept: 'Accept',
        complete: 'Complete',
        decline: 'Decline',
        pause: 'Pause'
      }
    }
  };