import Vue from "vue";
import VueI18n from "vue-i18n";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";

import "moment/locale/ru";

const LOCALES = [
  'ru',
  'en'
];
const DEFAULT_LOCALE = 'ru';

class LocalesEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);

    this.initReactive({
      data: {
        current: null
      },
      watch: {
        current(value) {
          this.$eva.$locales._i18n.locale = value;
          this.$eva.$context.updateTitle();
        }
      }
    });
  }

  install() {
    Vue.use(VueI18n);

    let messages = {};
    for (let i = 0; i < LOCALES.length; i++) {
      messages[LOCALES[i]] = {};
    }

    this._i18n = new VueI18n({
      locale: DEFAULT_LOCALE,
      fallbackLocale: DEFAULT_LOCALE,
      messages: messages,
      silentTranslationWarn: true
    });

    this.app.$t = (...args) => this.translate(...args);
    this.app.$te = (...args) => this.exists(...args);
  }

  async init() {
    this.current = DEFAULT_LOCALE;
  }

  register(locale, messages) {
    this._i18n.mergeLocaleMessage(locale, messages);
  }

  translate(key, ...params) {
    key = this.app.$tools.resolveValue(key);
    if (key && key.startsWith('$t.')) {
      params = typeof params[0] === 'object'
        ? params[0]
        : [...params];
      key = key.substring(3);
      return this._i18n.t(key, params);
    } else {
      return key;
    }
  }

  exists(key, ...params) {
    key = this.app.$tools.resolveValue(key);
    if (key) {
      params = typeof params[0] === 'object'
        ? params[0]
        : [...params];
      if (key.startsWith('$t.')) {
        key = key.substring(3);
      }
      return this._i18n.te(key, params);
    } else {
      return false;
    }
  }

  toggle() {
    let index = LOCALES.indexOf(this.current);
    if (index < 0 || (index + 1) >= LOCALES.length ) {
      this.current = LOCALES[0];
    } else {
      this.current = LOCALES[index + 1];
    }
  }
}

export default LocalesEvaPlugin;
