<template>
  <div class="eva-alert" :class="classes">
    <eva-icon :value="icon" color="white"/>
    <eva-text :text="label"/>
  </div>
</template>

<script>
export default {
  name: 'eva-alert',

  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'info'
    }
  },

  computed: {
    classes() {
      return [
        `eva-${this.type}-layout`
      ];
    },
    icon() {
      switch (this.type) {
        case 'info':
          return 'mdi-alert-circle-outline';
        case 'success':
          return 'mdi-check-circle-outline';
        case 'warning':
          return 'mdi-alert-outline';
        case 'error':
          return 'mdi-alert-octagon-outline';
      }
    }
  }
}
</script>

<style lang="less">
.eva-alert {
  display: flex;
  flex-direction: row;
  gap: @eva-padding;
  padding: @eva-padding;
}
</style>
