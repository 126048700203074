<template>
  <v-card class="app-table">
    <v-card-title class=" pa-1" v-if="tableHeader">
      <h3 class="d-flex">{{ tableHeader }}
        <v-sheet 
          color="#2196f3" 
          class="mx-2 px-3 rounded-pill" 
          style="color: #fff; border:5px"
        >
          {{ cnt }}
        </v-sheet>
      </h3>
    </v-card-title>

    <v-card-text class="app-table-content d-flex flex-column flex-grow-1 justify-space-between px-1">
      <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :no-data-text="$t('common.table.noDataText')"
        :items="items"
        :server-items-length="itemsCount"
        :loading="itemsLoading"
        :loading-text="$t('common.loading')"
        :options.sync="options"
        :sort-by="headers[0].value"
        :sort-desc="true"
        @page-count="pageCount = $event"
      >
        <template v-slot:header.actions="{}">
          <div class="app-table__actions">
            <v-btn
              v-if="enableAction('add')"
              fab
              dark
              x-small
              color="green"
              @click="addItem()"
              id="btn_add"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:item="{ item }">
          <tr @click="editItem(item)">
            <td
              v-for="header in headers"
              v-if="header.value !== 'actions'"
              class="app-table__cell"
            >
              <slot :name="`item.${header.value}`" :item="item">
                {{ item[header.value] }}
              </slot>
            </td>
            <td v-else class="app-table__cell">
              <div class="app-table__actions">
                <v-btn
                  v-if="enableAction('edit')"
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="#2196f3"
                  @click.stop="editItem(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  v-if="enableAction('delete')"
                  fab
                  dark
                  x-small
                  color="red"
                  @click.stop="removeItem(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="d-flex flex-row justify-center align-center">
        <v-pagination
          v-model="options.page"
          :length="pageCount"
          :total-visible="totalVisible"
          circle
        ></v-pagination>
        <div class="d-flex flex-row justify-center align-center page-selector">
          <v-select
            dense
            v-model="options.itemsPerPage"
            :items="pageSelects"
            outlined
            :label="this.$t(`common.table.itemsPerPage`)"
          ></v-select>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { isNil } from "lodash";

export default {
  name: "app-table",
  props: {
    resource: {},
    columns: {},
    adapter: {},
    header: null,
    needRefresh: null,
    needResetRefresh: null,
  },
  data() {
    return {
      items: [],
      itemsCount: 0,
      itemsLoading: false,
      options: {
        itemsPerPage: 20
      },
      oldItemsPerPage: 20,
      page: 1,
      pageCount: 0,
      pageSelects: [5, 10, 20, 50, 100],
      totalVisible: 7,
      error: null,
    };
  },
  computed: {
    tableHeader() {
      const headerName = `${this.resource}.header`;
      const header = this.$t(headerName)
      return isNil(this.header)? header: this.header;
    },
    cnt() {
      return this.itemsCount;
    },
    headers() {
      let result = [];
      if (this.columns && this.columns.length) {
        this.columns.forEach((column) => {
          if (column.name === "actions") {
            result.push({
              text: "",
              value: column.name,
              sortable: false,
            });
          } else {
            result.push({
              text: this.$t(`${this.resource}.columns.${column.name}`),
              value: column.name,
              sortable: column.sortable !== false,
            });
          }
        });
      }
      return result;
    },
  },
  watch: {
    options: {
      async handler() {
        // todo: изменить на адекватное отслеживание, из-за options все данные всегда стягиваются по 2 раза
        if (this.options.itemsPerPage !== this.oldItemsPerPage) {
          this.options.page = 1;
        }
        await this.loadItems();
      },
      deep: true,
    },
    needRefresh: {
      async handler() {
        await this.loadItems();
      }
    },
    needResetRefresh: {
      async handler() {
        this.options.page = 1;
        await this.loadItems();
      }
    },
  },
  async mounted() {
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.itemsLoading = true;

      const {sortBy, sortDesc, page, itemsPerPage} = this.options;

      const filter = {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        order_by: sortBy && sortBy.length? sortBy.map((key, index) => (sortDesc[index] ? `!${key}` : key)).join(","): undefined,
      };

      try {
        const {items, count, total} = await this.adapter.list(filter);
        this.items = items;
        this.itemsCount = parseInt(total);
        this.pageCount = 1 + (itemsPerPage > 0? parseInt(total / itemsPerPage): 0);
        this.oldItemsPerPage = itemsPerPage;
      } catch (error) {
        this.error = error;
      }

      this.itemsLoading = false;
    },
    addItem() {
      let done = async ({item}) => {
        if (!item) {
          return;
        }

        try {
          this.error = null;
          await this.adapter.add(item);
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      };

      this.$emit("add", {done});
    },
    editItem(item) {
      let done = async ({item}) => {
        if (!item) {
          return;
        }

        try {
          this.error = null;
          await this.adapter.update(item);
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      };

      this.$emit("edit", {item: Object.assign({}, item), done});
    },
    async removeItem(item) {
      if (!item) {
        return;
      }

      if (await this.$root.$confirm(`${this.resource}.remove`)) {
        try {
          this.error = null;
          await this.adapter.remove(item);
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
        document.activeElement.blur();
      }
    },
    enableAction(name) {
      let actions =
        this.columns && this.columns.find((c) => c.name === "actions");
      return actions && actions[name] !== false;
    },
  },
};
</script>

<style lang="less">
.app-table {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: none !important;

  .app-table-content {
    display: flex;
    flex-direction: column;
    flex: fit-content;
    overflow: hidden;
  }

  .page-selector {
    width: 250px !important;
  }

  .v-data-table {
    height: 100%;
  }

  .v-data-table__wrapper {
    height: 100%;
    overflow-y: auto !important;
  }


  th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .app-table-pagination {
    margin-top: auto;
  }

  .app-table__cell {
    cursor: pointer;
  }

  .app-table__actions {
    display: flex;
    justify-content: flex-end;
  }

  .v-btn {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
