<template>
  <div class="eva-table">
    <div v-if="showToolbar" class="eva-table__toolbar">
      <div v-if="showHeader" class="eva-table__header">
        {{ header }}
      </div>

      <v-chip v-if="showTotal" class="eva-table__total">
        {{ itemsTotal }}
      </v-chip>

      <v-text-field
        v-if="showContext"
        v-model="context"
        outlined
        dense
        clearable
        class="eva-table__context"
        prepend-inner-icon="mdi-magnify"
      />

      <eva-icon-btn
        icon="mdi-filter"
        color="#C3C3C3"
        @click="showFilter"
      />

      <v-spacer/>

      <div v-if="enableAction('add') || download" class="app-table__actions" style="margin-right: 26px">

        <eva-icon-btn
          v-if="enableAction('add')"
          icon="mdi-plus"
          color="#4CAF50"
          @click="addItem()"
        />

        <eva-icon-btn
          v-if="download"
          icon="mdi-download"
          color="#2196F3"
          @click="downloadItems"
        />

      </div>

    </div>

    <eva-alert v-if="error" :label="error" type="error" class="eva-table__alert"/>

    <v-data-table
      dense
      hide-default-footer
      :headers="columns"
      :no-data-text="$eva.$t('$t.core.tables.noData')"
      :items="items"
      :loading="itemsLoading"
      :loading-text="$eva.$t('$t.core.tables.loading')"
      :options.sync="options"
      :server-items-length="itemsTotal"
      @page-count="pageCount = $event"
      item-key="id"
      fixed-header
      class="eva-table__content"
    >

      <template v-slot:item="data">
        <tr @click="editItem(data.item)" :class="itemColor" :style="{ 'background-color' : data.item === selected ? '#2196F330' : null  }">

          <template v-for="header in columns">
            <td
              v-if="header.value === 'actions'"
              class="app-table__cell"
            >
              <div v-if="!readonly" class="app-table__actions">
                <v-tooltip
                  v-for="(rowAction, index) in rowActions"
                  :key="index"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-inline-flex"
                    >
                      <v-btn
                        v-if="!rowAction.visible || rowAction.visible(data.item)"
                        fab
                        :dark="!(rowAction.enable && !rowAction.enable(data.item))"
                        :disabled="rowAction.enable && !rowAction.enable(data.item)"
                        x-small
                        :color="rowAction.color"
                        @click.stop="rowAction.handle(data.item)"
                      >
                        <v-icon>{{ rowAction.icon }}</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <pre>{{ typeof rowAction.tooltip == 'string' ? rowAction.tooltip : rowAction.tooltip(data.item) }}</pre>
                </v-tooltip>

                <eva-icon-btn
                  v-if="enableAction('edit')"
                  icon="mdi-pencil"
                  color="#2196F3"
                  @click.stop="editItem(data.item)"
                />

                <eva-icon-btn
                  v-if="enableAction('delete')"
                  icon="mdi-delete"
                  color="#F44336"
                  @click.stop="removeItem(data.item)"
                />
              </div>
            </td>
            <td
              v-else
              class="app-table__cell"
            >
              <slot :name="`item.${header.value}`" :item="data.item">
                {{ data.item[header.value] }}
              </slot>
            </td>
          </template>

        </tr>
      </template>

    </v-data-table>

    <eva-spacer/>

    <v-pagination
      light
      v-model="options.page"
      :length="pageCount"
      :total-visible="totalVisible"
      v-if="pagination" class="eva-table__pagination"
    />
  </div>
</template>

<script>

export default {
  name: 'eva-table',

  props: {
    settings: {
      type: Object,
      default: () => {}
    },

    predefined : {},

    pagination : {
      type    : Boolean,
      default : true
    },

    itemColor :  {},
    selectable : {
      type    : Boolean,
      default : true
    },
    readonly : {
      type    : Boolean,
      default : false
    },
    filter : {
      type    : Object,
      default : null
    },
    onLoad : {
      type : Function,
      default : null
    },
    download : {
      type : Boolean,
      default : false
    },
    rowActions : {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      initialized: false,

      columns: [],

      items: [],
      itemsTotal: 0,
      itemsLoading: false,

      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
        mustSort: true
      },

      context : '',

      pageCount: 0,
      totalVisible: 7,

      error: null,

      selected : null
    };
  },

  computed: {
    showToolbar() {
      return this.settings.toolbar !== false;
    },
    header() {
      return `${this.$eva.$t(`$t.${this.settings.prefix}.header`)}`;
    },
    showHeader() {
      return this.settings.header !== false;
    },
    showTotal() {
      return this.settings.total !== false;
    },
    showContext() {
      return this.settings.context !== false;
    }
  },

  watch: {
    'settings.columns': {
      handler() {
        this.columns = this.getColumns();
      },
      deep: true,
      immediate: true
    },

    context() {
      if (this.showContext) {
        this.loadItems();
      }
    },


    options: {
      async handler() {
        await this.loadItems();
      },
      deep: true,
    },

    async predefined() {
      await this.loadItems();
    },

    filter : {
      handler() {
        this.loadItems('filter');
      },
      deep : true
    }
  },

  methods: {
    async loadItems() {
      if (!this.initialized) {
        return;
      }

      this.itemsLoading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        limit  : itemsPerPage,
        offset : (page - 1) * itemsPerPage,
      }
      if (sortBy && sortBy.length) {
        if (this.settings.newSort) {
          filter.sorting = sortBy
            .map((key, index) => `${sortDesc[index] ? '!' : ''}${key}`)
            .join(",");
        } else {
          filter.sorting = sortBy
            .map((key, index) => `${key}%${sortDesc[index] ? '-1' : 1}`)
            .join(",");
        }
      }
      if (this.showContext) {
        let context = (this.context || '').trim();
        if (context) {
          filter.context = context;
        }
      }
      if (this.filter) {
        Object.keys(this.filter).forEach((key) => {
          filter[key] = this.filter[key];
        });
      }

      if (this.predefined) {
        this.items      = this.onLoad ? this.onLoad(this.predefined) : this.predefined;
        this.itemsTotal = this.items.length;
      } else if (this.settings.url) {
        try {
          let {items, total} = await this.$eva.$http.getList(this.settings.url, filter);
          this.items = this.onLoad ? this.onLoad(items) : items;
          this.itemsTotal = total || 0;
        } catch (error) {
          this.error = error;
        }
      }

      this.itemsLoading = false;
    },

    getColumns() {
      if (!this.settings.columns) {
        return [];
      }
      let result = [];
      let sort = null;
      this.settings.columns.forEach((column) => {
        if (column.name === 'actions') {
          result.push({
            text: "",
            value: column.name,
            sortable: false,
          });
        } else {
          result.push({
            text: this.$eva.$t(`$t.${this.settings.prefix}.columns.${column.name}`),
            value: column.name,
            sortable: column.sort !== false,
            align: 'start'
          });
          if (column.sort !== false && (!sort || column.sort)) {
            sort = column;
          }
        }
      });

      if (sort) {
        this.options.sortBy = [sort.name];
        this.options.sortDesc = (sort.sort && sort.sort.toUpperCase() === 'DESC') ? [true] : [];
      }

      return result;
    },

    addItem() {
      let done = async ({ item }) => {
        if (!item) {
          return;
        }

        try {
          this.error = null;
          if (this.predefined) {
            this.predefined.push(item);
          } else {
            await this.adapter.add(item);
          }
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      };
      this.$emit("add", { done });
    },

    editItem(toEdit) {
      this.$emit('item-click', toEdit);

      if (this.selectable) {
        this.selected = toEdit;
        this.$emit('select', toEdit);
      } else if (!this.readonly) {
        let done = async ({item}) => {
          if (!item) {
            return;
          }

          try {
            this.error = null;
            if (this.predefined) {
              Object.assign(toEdit, item);
            } else {
              await this.adapter.update(item);
            }
          } catch (error) {
            this.error = error;
          }

          await this.loadItems();
        };

        this.$emit("edit", {item : Object.assign({}, toEdit), done});
      }
    },

    async removeItem(item) {
      if (!item) {
        return;
      }

      if (await this.$root.$confirm(`${this.resource}.remove`)) {
        try {
          this.error = null;
          if (this.predefined) {
            let index = this.predefined.indexOf(item);
            if (index >= 0) {
              this.predefined.splice(index, 1);
            }
          } else {
            await this.adapter.remove(item);
          }
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      }
    },

    enableAction(name) {
      if (this.readonly) {
        return false;
      }
      let actions = this.settings.columns && this.settings.columns.find((c) => c.name === "actions");
      return actions && actions[name] !== false;
    },

    async downloadItems() {

      const { sortBy, sortDesc,  } = this.options;

      let filter = { };
      if (sortBy && sortBy.length) {
        filter.order_by = sortBy
          .map((key, index) => (sortDesc[index] ? `!${key}` : key))
          .join(",");
      }
      if (this.search) {
        let searchText = (this.searchText || '').trim();
        if (searchText) {
          filter.search = searchText;
        }
      }
      if (this.filter) {
        Object.keys(this.filter).forEach((key) => {
          filter[key] = this.filter[key];
        });
      }

      let args = this.adapterArguments || [];
      await this.adapter.download(this.$eva.$t(`${this.resource}.header`), filter, ...args);
    },

    showFilter() {
      this.$panel.openComponent('div', {}, {
        size: 25,
        needCloseConfirm: true,
        title: 'Фильтр'
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.initialized = true;
      this.loadItems('mounted');
    });
  }
};
</script>

<style lang="less">
.eva-table1 {
  height: 100%;
  display: flex;
  gap: @eva-padding;
  flex-direction: column;

  .eva-table__toolbar {
    display: flex;
    flex-grow: 0;
    gap: @eva-padding;
    align-items: center;
    .eva-table__header {
      font-weight: bold;
      font-size: 1.2rem;
    }
    .eva-table__total {
      display: flex;
      justify-content: center;
      min-width: 64px;
      background-color: @eva-info-color;
      border-color: @eva-info-color;
      min-height: 36px;
      color: white;
      border-radius: (@eva-padding * 2);
      font-weight: bold;
      font-size: 1.2rem;
    }
    .eva-table__context {
      max-width : 250px;
      margin: 0!important;
      .v-input__prepend-inner {
        margin-top: 6px!important;
      }
      .v-input__slot {
        margin-bottom: 0!important;;
        min-height: 36px!important;
      }
      .v-text-field__details {
        display: none!important;;
      }
    }
  }

  .eva-table__alert {

  }

  .eva-table__content {

  }

  .eva-table__pagination {
    height: @eva-header;
  }

  .v-data-table {
    border-radius: 0!important;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    width: 100%;
    th {
      white-space: nowrap!important;
      text-align: start;
    }
    td {
      border-radius: 0!important;
      padding: 0;
    }
  }
}
</style>


