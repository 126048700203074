export default {
  "wp-task-list": {
    name: "Task List",
    labels: {
      createTaskBtn: "New task",
      createBtn: "Create",
      saveBtn: "Save",
      sortBy: "Sort by",
      acceptBtn: "Accept",
      rejectBtn: "Reject",
      tasksPerPage: "Tasks per page",
      sortSelects: {
        updateTime: "Update time",
        type: "Task type",
        author: "Author",
        performer: "Performer",
        status: "Status",
      },
      searchPlaceholder: "Search",
      taskNumber: "Task №:",
    },
    title: {
      update: "Edit Task",
    },
    actions: {
      saveButton: "Save",
    },
    infoMessages: {
      noResultsText: "No elements found. Consider changing the search query",
      loadingText: 'Loading data...'
    },
    errors: {
      get: "Cannot load task list",
    },
    components: {
      datePicker: {
        buttons: {
          todayBtn: "Today",
          weekBtn: "Week",
          monthBtn: "Month",
          anotherPeriodBtn: "Another period",
          applyBtn: "Apply",
        },
        labels: {
          startDate: "Start date",
          endDate: "End date",
          allTime: "For all period",
          from: "From",
          to: "To",
        },
        format: "YYYY-DD-MM",
      },
      taskFilterCard: {
        title: "Filters",
        byAuthor: "Indicate author",
        byPerformer: "Indicate performer",
        byStatus: "Indicate task status",
        byDeadline: "Indicate contract time",
        byPriority: "Indicate priority",
        expired: "Expired",
        notExpired: "Not expired",
        selectAll: "Select all",
        applyBtn: "Apply",
        clearBtn: "Clear",
        hideCompleted: "Hide completed",
        statuses: {
          "TaskStatus:NewTask": "New task",
          "TaskStatus:DeclinedTask": "Declined",
          "TaskStatus:AccomplishedTask": "Accomplished",
          "TaskStatus:FailedTask": "Failed",
          "TaskStatus:Accepted": "Accepted",
        },
        priorities: {
          "TaskPriority:Normal": "Normal",
          "TaskPriority:High": "High",
          "TaskPriority:Critical": "Critical",
        },
        actions: {
          apply: 'Apply',
          clear: 'Clear'
        }
      },
      searchPanel: {
        search: "Search",
      },
      listItem: {
        headers: {
          number: {
            text: "Number",
            sortable: true,
          },
          priority: {
            text: "Priority",
            sortable: true,
          },
          type: {
            text: "Type",
            sortable: true,
          },
          name: {
            text: "Name",
            sortable: true,
          },
          author: {
            text: "Author",
            sortable: true,
          },
          performer: {
            text: "Performer",
            sortable: true,
          },
          deadline: {
            text: "Deadline",
            sortable: true,
          },
          status: {
            text: "Status",
            sortable: true,
          },
        },
        labels: {
          number: "Number",
          type: "Type",
          name: "Name",
          priority: "Priority",
          author: "Author",
          from: "Period of validity from",
          to: "to",
          performer: "Performer",
          noDeadline: "Deadline time is not specified",
          deadline: "Deadline",
          until: "Until",
          status: "Status",
          subjectType: "Subject type",
          subject: {
            person: "Person",
            vehicle: "Vehicle",
          },
          source: "Event source",
        },
        format: "YYYY-DD-MM",
        actionButtons: {
          accept: "Accept",
          complete: "Complete",
          decline: "Decline",
          pause: "Pause",
        },
      },
      table: {
        title: "History",
        headers: {
          name: "Name",
          result: "Result",
          images: "Images",
        }
      },
    },
    settings: {},
    inputs: {},
    outputs: {},
  },
};
