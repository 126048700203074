<template functional>
  <div class="eva-header eva-background eva-border-bottom">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-header'
}
</script>

<style lang="less">
.eva-header {
  display: flex;
  flex-direction: row;
  gap: @eva-padding;
  width: 100%;
  height: @eva-header;
  align-items: center;
  padding: @eva-padding;
}
</style>
