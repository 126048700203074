<template>
  <div class="eva-repeater">

    <eva-repeater-header
      v-if="showHeader"
      :settings="settings"
      :options="options"
    />

    <div class="eva-repeater__content">
      <eva-overlay v-if="options.state.loading">
        <v-progress-circular
            indeterminate
            :size="100"
            :width="15"
            color="primary"
        />
      </eva-overlay>
      <slot name="items" v-bind="{ options, items }"/>
    </div>

    <eva-repeater-footer
      :settings="settings"
      :options="options"
    />

  </div>
</template>

<script>
import { reactive, readonly } from 'vue';

export default {
  name: 'eva-repeater',

  props: {
    settings: {
      type: Object
    }
  },

  data() {

    this.reload = this.$eva.$tools.debounce(this.reloadInternal);

    const pState = reactive({
      initialized: false,
      loading: false,
      total: 0,
      count: 0
    });
    const pFilter = reactive({
      context: '',
      sorting: '',
      limit: 50,
      offset: 0
    });

    const state = readonly(pState);
    const filter = readonly(pFilter);

    return {
      options: {
        state: pState,
        filter: pFilter
      },
      items: []
    }
  },

  computed: {
    showHeader() {
      return this.settings.header !== false;
    }
  },

  watch: {
    'options.filter': {
      handler() {
        this.reload();
      },
      deep: true
    }
  },

  methods: {
    async reloadInternal(state) {
      if (this.settings.url) {
        try {
          this.options.state.loading = true;
          let { items, total } = await this.$eva.$http.getList(this.settings.url, this.options.filter);
          if (!state.cancel) {
            this.options.state.total = total || 0;
            this.options.state.count = items.length || 0;
            this.items = items;
          }
        } catch (error) {
          this.error = error;
        } finally {
          this.options.state.loading = false;
        }
      }
    }
  },

  mounted() {
    this.reload().flush();
  }
}
</script>

<style lang="less">
.eva-repeater {
  height: 100%;
  display: flex;
  flex-direction: column;
  .eva-repeater__content {
    overflow: auto;
    flex-grow: 1;
    margin-bottom: @eva-padding;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
}
</style>
