import {isEmpty, isNil} from "lodash"

class DataAccessAdapter {
  constructor(da, serviceName, methods) {
    this._da = da
    this._serviceName = serviceName
    this._methods = methods
  }

  async list(filter, ...args) {
    const method = this._methods.list
    const options = {
      ...filter,
      ...args
    }
    return await this.__doRequest(method, options);
  }

  async item(id, ...args) {
    const method = this._methods.item
    const options = {
      id,
      ...args
    }
    return await this.__doRequest(method, options)
  }

  async add(item, ...args) {
    const method = this._methods.add
    const options = {
      data: {
        ...item,
        ...args
      }
    }
    return await this.__doRequest(method, options)
  }

  async update(item, ...args) {
    const method = this._methods.update
    const options = {
      data: {
        ...item,
        ...args
      }
    }
    return await this.__doRequest(method, options)
  }

  async remove(item, ...args) {
    const method = this._methods.remove
    const options = {
      id: item.id,
      ...args
    }
    return await this.__doRequest(method, options)
  }


  async __doRequest(method, options) {
    if (isNil(method) || isEmpty(method)) {
      throw new Error(`DataAccessAdapter method: ${this._serviceName}:${method} is not set`)
    }
    const response = await this._da.$serverRequest(`${this._serviceName}:${method}`, options)
    return this._da.getResult({
      data: response
    });
  }
}

export default DataAccessAdapter
