export default {
  "user-profile": {
    name: "Личный кабинет",
    exit: 'Выйти',
    labels: {
      information: {
        topLabel: 'О себе',
        about: 'Расскажите о себе',
        firstName: 'Имя',
        middleName: 'Отчество (при наличии)',
        lastName: 'Фамилия',
        userProfilePhoto: 'Прикрепите фото',
        birthDate: 'Дата рождения',
        birthPlace: 'Место рождения',
        sex: 'Пол',
        alertInfo: 'Вы не можете редактировать данный раздел, для смены данных обратитесь в кадровую службу',
        login: 'Логин',
        password: 'Новый пароль',
      },
      sections: {
        about: 'О себе',
        personal: 'Персональные данные',
        applicationsAndPermits: 'Заявки и пропуска',
        actions: 'Действия',
        access: 'Доступ в систему',
      },
      docType: {
        Passport: "Паспорт",
        ForeignPassport: "Загранпаспорт",
        DriverLicense: "Водительское удостоверение",
      },
      contacts: {
        topLabel: 'Контактные данные',
        workphone: 'Внутренний номер',
        telephone: 'Телефон',
        email: 'Электронная почта',
      },
      document: {
        topLabel: 'Персональные данные',
        seriesDocument: 'Серия',
        numberDocument: 'Номер',
        dateDocument: 'Дата выдачи',
        departmentCode: 'Код подразделения',
        issuedDocument: '​Учреждение, выдавшее документ',
      },
      filterBtn: 'Применить',
      resetFilterBtn: 'Очистить',
      orderFilter: {
        status: 'Статус',
        checkPoints: 'Доступ',
        dateCreation: 'Дата подачи заявки',
        datePeriodPass: 'Срок действия пропуска',
        timePeriodPass: 'Время действия пропуска',
        from: 'с',
        to: 'по',
        on: 'Дата действия',
      }
    },
    preferences: {
      dateFormat: "DD.MM.YYYY",
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        maxLength: "Значение должно занимать не более {0} символов",
        correctNumber: "Некорректный номер",
      },
    },
    components: {
      searchPanel: {
        search: 'Поиск'
      },
      filters: {
        title: 'Параметры фильтра'
      }
    },
    orderTypes: {
      complex: 'Комплексная заявка',
      person: 'Заявка на ФЛ',
      vehicle: 'Заявка на ТС',
    },
    table: {
      format: 'DD.MM.YYYY HH:mm',
      permits: {
        headers: {
          number: {
            text: 'Номер',
            sortable: false,
          },
          type: {
            text: 'Тип пропуска',
            sortable: true,
          },
          status: {
            text: 'Статус',
            sortable: true,
          },
          start_time: {
            text: 'Даты действия',
            sortable: true,
          },
          markup: {
            text: 'Пометка',
            sortable: false,
          },
        },
        currentStatus: {
          Active: 'Активирован',
          Block: 'Заблокирован',
          Deactivated: 'Деактивирован',
        },
        type: {
          PermitPerson: 'Частное лицо',
        }
      },
      orders: {
        format: "DD.MM.YYYY",
        headers: {
          number: {
            text: 'Номер',
            sortable: true,
          },
          type: {
            text: 'Тип заявки',
            sortable: false,
          },
          status: {
            text: 'Статус',
            sortable: true,
          },
          dateValidFrom: {
            text: 'Дата заявки',
            sortable: true,
          },
          markup: {
            text: 'Пометка',
            sortable: false,
          },
        },
      },
      actions: {
        format: "DD.MM.YYYY",
        headers: {
          date: {
            text: 'Дата и время',
            sortable: true,
          },
          subject: {
            text: 'Субъект',
            sortable: true,
          },
          location: {
            text: 'Локация',
            sortable: true,
          },
          type: {
            text: 'Тип события',
            sortable: true,
          },
          violation: {
            text: 'Нарушение',
            sortable: false,
          },
          file: {
            text: 'Файл',
            sortable: false,
          },
        },
      },
    },
  },
};
