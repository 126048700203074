export default {
  'wp-passage-access-queue': {
    name: 'Passage access queue',
    settings: {
      header: "Header",
      eventSettings: 'Event settings',
      directObjs: "Objects",
      subjects: "Subjects",
      locations: "Locations",
    },
    inputs: {
      refreshList: 'Refresh the list of events'
    },
    rules: {},
    fields: {
      entry: 'Entry',
      exit: 'Exit',
      records: 'records',
      from: 'from',
      shown: 'Shown'
    },
    categoriesEmployee: {
      Visitor: 'Visitor',
      Contractor: 'Contractor',
      Subcontractor: 'Subcontractor',
      Employee: 'Employee',
      Security: 'Security',
      Chief: 'Chief',
      Household: 'Household'
    },
    errors: {
      configWidget: "Widget's events aren't set or are set incorrectly. Please, contact the administrator"
    }
  }
}
