export default {
  "wp-permit-person-card": {
    name: "Permit Person Card",
    notSet: "Not set",
    title: {
      new: "New permit person",
      update: "Edit permit person",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      information: {
        topLabel: "Information",
        name: "Name",
        subject: "Subject",
        status: "Status",
        dates: "Validity dates",
      },
    },
    preferences: {
      dateFormat: 'YYYY-DD-MM'
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        correctNumber: "Phone number is incorrect",
      },
    },
  },
};
