<template>
  <div class="eva-drawer eva-background">
    <div
      class="eva-drawer__inner eva-background eva-border-right"
      :style="{ width: currentWidth }"
      @mouseover="onMouseOver"
      @mouseleave="onMouseLeave"
    >
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-drawer',

  props: {
    width: {
      type: [String, Number],
      default: '300px'
    }
  },

  data() {
    return {
      currentWidth: null
    }
  },

  methods: {
    onMouseOver() {
      this.currentWidth = this.width;
    },
    onMouseLeave() {
      this.currentWidth = '100%';
    }
  }
}
</script>

<style lang="less">
.eva-drawer {
  width: @eva-header;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  .eva-drawer__inner {
    display: flex;
    flex-direction: column;
    min-height: 0;
    z-index: 1000;
    height: 100%;
    transition-property: width;
    transition-duration: 0.2s;
    cursor: pointer;
    &:hover {
      overflow-y: auto;
    }
  }
}
</style>
