import toLowerCamelCase from '@eva/common/tools/text/toLowerCamelCase';

function createPlugins(app) {
  let result = {};
  let plugins = require.context('@eva/client/plugins', true, /([\\/])[a-z]+EvaPlugin\.js$/i, 'sync');
  let keys = plugins.keys();
  for (let i = 0; i < keys.length; i++) {
    let fileName = keys[i];
    let pluginName = `$${toLowerCamelCase(/[\\/]([a-z]+)EvaPlugin\.js$/i.exec(keys[i])[1])}`;
    let PluginConstructor = require(`@eva/client/plugins${fileName.substring(1)}`);
    result[pluginName] = new PluginConstructor.default(app);
  }
  return result;
}

export default createPlugins;
