import Plugin from "../../plugin";
import DataAccess from "./data-access";

class DataAccessPlugin extends Plugin {

    static install(Vue) {
        //Vue.prototype.$dataAccess = new DataAccess(Vue);
    }

}

export default DataAccessPlugin;
