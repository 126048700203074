const CheckpointsTranslations = {
  header: "Checkpoints",
  add: "Add",
  edit: "Edit",
  remove: "Remove checkpoint?",
  save: "Save",
  cancel: "Cancel",
  searchPanel: {
    search: "Search"
  },
  table: {
    headers: {
      id: {
        text: "ID",
        sortable: false,
      },
      name: {
        text: "Name",
        sortable: true,
      },
      actions: {
        text: "",
        sortable: false,
      },
    },
    item: {
      remove: "Remove checkpoint {0}",
    }
  },
  columns: {
    name: "Name",
    levelPermits: "Level permits",
  },
  dialogs: {
    changePerson: {
      title: "Are you sure to change checkpoint?",
    },
  },
  options: {
    itemsPerPage: "items per page",
  },
}

export default CheckpointsTranslations
