export default {
  'wp-reports': {

    name: 'Отчеты',

    accessReportConfig: {
      readerInputs: "Считыватели на вход",
      readerOutputs: "Считыватели на выход",
      timeAccess: "Объединять события за период",
      timeType: {
        min: "Минуты",
        hour: "Часы",
        day: "Дни",
      },
    },

    'checkpoints-report' : {
      title : 'Загрузка КПП'
    },

    'access-person-report' : {
      title: 'Проходы',
    },

    'orders-report' : {
      title : 'Заявки на пропуска'
    },

    'permits-report' : {
      title : 'Пропуска'
    },

    config: {
      isChanged: "Внимание: конфигурация изменилась",
      isUpdating: "Идет сохранение",
      isSaved: "Конфигурация успешно сохранена",
      configForReportBuild: "Конфигурация для сборки отчета",
      inBuildProcess: "Формирование отчета отправлено в очередь",
      btn: {
        rollback: "Откатить к последней сохраненной версии",
      },
      confirmRollback: {
        title: "Откатить?",
        text: "Это действие нельзя отменить",
      },
      confirmToCreate: {
        title: "Создать новую конфигурацию?",
        text: "Внесенные изменения не сохранятся",
      },
      error:{
        notSaved: "Не удалось сохранить конфигурацию",
        notLoaded: "Не удалось загрузить конфигурацию",
        notParsed: "Не удалось обработать конфигурацию",
        notHaveConfigBuild: "Не настроена конфигурация для выгрузки отчета",
      },
    },

    filters: {
      objectTypeLabel: 'Тип объекта',
      objectTypes: {
        Vehicle: 'Транспортные средства',
        Person: 'Физические лица',
      },
      validatedObjectTypes: {
        ValidatedVehicle: 'Транспортные средства',
        ValidatedPerson: 'Физические лица',
      },
      dateFormat: 'DD.MM.YYYY',
      statuses: 'Статусы',
      organizations: 'Организации',
      checkpoints: 'Доступ',
      orderTypes: "Типы заявок",
      dateCreation: "Дата подачи заявки",
      datePassValid: "Срок действия пропуска",
      isInsidePeriod: "Только внутри периода срока действия",
      reportDate: 'Дата построения отчета',
      startReportDate: 'Дата построения отчета с',
      endReportDate: 'Дата построения отчета по',
    },

    table: {
      orders: {
        headers: {
          date_valid_from: 'Дата создания',
          number: 'Номер заявки',
          object: 'Объект',
          requester_organization: 'Организация',
          requester: 'Заявитель',
          status_name: 'Статус',
          start_pass_time: 'Действителен с',
          end_pass_time: 'Действителен по',
          checkpoints: 'Зоны доступа',
          orderType: "Тип заявки",
          orderReportActions: "Действия",
        },
        item: {
          format: 'DD.MM.YYYY'
        },
        pdf: {
          number: `Заявка №`,
          object: {
            header: `Данные объекта`,
          },
          organization: {
            header: `Организация`,
          },
          checkpoints: {
            header: `Зоны доступа`,
          },
          access: {
            header: `Срок действия пропуска`,
            timeRange: `с {start} по {end}`,
          },
        },
      },
      permits: {
        headers: {
          object: 'Объект',
          object_organization: 'Организация объекта',
          requester: 'Заявитель',
          requester_organization: 'Организация заявителя',
          date_valid_from : 'Дата подачи заявки',
          status_name: 'Статус',
          start_pass_time: 'Действителен с',
          end_pass_time: 'Действителен по',
          checkpoints: 'Зоны доступа'
        },
        item: {
          format: 'DD.MM.YYYY'
        }
      },
      checkpoints: {
        headers: {
          subject: 'Объект',
          time_entry: 'Время въезда',
          type_entry: 'Разрешение на въезд',
          reader_entry: 'Зона въезда',
          time_departure: 'Время выезда',
          type_departure: 'Разрешение на выезд',
          reader_departure: 'Зона выезда',
        },
        item: {
          format: 'h:mm'
        }
      },
      personAccess: {
        headers: {
          subject: 'ФИО',
          organization: 'Организация',
          time_entry: 'Время входа',
          type_entry: 'Разрешение на вход',
          reader_entry: 'Зона входа',
          time_departure: 'Время выхода',
          type_departure: 'Разрешение на выход',
          reader_departure: 'Зона выхода',
        },
        item: {
          format: 'hh:mm'
        }
      }
    },
    total: 'Всего',
    components: {
      searchPanel: {
        search: "Поиск"
      },
    },
  }
}
