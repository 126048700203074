<template>
  <v-main class="app-content">
    <v-container fluid>
      <slot>
        <router-view :key="$route.fullPath"></router-view>
      </slot>
    </v-container>
    <panels></panels>
  </v-main>
</template>

<script>
import Panels from './panels';

export default {
  name: 'app-content',
  components: {
    Panels
  },
};
</script>

<style lang="less">
.app-content {
  .v-main__wrap > .container {
    height: 100%;
    padding: 0;
  }
}
</style>
