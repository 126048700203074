<template>
  <div
    class="eva-layout"
    :class="classes"
    :style="styles"
  >
    <slot/>
  </div>
</template>

<script>

/* TODO
 1. Если компонент находится в режиме 'показывать скролл только при наведении',
    то отображать какую либо графическую пометку (см. 'vuetify/lib/services/goto').
 2. Добавить ручное управление видимость скрола
    (например, когда открывается и закрывается EvaDrawer).
*/

export default {
  name: 'eva-layout',

  props: {
    row: {
      type: Boolean,
      default: true
    },
    column: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: false
    },
    scrollHide: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    maxHeight: {
      type: String
    },
    noGap: {
      type: Boolean,
      default: false
    },
    noShrink: {
      type: Boolean,
      default: false
    },
    noGrow: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'eva-background': !this.transparent,
        'eva-layout--column': this.column,
        'eva-layout--row': this.row,
        'eva-layout--fill': this.fill,
        'eva-layout--scroll': this.scroll,
        'eva-layout--scroll-hide': this.scrollHide,
        'eva-layout--gap': this.noGap,
        'eva-layout--no-shrink': this.noShrink,
        'eva-layout--no-grow': this.noGrow,
        'eva-layout--padding': this.padding
      }
    },
    styles() {
      let result = {};
      if (this.width != null) {
        result.width = this.width;
      }
      if (this.height != null) {
        result.height = this.height;
      }
      if (this.maxHeight != null) {
        result.maxHeight = this.maxHeight;
      }
      return result;
    }
  }
}
</script>

<style lang="less">
.eva-layout {
  display: flex;
  width: 100%;
  overflow: hidden;
  &.eva-layout--row {
    flex-direction: row;
  }
  &.eva-layout--column {
    flex-direction: column;
  }
  &.eva-layout--fill {
    flex: 1;
  }
  &.eva-layout--gap {
    gap: @eva-padding;
  }
  &.eva-layout--no-shrink {
   flex-shrink: 0;
  }
  &.eva-layout--no-grow {
    flex-grow: 0;
  }
  &.eva-layout--scroll {
    flex: 1;
    overflow: auto;
  }
  &.eva-layout--padding {
    padding: @eva-padding;
  }
  &.eva-layout--scroll-hide {
    flex: 1;
    overflow: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
}
</style>
