const ScenariosTranslations = {
  header: "Order Scenarios",
  add: "Add",
  edit: "Edit",
  remove: "Remove order scenario?",
  save: "Save",
  cancel: "Cancel",
  exit: "All unsaved changes will be lost",
  searchPanel: {
    search: "Search"
  },
  labels: {
    name: "Scenario name",
    startСondition: "Start condition",
    endСondition: "End condition",
    stageName: "Stage name",
    startConditionName: "Start condition name",
    endConditionName: "End condition name",
    conditionName: "Condition name",
    roles: "Roles",
    organization: "Organization",
    users: "Users",
    scenario: "Scenario",
    startReason: "Start reason",
    determineApprover: "Determine the approver based on",
    determineApproverReason: "Reason",
    timeToComplete: "Time to complete",
    activeAlways: "Always active",
    editRight: "Edit rights",
    checkpoint: "Checkpoints",
    accessdate: "Access date",
    securitylist: "Security list",
    inspection: "Inspection",
    periodToComplete: {
      days: "Days",
      hours: "Hours",
    },
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    exit: "Exit",
  },
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Remove order scenario {0}",
    }
  },
  errors: {
    required: "Field is required",
    verifed: "Correct scenario",
    unverifed: "There are errors in the scenario",
  },
}

export default ScenariosTranslations
