import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":_vm.options.color,"dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])],1),_vm._v(" "),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pa-4"},[_vm._v(_vm._s(_vm.$t(_vm.message)))]),_vm._v(" "),(_vm.options.form && _vm.options.form.items && _vm.options.form.items.length)?_c(VCardText,{staticClass:"pb-0 pa-4"},_vm._l((_vm.options.form.items),function(item){return _c(VTextarea,{key:item.field,attrs:{"label":item.label,"persistent-placeholder":"","outlined":"","dense":"","autofocus":""},model:{value:(_vm.options.form.model[item.field]),callback:function ($$v) {_vm.$set(_vm.options.form.model, item.field, $$v)},expression:"options.form.model[item.field]"}})}),1):_vm._e(),_vm._v(" "),_c(VCardActions,{staticClass:"pt-0"},[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.dialog.ok')))]),_vm._v(" "),_c(VBtn,{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.dialog.cancel')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }