export default {
  "wp-card-card": {
    name: "Card",
    notSet: "Not set",
    title: {
      new: "New Card",
      update: "Edit Card",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Information",
        name: "Name",
        code: "Code",
        personPermit: "Person permit",
        vehiclePermit: "Vehicle permit",
        levelPermit: "Permit levels",
      },
    },
    preferences: {
      dateFormat: 'YYYY-DD-MM'
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        correctNumber: "Phone number is incorrect",
      },
    },
  },
};
