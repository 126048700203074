<template>

  <eva-tree
    :load-nodes="loadNodes"
  >

  </eva-tree>

</template>

<script>
export default {
  name: 'eva-tree-details',

  props: {
    loadNodes : {
      type: Function
    }
  }
}
</script>

<style lang="less">
  .eva-tree-details {

  }
</style>