const IntegrationTranslations = {
  header: "Интеграции",
  labels: {
    name: "Название",
    createBtn: "Создать ОМ",
  },
  actions: {
    remove: {
      title: 'Удалить объект',
      confirm: 'Вы действительно хотите удалить объект',
    },
    create: {
      title: 'Создать',
      selectPlaceholder: 'Выберите тип объекта',
      save: 'Сохранить',
    },
    edit: {
      save: 'Сохранить'
    },
    command: {
      title: 'Выполнить команду',
      execute: 'Выполнить',
      error: 'Не удалось выполнить команду',
      selectPlaceholder: 'Выберите команду',
    },
  }
}

export default IntegrationTranslations
