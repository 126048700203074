export default {
  "wp-card-card": {
    name: "Карта",
    notSet: "Не задано",
    title: {
      new: "Новая карта",
      update: "Редактирование карты",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      details: {
        topLabel: "Сведения",
        name: "Название",
        code: "Код карты",
        personPermit: "Пропуск на ФЛ",
        vehiclePermit: "Пропуск на ТС",
        levelPermit: "Уровни доступа",
      },
    },
    preferences: {
      dateFormat: "DD.MM.YYYY",
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        minLength: "Значение должно быть не менее {0} символов",
        maxLength: "Значение должно занимать не более {0} символов",
        correctNumber: "Некорректный номер",
      },
    },
  },
};
