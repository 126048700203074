const COMPONENT_ROUTE_TRIM_LEFT = [
  'eva'
];
const COMPONENT_ROUTE_TRIM_RIGHT = [
  'widget'
];

class EvaRouteManager {
  constructor(app) {
    this.app = app;
    this.items = []
  }

  route(path, component, props) {
    let settings = component
      ? {path, component, props}
      : {path: '*', redirect: path};
    if (this.app.router.addRoute) {
      this.app.router.addRoute(settings);
    } else {
      this.app.router.addRoutes([settings]);
    }
    return this;
  }

  componentRoute(component) {
    this.items.push({
      type: 'item',
      component
    });
    let path = this.componentRoutePath(component);
    return this.route(`/${path}`, component);
  }

  componentRouteGroup(...components) {
    this.items.push({
      type: 'group',
      items: components.map((component) => {
        let path = this.componentRoutePath(component);
        this.route(`/${path}`, component);
        return {
          type: 'item',
          component
        }
      })
    });
    return this;
  }

  componentRoutePath(component) {
    let path = component.name;
    for (let i = 0; i < COMPONENT_ROUTE_TRIM_LEFT.length; i++) {
      let trim = COMPONENT_ROUTE_TRIM_LEFT[i];
      if (path.startsWith(trim)) {
        path = path.substring(trim.length);
      }
    }
    for (let i = 0; i < COMPONENT_ROUTE_TRIM_RIGHT.length; i++) {
      let trim = COMPONENT_ROUTE_TRIM_RIGHT[i];
      if (path.endsWith(trim)) {
        path = path.substring(0, path.length - trim.length);
      }
    }
    path = path.replaceAll('-', '');
    return path;
  }

  getRouteLinks(options = {}) {
    if (!options.type) {
      options.type = 'dividers';
    }
    let result = [];
    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      switch (item.type) {
        case 'item':
          this.setRouteItemLink(result, item);
          break;
        case 'group':
          this.setRouteGroupLinks(options, result, item);
          break;
      }
    }
    return result;
  }

  setRouteItemLink(result, item = {}) {
    let { component } = item;
    if (!component) {
      return false;
    }
    result.push({
      name: `$t.${component.name}.caption`,
      to: `/${this.componentRoutePath(component)}`,
      icon: component.settings.icon
    });
    return true;
  }

  setRouteGroupLinks(options, result, item = {}) {
    let { items } = item;
    if (!items || !items.length) {
      return false;
    }
    switch (options.type) {
      case 'dividers':
        let index = result.length;
        if (index) {

        }
        let hasRoutes = false;
        for (let i = 0; i < items.length; i++) {
          if (this.setRouteItemLink(result, items[i])) {
            hasRoutes = true;
          }
        }
        if (hasRoutes && index) {
          result.splice(index, 0, {
            divider: true
          });
        }
        return hasRoutes;
      case 'groups':

        return true;
    }
    return false;
  }
}

export default EvaRouteManager;
