class DataAccessAdapter {

    constructor(da, url) {
        this._da  = da;
        this._url = url;
    }

    async list(filter, ...args) {

        let query = Object
            .entries(filter || {})
            .filter(([key, value]) => value != null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        if (query) {
            query = `?${query}`;
        } else {
            query = '';
        }

        let res = await this._da.$http.get(`${this.getUrl(args)}${query}`);
        return this._da.getResult(res);
    }

    async download(name, filter, ...args) {
        let query = Object
            .entries(filter || {})
            .filter(([key, value]) => value != null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        if (query) {
            query = `?${query}`;
        } else {
            query = '';
        }

        try {
            let response = await this._da.$http({
                url: `${this.getUrl(args)}/download${query}`,
                method: 'GET',
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
        }
    }

    async item(id, ...args) {
        let res = await this._da.$http.get(`${this.getUrl(args)}/${id}`);
        return this._da.getResult(res);
    }

    async add(item, ...args) {
        let res = await this._da.$http.post(this.getUrl(args), item);
        return this._da.getResult(res);
    }

    async update(item, ...args) {
        let res = await this._da.$http.put(`${this.getUrl(args)}/${item.id}`, item);
        return this._da.getResult(res);
    }

    async remove(item, ...args) {
        let res = await this._da.$http.delete(`${this.getUrl(args)}/${item.id}`);
        return this._da.getResult(res);
    }

    getUrl(args) {
        if (args.length) {
            return this.format(this._url, ...args);
        } else {
            return this._url;
        }
    }

    format(str, ...args) {
        return str.replace(/{(\d+)}/g, (match, number) => args[number] != null ? args[number] : null);
    }
}

export default DataAccessAdapter;
