<template>
  <v-btn
      :color="color"
      :text="isText"
      :plain="plain"
      @click="$emit('click', $event)"
  >
 {{text}}
  </v-btn>

</template>

<script>
export default {
  name: 'eva-btn',

  props: {
    color: {
      type: String
    },
    text: {
      type: String
    },
    plain: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less">
.eva-icon-btn {
  height: 28px!important;
  width: 28px!important;
  color: white!important;
  .v-icon {
    font-size: 16px!important;
  }
}
</style>
