const OrganizationTranslations = {
  header: "Organizations",
  title: {
    new: "New organization",
    update: "Edit organization",
  },
  searchPanel: {
    search: "Search"
  },
  labels: {
    name: "Organization name",
    add: "Add organization",
    edit: "Edit organization",
    remove: "Remove organization?",
    details: {
      name: "Name",
      tin: "Tin",
      iec: "Iec",
      psrn: "Psrn",
    },
    contracts: {
      topLabel: "Contracts",
      withoutContract: "Operate without contract",
      archive: "Archive",
    },
  },
  buttons: {
    save: "Save",
  },
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      tin: {
        text: "Tin",
        sortable: false,
      },
      iec: {
        text: "Iec",
        sortable: false,
      },
      psrn: {
        text: "Psrn",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Edit organization {0}",
      remove: "Remove organization {0}",
    }
  },
  validationRules: {
    defaults: {
      required: "Value is required",
      minLength: "Length must be more than {0} characters",
      maxLength: "Length must be less than {0} characters",
      numeric: 'Value can be only numeric',
      lengthTin: "Length must be {0} or {1} characters",
      minLengthTin: "Length must be {0} characters",
      maxLengthTin: "Length must be {0} characters",
      lengthIec: "Length must be {0} characters",
      lengthPsrn: "Length must be {0} or {1} characters",
      minLengthPsrn: "Length must be {0} characters",
      maxLengthPsrn: "Length must be {0} characters",
      uniqueTin: "Organization with such data already exists",
      uniqueIec: "Organization with such data already exists",
      uniquePsrn: "Organization with such data already exists",
      certainSymbols: "Invalid character entered",
    },
    tin: {
      unique: "There is another organization with that TIN",
    },
  },
}

export default OrganizationTranslations
