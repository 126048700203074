const DesktopTranslations = {
  header: "Группы рабочих столов",
  columns: {
    name: "Наименование",
    sort_order: "Порядок сортировки",
    roles: "Роли"
  },
  add: "Добавление группы рабочих столов",
  edit: "Редактирование группы рабочих столов",
  remove: "Удалить группу рабочих столов ?"
}

export default DesktopTranslations
