export default {
  "wp-checkpoint": {
    name: "Checkpoint card",
    labels: {
      titleNew: 'New checkpoint',
      titleUpdate: 'Checkpoint',
      name: 'Name',
      saveButton: 'Save',
    },
    rules: {
      name: {
        requiredMessage: 'Value is required'
      },
    },
  }
};
