<template>
<div class="eva-text" :class="classes" :style="{ color: color , fontSize: size}">
  {{ $eva.$t(text) }}
</div>
</template>

<script>
export default {
  name: 'eva-text',

  props: {
    text: {
      type: String
    },
    bolder: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    size:{
      type: String,
      default: "1rem"
    }
  },

  computed: {
    classes() {
      return {
        'eva-text--bolder': this.bolder,
        'eva-text--bold': this.bold
      }
    }
  }
}
</script>

<style lang="less">
.eva-text {
  align-self: center;
  font-size: 1rem;
  &.eva-text--bolder {
    font-weight: bolder;
  }
  &.eva-text--bold {
    font-weight: bold;
  }
}
</style>
