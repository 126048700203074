export default {

    locale : {
        code : 'en',
        name : 'English'
    },

    app : {
        name : 'TOIR'
    },

    common : {
        table : {
            noDataText : 'No data'
        },
        dialog : {
            ok : 'OK',
            cancel : 'Cancel'
        },
        loading : 'Loading...',
        images : {
            clickToChange : 'Click to change image'
        }
    }
}
