const EventCatalogsTranslations = {
  header: "Events",
  remove: "Remove element?",
  add: "Add element",
  create: "Create element",
  edit: "Edit element",
  save: "Save",
  cancel: "Cancel", 
  searchPanel: {
    search: "Search"
  },
  columns: {
    id: "ID",
    name: "Name",
    nameRu: "Name [ru]",
    nameEn: "Name [en]",
    params: "Parameters",
    sys_name: "System name",
    description: "Description",
    alarmLevel: "Alarm level",
    color: "Color",
    showEvent: "Show event",
  },
  labels: {
    name: "Event name",
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    exit: "Exit",
    back: "Back",
  },
  eventTypeList: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Edit event type {0}",
      remove: "Remove event type {0}",
    },
    remove: "Remove event type?",
  },
  eventList: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      params: {
        text: "Params",
        sortable: false,
      },
      sys_name: {
        text: "System name",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Edit event {0}",
      remove: "Remove event {0}",
    },
    remove: "Remove event?",
  },
  errors: {
    required: "Field is required",
    alpha: "The value must contain only latin characters",
    uniqueId: "Event type with the same ID already exists",
    uniqueName: "Event type with the same name already exists",
  },
}

export default EventCatalogsTranslations
