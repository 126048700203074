const LoginTranslation = {
  login: {
    header: "Login to the system",
    user: "Username",
    password: "Password",
    login: "Login",
    logout: "Logout",
    register: "Can not enter",
    empty: "Enter login/password"
  },
}
export default LoginTranslation
