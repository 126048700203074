<template>
  <div class="eva-widget">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-widget'
}
</script>

<style lang="less">
.eva-widget {
  padding: @eva-padding;
  width: 100%;
  height: 100%;
}
</style>
