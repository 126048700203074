import EnLocale from "./en-locale";
import AdminTranslations from "./admin";
import LoginTranslations from "./login";

const Translations = {
  ...EnLocale,
  ...AdminTranslations,
  ...LoginTranslations,
}
export default Translations
