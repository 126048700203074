export default {
  "wp-analitycs": {
    name: "Аналитика",
    components: {
      orders: {
        total: 'Всего заявок',
        vehicles: 'Транспортные средства',
        persons: 'Физические лица',
        byStatus: 'По статусам'
      },
      permits: {
        day: 'День',
        time: 'Время суток',
        week: 'Неделя',
        dayOfWeek: 'День недели',
        month: 'Месяц',
        date: 'Число',
        year: 'Год',
        barrier: 'Шлагбаум',
        buildReport: "Построить отчёт",
        dates: {
          startTime: 'Начало периода',
          endTime: 'Конец периода',
        },
        preferences: {
          dateFormat: "DD.MM.YYYY",
        },

        total: 'Всего пропусков',
        vehicles: 'Пропуска на транспортные средства',
        persons: 'Пропуска на физических лиц',
        byStatus: 'Статусы пропусков'
      },
      accesses: {
        day: 'День',
        time: 'Время суток',
        week: 'Неделя',
        dayOfWeek: 'День недели',
        month: 'Месяц',
        date: 'Число',
        year: 'Год',
        barrier: 'Шлагбаум',
        buildReport: "Построить отчёт",
        dates: {
          startTime: 'Начало периода',
          endTime: 'Конец периода',
        },
        preferences: {
          dateFormat: "DD.MM.YYYY",
        },
      },
    }
  },
  "wp-analitycs-config": {
    name: "Конфигурация",
    header: "Название",
    type: "Тип",
    types: {
      "ordersSummary": "Заявки (счетчик)",
      "permitsSummary": "Пропуска (счетчик)",
      "permitsTimeline": "Пропуска (инфографика)",
      "accessTimeline": "Проезды (инфографика)",
      "personAccessTimeline": "Проходы (инфографика)",
    },
  },
};
