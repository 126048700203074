import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import './toast-index.css';
import Plugin from "../../plugin";

class ToastPlugin extends Plugin {
  static install(Vue) {
    Vue.use(VueToast, {position: 'bottom', queue: true});

    Vue.prototype.$showInfo = function (message) {
      Vue.$toast.info(message)
    }
    Vue.prototype.$showError = function (message) {
      Vue.$toast.error(message)
    }
    Vue.prototype.$showWarning = function (message) {
      Vue.$toast.warning(message)
    }
  }
}

export default ToastPlugin;
