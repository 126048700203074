const LAST_DESKTOP_ID = "LAST_DESKTOP_ID"
const Z_INDEXES = Object.freeze({
  panelStart: 1101,
  panelDialog: 1200, // из расчета, что z-index боковых панелей, в т.ч. сайдбара и панели с виджетами, равен 1100
  templateDialog: 1300,
})

export {
  LAST_DESKTOP_ID,
  Z_INDEXES
}
