import App from "../core/app"

import Content from "./components/app-content"
import Table from "./components/app-table"
import VDateField from "./components/v-date-field";
import ConfirmDialog from "./dialogs/confirm-dialog"
import Dialog from "./dialogs/dialog"

const app = new App()

app.components(
  Content,
  Table,
  VDateField,
  ConfirmDialog,
  Dialog
)

export default app
