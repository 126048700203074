const CheckpointsTranslations = {
  header: "Точки доступа",
  add: "Добавление",
  edit: "Редактирование",
  remove: "Удалить точку доступа?",
  save: "Сохранить",
  cancel: "Отмена",
  searchPanel: {
    search: "Поиск"
  },
  table: {
    headers: {
      id: {
        text: "ID",
        sortable: false,
      },
      name: {
        text: "Название",
        sortable: true,
      },
      actions: {
        text: "",
        sortable: false,
      },
    },
  item: {
      remove: "Удалить точку доступа {0}",
    }
  },
  columns: {
    name: "Название",
    levelPermits: "Уровни доступа",
  },
  dialogs: {
    changeCheckpoint: {
      title: "Вы уверены, что хотите изменить точку доступа?",
    },
  },
  options: {
    itemsPerPage: "Записей на странице",
  },
}

export default CheckpointsTranslations
