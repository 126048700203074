import ContractCardTranslation from './en/contract-card'
import OrgCardTranslation from './en/org-card'
import PersonCardTranslation from './en/person-card'
import PermitPersonCardTranslation from './en/permit-person-card'
import PermitVehicleCardTranslation from './en/permit-vehicle-card'
import VehicleCardTranslation from './en/vehicle-card'
import CheckpointTranslation from './en/checkpoint'
import ControllerBoardTranslation from './en/controller-board'
import EventDetailTranslation from './en/event-detail'
import EventLogTranslation from './en/event-log'
import VideoStreamTranslation from './en/video-stream'
import PersonListTranslation from './en/person-list'
import VehicleListTranslation from './en/vehicle-list'
import VerificationPanelTranslation from './en/verification-panel'
import CommonTranslation from './en/common'
import MyOrgTranslation from './en/my-org'
import UserProfileTranslation from './en/user-profile'
import ContentFrameTranslation from './en/content-frame'
import PassageAccessQueueTranslation from './en/passage-access-queue'
import ReportsTranslation from './en/reports'
import AnalitycsTranslation from './en/analitycs'
import CardCardTraslation from './en/card-card'
import CheckPointCardTraslation from './en/check-point-card'
import LevelPermitCardTraslation from './en/level-permit-card'
import PassOfficeTraslation from './en/pass-office'
import FileCardTranslation from './en/file-card'

import ComponentsLocales from '../components/locales/en'
import ErrorMessagesLocale from "./en/error-messages";

export default Object.assign({},
  ContractCardTranslation,
  OrgCardTranslation,
  PersonCardTranslation,
  PermitPersonCardTranslation,
  PermitVehicleCardTranslation,
  VehicleCardTranslation,
  CheckpointTranslation,
  ControllerBoardTranslation,
  EventDetailTranslation,
  EventLogTranslation,
  VideoStreamTranslation,
  PersonListTranslation,
  VehicleListTranslation,
  VerificationPanelTranslation,
  CommonTranslation,
  MyOrgTranslation,
  UserProfileTranslation,
  ComponentsLocales,
  ContentFrameTranslation,
  PassageAccessQueueTranslation,
  ReportsTranslation,
  AnalitycsTranslation,
  CardCardTraslation,
  CheckPointCardTraslation,
  LevelPermitCardTraslation,
  ErrorMessagesLocale,
  PassOfficeTraslation,
  FileCardTranslation,
)
