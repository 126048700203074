<template>
  <v-list class="eva-list" :dark="dark">
    <template v-for="(item, index) in items">
      <v-divider v-if="item.divider" :key="index"/>
      <v-list-group v-else-if="item.items" :key="index">

        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $eva.$t(item[nameField]) }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.items" link :to="child.to" :key="child.to" class="">
          <v-list-item-icon>
            <v-icon>
              {{ child.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$eva.$t(child[nameField])">

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list-group>
      <v-list-item v-else link :to="item.to" :key="index" @click.stop="runCommand(item)">
        <v-list-item-icon>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $eva.$t(item[nameField]) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>

/* TODO
1. Убрать использование v-list
2. После добавления механизма команд, переделать обработку клика на него.
*/

export default {
  name: 'eva-list',

  props: {
    items: {
      type: Array,
      default: undefined
    },
    nameField: {
      type: String,
      default: 'name'
    }
  },

  computed: {
    dark() {
      return this.$eva.$themes.current === 'dark';
    }
  },

  methods: {
    runCommand(item) {
      if (!item || !item.command) {
        return;
      }
      item.command();
    }
  }
}
</script>

<style lang="less">
.eva-list {
  width: 100%;
  padding: 0;
  font-size: 1rem;
  .v-list-item {
    min-height: 40px;
    .v-list-item__icon {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 32px;
    }
  }
  .v-list-group__items {
    .v-list-item {
      padding-left: 56px;
    }
  }
}
</style>
