export default {
  core: {
    locales: {
      ru: {
        header: 'Русский'
      },
      en: {
        header: 'Английский'
      },
    },

    themes: {
      dark: {
        header: 'Темная'
      },
      light: {
        header: 'Светлая'
      }
    },

    commands: {
      navigateMain: {
        header: 'Рабочие столы'
      },
      navigateAdmin: {
        header: 'Администрирование'
      },
      logout: {
        header: 'Выйти'
      }
    },

    tables: {
      noData: 'Нет данных',
      loading: 'Загрузка...'
    },

    paginations: {
      itemsPerPage: '{0} строк',
      fromToItems: 'Показано {0} из {1} записей'
    },

    errors: {
      fields: {
        require: 'Поле не может быть пустым',
        regexp: 'Поле не соответсвует заданному выражению',
        email: 'Поля не является адресом электронной почты',
        passport: 'Поле не является номером пасспорта'
      }
    }
  }
}
