import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-wrapper"},[_vm._l((_vm.panels),function(p,index){return _c(VNavigationDrawer,{key:index,class:{ 'v-navigation-drawer--left': p.position === 'left' },style:({
      zIndex: _vm.startZIndex + index,
      pointerEvents: index === _vm.panels.length - 1 ? 'auto' : 'none',
    }),attrs:{"width":['left', 'right'].includes(p.position) && !_vm.fullscreen ? `${p.size}%` : '100%',"height":['top', 'bottom'].includes(p.position) ? `${p.size}%` : '100%',"right":p.position === 'right',"left":p.position === 'left',"bottom":p.position === 'bottom',"fixed":"","stateless":"","temporary":""},model:{value:(p.opened),callback:function ($$v) {_vm.$set(p, "opened", $$v)},expression:"p.opened"}},[_c('div',{staticClass:"panel-inner"},[_c('div',{staticClass:"panel-top"},[_c('span',{staticClass:"panel-title text-h5"},[_vm._v(_vm._s(p.title))]),_vm._v(" "),_vm._l((p.actions),function({ label, action, uiOptions = {} },i){return _c(VBtn,{key:i,staticClass:"mr-2",attrs:{"loading":p.loading,"color":uiOptions.color,"outlined":uiOptions.outlined,"disabled":uiOptions.disabled},on:{"click":function($event){return _vm.executeAction(p, action)}}},[_vm._v(_vm._s(label))])}),_vm._v(" "),_c(VBtn,{staticClass:"mr-2",attrs:{"elevation":"2","min-width":"36","max-width":"36","width":"36"},on:{"click":function($event){return _vm.$panel.close()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],2),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(p.component,_vm._b({tag:"component"},'component',p.props,false))],1)])}),_vm._v(" "),_c('app-confirm-dialog',{ref:"closePanelDialog"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }