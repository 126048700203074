import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({staticClass:"v-date-field",attrs:{"label":_vm.label,"readonly":"","outlined":_vm.outlined,"clearable":_vm.clearable,"dense":""},on:{"click:clear":_vm.onClear},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","first-day-of-week":1,"locale":"ru"},model:{value:(_vm.dateEdit),callback:function ($$v) {_vm.dateEdit=$$v},expression:"dateEdit"}},[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dateEdit)}}},[_vm._v("\n      "+_vm._s(_vm.$t('common.dialog.ok'))+"\n    ")]),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("\n      "+_vm._s(_vm.$t('common.dialog.cancel'))+"\n    ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }