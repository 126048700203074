import {v4} from 'uuid';
import SocketEvents from './socket-events';

class EventBusTask {
  constructor(type, req, options) {
    this.id = v4();
    this.type = type;
    this.req = req || {};
    this.options = options || {};
    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  async send(socket) {
    await socket.emit(SocketEvents.REQUEST, {
      id: this.id,
      type: this.type,
      req: this.req,
      options: this.options,
      ts: Date.now(),
    });
  }

  done(error, res) {
    if (error) {
      this._reject(error);
    } else {
      this._resolve(res);
    }
  }

  async wait() {
    return await this._promise;
  }
}

export default EventBusTask;
