<template>
  <eva-repeater
    :settings="settings"
    class="eva-table"
  >

    <template v-slot:items="{ options, items }">
      <table>
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.value" :width="column.width">
              {{ column.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td v-for="column in columns" :key="column.value">
              <slot :name="`item.${column.value}`" :item="item">
                {{ item[column.value] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

  </eva-repeater>
</template>

<script>
export default {
  name: 'eva-table',

  props: {
    settings: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      columns: []
    };
  },

  watch: {
    'settings.columns': {
      handler() {
        this.columns = this.getColumns();
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    getColumns() {
      if (!this.settings.columns) {
        return [];
      }
      let result = [];
      let sort = null;
      this.settings.columns.forEach((column) => {
        if (column.name === 'actions') {
          result.push({
            text: "",
            value: column.name,
            sortable: false,
          });
        } else {
          result.push({
            text: this.$eva.$t(`$t.${this.settings.prefix}.columns.${column.name}`),
            value: column.name,
            width: column.width,
            sortable: column.sort !== false,
            align: 'start'
          });
          if (column.sort !== false && (!sort || column.sort)) {
            sort = column;
          }
        }
      });

      if (sort) {
        //this.options.sortBy = [sort.name];
        //this.options.sortDesc = (sort.sort && sort.sort.toUpperCase() === 'DESC') ? [true] : [];
      }

      return result;
    }
  }
};
</script>

<style lang="less">
.eva-table {
  padding: @eva-padding;
  table {
    width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: separate;
    tr {
      border: none;
    }
    th {
      text-align: left;
      font-size: 1rem;
      font-weight: bold;
      padding: @eva-padding 0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 42px;
      border: none;
      background-color: white;
      white-space: nowrap;
      z-index: 1;
    }
    td {
      text-align: left;
      font-size: 1rem;
      padding: (@eva-padding / 2) 0;
      height: 32px;
      border: none;
    }
    th,
    td:not(:last-child) {
      border-bottom: (@eva-border-width / 2) solid rgba(0,0,0,.12);
    }
    .eva-table__progress {
      padding: 0;
      border: none;
      height: auto;
      .v-progress-linear {
        height: @eva-border-width!important;
      }
    }
  }
}
</style>


