const SubscriptionTranslations = {
  header: "Подписки",
  searchPanel: {
    search: "Поиск"
  },
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      url: {
        text: "Адрес",
        sortable: false,
      },
      filter: {
        text: "Фильтры",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Удалить подписку {0}",
    }
  },
  columns: {
    id: "Идентификатор",
    name: "Описание",
    status: "Статус",
    url: "Адрес",
    filter: 'Фильтры'
  },
  add: "Добавление подписки",
  remove: "Удалить подписку?",
  edit: "Редактировать подписку"
}

export default SubscriptionTranslations
