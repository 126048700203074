<template>
  <div class="eva-form">
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: 'eva-form',

  props: {
    settings: {
      type: Object
    },
    model: {
      type: Object
    }
  }
}
</script>

<style lang="less">
  .eva-form {

  }
</style>