export default {
  "wp-task-view": {
    name: "Просмотр задания",
    labels: {
      creator: "Автор",
      from: "Срок действия: с",
      to: "по",
      contractor: "Исполнитель",
      description: "Описание",
      stages: "Этапы",
      location: 'Местоположение',
      expired: 'Просрочено',
      source: 'Объект',
    },
    format: "DD.MM.YYYY HH:mm",
    components: {
      taskStage: {
        labels: {
          startDate: "Начало этапа",
          endDate: "Завершение этапа",
          status: "Статус",
          comment: "Комментарий"
        },
        format: "DD.MM.YYYY HH:mm"
      },
      source: {
        vehicle: {
          brand: 'Марка',
          color: 'Цвет',
          model: 'Модель',
          number: 'Номер',
        }
      }
    }
  }
};
