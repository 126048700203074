const CatalogTranslations = {
  header: "Catalog",
  remove: "Remove element?",
  add: "Add element",
  create: "Create element",
  edit: "Edit element",
  save: "Save",
  cancel: "Cancel", 
  searchPanel: {
    search: "Search"
  },
  columns: {
    id: "ID",
    name: 'Name',
    nameRu: "Name [ru]",
    nameEn: "Name [en]",
    params: "Parameters",
    sys_name: "System name",
    description: "Description"
  },
  labels: {
    name: "Catalog name",
  },
  buttons: {
    save: "Save",
    cancel: "Cancel",
    exit: "Exit",
    back: "Back",
  },
  params: {
    name: "Status",
    icon: "Icon",
    color: "Color",
  },
  mainTable: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Edit catalog",
      remove: "Remove catalog",
    }
  },
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      params: {
        text: "Params",
        sortable: false,
      },
      sys_name: {
        text: "System name",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Edit element",
      remove: "Remove element",
    }
  },
  errors: {
    required: "Field is required",
    alpha: "The value must contain only latin characters",
    uniqueId: "A directory type with the same ID already exists",
    uniqueName: "A directory type with the same name already exists",
  },
}

export default CatalogTranslations
