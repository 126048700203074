export default {
  "wp-file-card": {
    name: "Карточка файла",
    title: {
      update: "Обновить файл",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      details: {
        topLabel: "Сведения",
        name: "Название",
      },
    },
    errors: {
      required: "Обязательное поле",
    },
  }
};
