<template>
  <img
    class="eva-image"
    :style="style"
    :src="src"
  />
</template>

<script>
export default {
  name: 'eva-image',

  props: {
    src: {
      type: String
    },
    size: {
      type: Number
    }
  },

  computed: {
    style() {
      if (this.size) {
        return {
          width: `${this.size}px`,
          height: `${this.size}px`
        };
      }
    }
  }
}
</script>
