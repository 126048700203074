export default {
  "wp-task-list": {
    name: "Реестр заданий",
    labels: {
      createTaskBtn: "Новое задание",
      createBtn: "Создать",
      saveBtn: "Сохранить",
      acceptBtn: "Принять",
      rejectBtn: "Отклонить",
      sortBy: "Сортировать",
      tasksPerPage: "Заданий на странице",
      sortSelects: {
        updateTime: "По времени обновления",
        type: "По типу задания",
        author: "По автору",
        performer: "По исполнителю",
        status: "По статусу",
      },
      searchPlaceholder: "Поиск",
      taskNumber: "Задание №:",
    },
    title: {
      update: "Редактирование задания",
    },
    actions: {
      saveButton: "Сохранить",
    },
    infoMessages: {
      noResultsText: "Результатов по запросу нет. Попробуйте поменять запрос",
      loadingText: 'Загрузка данных...'
    },
    errors: {
      get: "Не удалось загрузить список заданий",
      type: {
        required: "Тип не может быть пустым",
      },
      performer: {
        required: "Исполнитель не может быть пустым",
      },
      deadlineTime: {
        required: "Срок выполнения не может быть пустым",
      },
    },
    components: {
      datePicker: {
        buttons: {
          todayBtn: "Сегодня",
          weekBtn: "Неделя",
          monthBtn: "Месяц",
          anotherPeriodBtn: "Другой период",
          applyBtn: "Применить",
        },
        labels: {
          startDate: "Начало периода",
          endDate: "Конец периода",
          allTime: "За все время",
          from: "С",
          to: "По",
        },
        format: "DD.MM.YYYY",
      },
      taskFilterCard: {
        title: "Фильтры",
        byAuthor: "Укажите автора",
        byPerformer: "Укажите исполнителя",
        byStatus: "Укажите статус задания",
        byDeadline: "Укажите время исполнения",
        byPriority: "Укажите приоритет",
        expired: "Просрочено",
        notExpired: "Не просрочено",
        selectAll: "Выбрать все",
        applyBtn: "Применить",
        clearBtn: "Очистить",
        hideCompleted: "Скрывать завершенные",
        statuses: {
          "TaskStatus:NewTask": "Новое задание",
          "TaskStatus:DeclinedTask": "Отменено",
          "TaskStatus:AccomplishedTask": "Завершено",
          "TaskStatus:FailedTask": "Провалено",
          "TaskStatus:Accepted": "Принято к выполнению",
        },
        priorities: {
          "TaskPriority:Normal": "Обычный",
          "TaskPriority:High": "Высокий",
          "TaskPriority:Critical": "Критический",
        },
        actions: {
          apply: 'Применить',
          clear: 'Очистить'
        }
      },
      searchPanel: {
        search: "Поиск",
      },
      listItem: {
        headers: {
          number: {
            text: "Номер",
            sortable: true,
          },
          ref_priority: {
            text: "Приоритет",
            sortable: true,
          },
          ref_task_type: {
            text: "Тип",
            sortable: true,
          },
          name: {
            text: "Название",
            sortable: true,
          },
          ref_author: {
            text: "Автор",
            sortable: true,
          },
          ref_performer: {
            text: "Исполнитель",
            sortable: true,
          },
          deadline_time: {
            text: "Срок",
            sortable: true,
          },
          ref_status: {
            text: "Статус",
            sortable: true,
          },
        },
        labels: {
          number: "Номер",
          type: "Тип",
          name: "Название",
          priority: "Приоритет",
          author: "Автор",
          from: "Срок выполнения с",
          to: "по",
          performer: "Исполнитель",
          noDeadline: "Без ограничения срока",
          deadline: "Срок выполнения",
          until: "До",
          status: "Статус",
          subjectType: "Тип субъекта",
          subject: {
            person: "Персона",
            vehicle: "Транспорт",
          },
          source: "Источник события",
        },
        format: "DD.MM.YYYY",
        actionButtons: {
          accept: "В работу",
          complete: "Завершить",
          decline: "Отклонить",
          pause: "Приостановить",
        },
      },
      table: {
        title: "История",
        headers: {
          name: "Название",
          result: "Результат",
          images: "Изображения",
        }
      },
    },
  },
};
