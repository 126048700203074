import Vue from "vue";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaRouteManager from "./EvaRouteManager";

Vue.config.devtools = true;
Vue.config.productionTip = false;

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@eva/client/styles/index.less';

import VueRouter from 'vue-router';
import Vuetify from 'vuetify/lib';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';

Vue.use(VueRouter);
Vue.use(Vuetify, {iconfont: 'mdi'});
Vue.use(Vuelidate);
Vue.use(VueMask);

import createPlugins from '@eva/client/plugins';
import locales from '@eva/client/locales';
import WidgetsPlugin from "../../../core/client/core/plugins/widgets/widgets-plugin";
WidgetsPlugin.install();

const widgets = [];

class EvaApp {
  constructor() {

    this.routeManager = new EvaRouteManager(this);
    this.router = new VueRouter({});

    let plugins = createPlugins(this);

    Object.assign(this, plugins);

    this.$tools.handleKeys(
      this,
      (plugin) => plugin instanceof EvaPlugin,
      (plugin) => plugin.install()
    );

    Vue.prototype.$eva = this;
  }

  components(...components) {
    components.forEach((component) => {
      Vue.component(component.name, component)
    });
    return this;
  }

  async init() {
    this.root = new Vue({
      vuetify: new Vuetify(),
      router: this.router,
      render: (h) => h(this.component),
      mounted() {
        document.dispatchEvent(new CustomEvent('appMounted'));
      }
    });

    await this.$tools.handleKeysAsync(
      this,
      (plugin) => plugin instanceof EvaPlugin,
      (plugin) => plugin.init()
    );

    this.$tools.handleKeys(
      locales,
      (messages, locale) => this.$locales.register(locale, messages)
    );


    widgets.forEach(({ component, componentSettings, fields }) => {
      Vue.prototype.$widgets.register(component, componentSettings, fields);
    })

  }

  registerWidget({ component, componentSettings, fields }) {
    widgets.push({
      component,
      componentSettings,
      fields
    });
  }

  bootstrap(component) {
    this.component = component;
    this.root.$mount('#app');
  }
}

export default EvaApp;
