export default {
  "wp-level-permit-card": {
    name: "Level Permit",
    notSet: "Not set",
    title: {
      new: "New Level Permit",
      update: "Edit Level Permit",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Information",
        name: "Name",
      },
    },
  },
};
