const IconListTranslations = {
  header: "Иконки",
  add: "Добавление",
  edit: "Редактирование",
  remove: "Удалить иконку?",
  save: "Сохранить",
  cancel: "Отмена",
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: false,
      },
      extension: {
        text: "Расширение",
        sortable: false,
      },
      download_url: {
        text: "Адрес",
        sortable: false,
      },
      actions: {
        text: "",
        sortable: false,
      },
    },
  item: {
      remove: "Удалить иконку {0}",
    }
  },
  columns: {
    name: "Название",
  },
  dialogs: {
    changeCheckpoint: {
      title: "Вы уверены, что хотите изменить иконку?",
    },
  },
  options: {
    itemsPerPage: "Записей на странице",
  },
  errors: {
    required: "Обязательное поле",
  }
}

export default IconListTranslations
