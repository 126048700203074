<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        readonly
        :outlined="outlined"
        :clearable="clearable"
        @click:clear="onClear"
        v-bind="attrs"
        v-on="on"
        dense
        class="v-date-field"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dateEdit"
      no-title
      scrollable
      :first-day-of-week="1"
      locale="ru"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(dateEdit)"
      >
        {{ $t('common.dialog.ok') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        {{ $t('common.dialog.cancel') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
import { setDateToStart, setDateToEnd} from "../../../../plugins/core-plugin/client/components/utils/datetime-helpers";
const PICKER_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'v-date-field',

  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    // startDayFlag : {
    //   type    : Boolean,
    //   default : false
    // },
    endDayFlag: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      menu: false,
      date: null,
      dateEdit: null,
      dateFormatted: null,
    }
  },

  watch: {
    value: {
      handler(value) {
        if (value == null) {
          this.date = null;
        } else {
          this.date = moment.utc(value).format(PICKER_FORMAT);
        }
      },
      immediate: true
    },
    date: {
      handler(value) {
        this.dateEdit = value;
        if (value) {
          // let date = setDateToStart(value, PICKER_FORMAT);
          // let res = date.utc();
          let date;
          if (this.endDayFlag) {
            date = setDateToEnd(value, PICKER_FORMAT);
          } else {
            date = setDateToStart(value, PICKER_FORMAT);
          }
          this.dateFormatted = date.format(this.dateFormat);
          this.$emit('input', date.valueOf());
        } else {
          this.dateFormatted = '';
          this.$emit('input', null);
        }
      },
      immediate: true,
    }
  },

  methods: {
    onClear() {
      this.date = null;
    }
  }
}
</script>
