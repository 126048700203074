import axios from "axios";

import Plugin from "../../plugin";

class HttpPlugin extends Plugin {

    static install(Vue) {
        Vue.prototype.$http = axios;
    }

}

export default HttpPlugin;
