import Plugin from "../../plugin";
import tools from '../../tools';

class ToolsPlugin extends Plugin {

  static install(Vue) {
    Vue.prototype.$tools = tools;
  }

  constructor() {
    super();
  }
}

export default ToolsPlugin;
