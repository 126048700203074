const RolesTranslation = {
  header: "Roles",
  table: {
    headers: {
      id: {
        text: "ID",
        sortable: true,
      },
      name: {
        text: "Name",
        sortable: true,
      },
      admin: {
        text: "Administrator",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    }
  },
  columns: {
    id: "ID",
    name: "Name",
    admin: "Administrator",
  },
  add: 'Add role',
  edit: 'Edit role',
  admin: 'Administrator',
  roleGroup: "Group",
  remove: 'Remove role?'
}

export default RolesTranslation
