import UserTranslations from "./users";
import LogoSettingsTranslations from "./logo-settings";
import PermissionsTranslations from "./permissions";
import CheckpointsTranslations from "./checkpoints";
import IconListTranslations from "./icon-list";
import CatalogTranslations from "./catalogs";
import ScenariosTranslations from "./scenarios";
import EventCatalogsTranslations from "./event-catalogs";
import RolesTranslation from "./roles";
import RoleGroupsTranslation from "./role-groups";
import OrganizationTranslations from "./organizations";
import DesktopTranslations from "./desktops";
import DesktopGroupsTranslations from "./desktopgroups";
import EventReceiverSettingsTranslations from "./event-receiver-settings";
import ReportConfiguratorTranslation from "./report-configurator";
import MetadataTranslations from "./metadata";

const AdminTranslations = {
  admin: {
    roles: {
      ...RolesTranslation
    },
    roleGroups: {
      ...RoleGroupsTranslation,
    },
    users: {
      ...UserTranslations,
    },
    logoSettings: {
      ...LogoSettingsTranslations
    },
    eventReceiverSettings: {
      ...EventReceiverSettingsTranslations
    },
    permissions: {
      ...PermissionsTranslations
    },
    checkpoints: {
      ...CheckpointsTranslations
    },
    iconlist: {
      ...IconListTranslations
    },
    catalogs: {
      ...CatalogTranslations
    },
    scenarios: {
      ...ScenariosTranslations
    },
    eventCatalogs: {
      ...EventCatalogsTranslations
    },
    organizations: {
      ...OrganizationTranslations
    },
    desktops: {
      ...DesktopTranslations,
    },
    desktopgroups: {
      ...DesktopGroupsTranslations,
    },
    reportConfigurator: {
      ...ReportConfiguratorTranslation
    },
    metadata: {
      ...MetadataTranslations
    },
  }
}

export default AdminTranslations
