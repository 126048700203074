import RuLocale from "./ru-locale";
import AdminTranslations from "./admin";
import LoginTranslations from "./login";
import MainTranslations from "./main";

const Translations = {
  ...RuLocale,
  ...AdminTranslations,
  ...LoginTranslations,
  ...MainTranslations
}

/*let text = '';
Object.keys(AdminTranslations).forEach((key) => {
  let value = AdminTranslations[key];
  if (value.header) {
    if (text) {
      text += ',';
    }
    text += '{\n\t';
  }
})*/

export default Translations
