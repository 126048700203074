export default {
  "wp-checkpoint": {
    name: 'Карточка КПП',
    labels: {
      titleNew: 'Новый КПП',
      titleUpdate: 'КПП',
      name: 'Название',
      saveButton: 'Сохранить',
    },
    rules: {
      name: {
        requiredMessage: 'Обязательное поле'
      },
    },
  }
};
