export default {
  "wp-org-card": {
    name: "Карточка организации",
    title: {
      new: "Новая организация",
      update: "Редактирование контрагента",
      myOrg: "Моя организация",
      selection: "Добавление контрагента",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      details: {
        topLabel: "Реквизиты",
        contractor: "Название",
        tin: "ИНН",
        iec: "КПП",
        psrn: "ОГРН/ОГРНИП",
        partner: "Партнёры",
      },
      contacts: {
        topLabel: "Контактные лица",
      },
      address: {
        topLabel: "Адрес",
      },
      contracts: {
        topLabel: "Договоры",
        withoutContract: "Действует без договора",
        archive: "Архивная",
      },
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        minLength: "Значение должно быть не менее {0} символов",
        maxLength: "Значение должно быть не более {0} символов",
        numeric: 'Значением может быть только число',
        lengthTin: "Длина должна быть {0} или {1} символов",
        minLengthTin: "Длина должна быть {0} символов",
        maxLengthTin: "Длина должна быть {0} символов",
        lengthIec: "Длина должна быть {0} символов",
        lengthPsrn: "Длина должна быть {0} или {1} символов",
        minLengthPsrn: "Длина должна быть {0} символов",
        maxLengthPsrn: "Длина должна быть {0} символов",
        uniqueTin: "Организация с такими данными уже существует",
        uniqueIec: "Организация с такими данными уже существует",
        uniquePsrn: "Организация с такими данными уже существует",
        certainSymbols: "Ввёден запрещённый символ",
      },
      tin: {
        unique: "Организация с таким ИНН уже существует",
      },
    },
  },
};
