export default {
  "wp-events-event-log": {
    name: "Журнал событий",
    options: {
      itemsPerPage: "Записей на странице"
    },
    table: {
      unknownEvent: 'Неизвестное событие',
      format: 'HH:mm:ss, DD.MM.YYYY',
      headers: {
        time: "Дата и время",
        typeEvent: "Тип",
        directObj: "Источник события",
        subject: "Субъект",
        description: "Описание",
        location: "Место события",
        alarm_level: 'Тревога',
        file: "Файл",

      },
    },
    components: {
      datePicker: {
        buttons: {
          todayBtn: "Сегодня",
          weekBtn: "Неделя",
          monthBtn: "Месяц",
          anotherPeriodBtn: "Другой период",
          applyBtn: "Применить"
        },
        labels: {
          startDate: "С даты",
          endDate: "По дату",
          allTime: "За все время",
          from: "С",
          to: "По"
        },
        format: "DD.MM.YYYY"
      },
    },
    labels: {
      placeholderSearchBar: "Введите текст для поиска"
    },
    settings: {
      header: 'Заголовок',
      eventSettings: 'Настройки событий',
      directObjs: "Источники события",
      subjects: "Субъекты",
      locations: "Места события",
      needToCount: "Отображать общее количество событий",
      needToCountHint: "Получение событий замедлится",
    },
    inputs: {
      refreshList: "Обновление списка",
    }
  },
}
