class LocalStorage {

    constructor() {
        try {
            const test = '__localStorage_test__';
            window.localStorage.setItem(test, test);
            window.localStorage.removeItem(test);
            this._supported = true;
        } catch (e) {
            this._supported = false;
            console.error('Local storage is not supported');
        }
    }

    get(key) {
        if (!this._supported) {
            return null;
        }
        let stateStr = window.localStorage[key];
        if (!stateStr) {
            return null
        }
        try {
            let state = JSON.parse(stateStr);
            return state.value;
        }
        catch(e) {
            return null;
        }
    }
    set(key, value) {
        if (!this._supported) {
            return;
        }
        let state = { value };
        let stateStr = JSON.stringify(state);
        window.localStorage.setItem(key, stateStr);
    }
    remove(key) {
        if (!this._supported) {
            return;
        }
        window.localStorage.removeItem(key);
    }

}

export default LocalStorage;
