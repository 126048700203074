const EventReceiverSettingsTranslations = {
  header: "Настройки событий",
  labels: {
    title: "Укажите необходимые настройки",
    streamName: "Имя потока",
  },
  errorMessages: {
    loading: "Не удалось загрузить данные настроек",
    updating: "Не удалось сохранить настройки",
    cannotToggleStream: "Не удалось переключить поток",
  },
  infoMessages: {
    updateSuccess: "Настройки успешно сохранены",
  },
}

export default EventReceiverSettingsTranslations
