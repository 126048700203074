<template>
  <div class="eva-overlay">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'eva-overlay'
}
</script>

<style lang="less">
.eva-overlay {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  opacity: 0.46;
  background-color: rgb(33, 33, 33);
}
</style>
