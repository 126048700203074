export default {
  "wp-my-org": {
    name: "Моя организация",
    contractor: 'Вы работаете от {0}',
    exit: 'Выйти',
    removeTitle: 'Подтвердите',
    buttons: {
      add: 'Добавить',
      select: 'Выбрать из справочника'
    },
    tabs: {
      contractors: "Контрагенты",
      persons: "Физические лица",
      vehicles: "Транспорт",
      contracts: "Договоры",
      files: "Дисковое пространство",
    },
    table: {
      contractors: {
        headers: {
          name: {
            text: "Наименование",
            sortable: true,
          },
          tin: {
            text: "ИНН",
            sortable: false,
          },
          contracts: {
            text: "Договоры",
            sortable: false,
          },
        },
        item: {
          format: "DD.MM.YYYY",
          contractField: "С {0} по {1}",
          checkFailed: 'Невозможно удалить контрагента с действующим договором',
          remove: 'Вы уверены, что хотите убрать из контрагентов?',
          actionsTips: {
            enterAsContractor: "Войти под контрагентом {0}",
            removeItem: 'Убрать из контрагентов {0}',
          }
        }
      },
      persons: {
        headers: {
          name: {
            text: "ФИО",
            sortable: true,
          },
          hasUser: {
            text: "Аккаунт",
            sortable: false,
          },
          position: {
            text: "Должность",
            sortable: false,
          },
          securityList: {
            text: "Список",
            sortable: false,
          },
          resident: {
            text: "Гражданин РФ",
            sortable: false,
          },
        },
        item: {
          remove: 'Вы уверены, что хотите удалить физлицо?',
          removeLinkedUser: "Вы уверены, что хотите удалить учетную запись?",
          actionsTips: {
            removeItem: "Удалить {0}",
            removeUser: "Удалить учетную запись",
            addUser: "Добавить учетную запись"
          }
        }
      },
      vehicles: {
        headers: {
          license_plate: {
            text: "Госномер",
            sortable: true,
          },
          brand: {
            text: "Марка, модель",
            sortable: false,
          },
          securityList: {
            text: "Пометка списка",
            sortable: false,
          },
        },
        item: {
          remove: 'Вы уверены, что хотите удалить TС?',
          actionsTips: {
            removeItem: "Удалить {0}"
          }
        }
      },
      contracts: {
        headers: {
          name: {
            text: "Номер",
            sortable: true,
          },
          customer: {
            text: "Заказчик",
            sortable: false,
          },
          contractor: {
            text: "Исполнитель",
            sortable: false,
          },
          responsiblePerson: {
            text: "Ответственный",
            sortable: false,
          },
          startDate: {
            text: "Дата начала",
            sortable: false,
          },
          endDate: {
            text: "Дата окончания",
            sortable: false,
          },
        },
        item: {
          format: "DD.MM.YYYY",
          remove: 'Вы уверены, что хотите архивировать контракт?',
          actionsTips: {
            removeItem: "Архивировать {0}"
          }
        }
      },
      files: {
        headers: {
          image: {
            text: '',
            sortable: false,
            width : '10px',
          },
          name: {
            text: "Название",
            sortable: true,
          },
          size: {
            text: "Размер",
            sortable: true,
          },
        },
        item: {
          format: "DD.MM.YYYY",
          remove: 'Вы уверены, что хотите удалить файл?',
          actionsTips: {
            editItem: "Переименовать {0}",
            downloadItem: "Скачать {0}",
            removeItem: "Удалить {0}",
          }
        }
      },
    },
    components: {
      searchPanel: {
        search: "Поиск"
      },
      filters: {
        title: 'Параметры фильтра'
      }
    },
    errors: {
      contractors: {
        list: 'Не удалось загрузить список контрагентов',
        item: 'Не удалось загрузить контрагента',
        update: 'Не удалось изменить контрагента',
        add: 'Не удалось добавить контрагента',
      },
      persons: {
        list: 'Не удалось загрузить список физлиц',
        item: 'Не удалось загрузить физлицо',
        update: 'Не удалось изменить физлицо',
        add: 'Не удалось добавить физлицо',
        remove: 'Не удалось удалить физлицо'
      },
      vehicles: {
        list: 'Не удалось загрузить список TC',
        item: 'Не удалось загрузить TC',
        update: 'Не удалось изменить TC',
        add: 'Не удалось добавить TC',
        remove: 'Не удалось удалить TC'
      },
      contracts: {
        list: 'Не удалось загрузить список договоров',
        item: 'Не удалось загрузить договор',
        update: 'Не удалось изменить договор',
        add: 'Не удалось добавить договор',
        remove: 'Не удалось удалить договор'
      },
      myOrgLoad: "Не удалось загрузить данные корневой организации"
    }
  }
};
