import Vue from "vue";

class UserStorage {

  constructor() {
    this._data = null;
  }

  get(path) {
    let data = this._data;
    if (!data) {
      return;
    }

    let paths = path.split('.');
    while (paths.length) {
      let n = paths.shift();
      if (!(n in data)) {
        return;
      }
      data = data[n];
    }
    return data;
  }
  async set(path, value) {
    let data = await this.initialize();
    if (!data) {
      return;
    }

    let paths = path.split('.');
    while (paths.length - 1) {
      let n = paths.shift();
      if (!(n in data)) {
        data[n] = {};
      }
      data = data[n];
    }
    data[paths[0]] = value;

    await this.save();
  }
  async remove(path) {
    let data = await this.initialize();
    if (!data) {
      return;
    }

    let paths = path.split('.');
    while (paths.length - 1) {
      let n = paths.shift();
      if (!(n in data)) {
        return;
      }
      data = data[n];
    }
    delete data[paths[0]];

    await this.save();
  }

  async initialize() {
    if (!this._data) {
      await this.load();
    }
    return this._data;
  }

  async load() {
    if (window.location.href.includes('login') || window.location.href.includes('metadata')) {
      return;
    } else {
      try {
        let res = await this.$http.get('/api/v1/users/storage');
        this._data = res.data;
      } catch (error) {
        // document.location.href = '#';
        console.log('load user storage error');
        console.log(error);
      }
    }
  }

  async save() {
    try {
      await this.$http.put('/api/v1/users/storage', this._data || {});
    } catch (error) {
      console.log('save user storage error');
      console.log(error);
    }
  }

  get $http() {
    return Vue.prototype.$http;
  }
}

export default UserStorage;
