const WidgetTranslations = {
  columns: {
    name: "Название"
  },
  tabs: {
    common: "Общие",
    settings: "Настройки",
    inputs: "Входы",
    outputs: "Выходы",
    eventFilters: "События",
  },
  edit: "Редактирование виджета",
  remove: "Удалить виджет?",
  eventSettings: {
    addEventFilter: "Добавить фильтр",
    editEventFilter: "Изменить фильтр",
    allEventFilter: "Слушать все события",
    defaultFilters: "Предустановленные фильтры",
    customFilters: "Пользовательские фильтры",
    name: "Название",
    dialogs: {
      deleteFilter: {
        text: "Действие нельзя отменить.",
        title: "Удалить фильтр?",
      },
    },
  },
}

export default WidgetTranslations
