export default {
  "user-profile": {
    name: "Personal account",
    exit: 'Exit',
    labels: {
      information: {
        topLabel: 'About myself',
        about: 'Tell us about yourself',
        firstName: 'First name',
        middleName: 'Middle name (if presence)',
        lastName: 'Last name',
        userProfilePhoto: 'Attach photo',
        birthDate: 'Birth date',
        birthPlace: 'Birthplace',
        sex: 'Sex',
        alertInfo: 'You cannot edit this section, to change the data, contact the HR department',
        login: 'Login',
        password: 'New password',
      },
      sections: {
        about: 'About myself',
        personal: 'Personal information',
        applicationsAndPermits: 'Applications and passes',
        actions: 'Actions',
        access: 'Access',
      },
      docType: {
        Passport: "Passport",
        ForeignPassport: "Foreign passport",
        DriverLicense: "Driver license"
      },
      contacts: {
        topLabel: 'Contacts',
        workphone: 'Work phone number',
        telephone: 'Phone number',
        email: 'Email',
        
      },
      document: {
        topLabel: 'Personal information',
        seriesDocument: 'Series',
        numberDocument: 'Number',
        dateDocument: 'Issue date',
        departmentCode: 'Department code',
        issuedDocument: 'Issued departament', 
      },
      filterBtn: 'Filter',
      resetFilterBtn: 'Reset',
      orderFilter: {
        status: 'Status',
        checkPoints: 'Access',
        dateCreation: 'Order creation date',
        datePeriodPass: 'Period of validity of pass',
        timePeriodPass: 'Time period of validity of pass',
        from: 'from',
        to: 'to',
        on: 'Pass validity date'
      }
    },
    preferences: {
      dateFormat: 'YYYY-DD-MM'
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        correctNumber: "Phone number is incorrect",
      },
    },
    components: {
      searchPanel: {
        search: "Search"
      },
      filters: {
        title: 'Filter parameters'
      }
    },
    orderTypes: {
      complex: 'Complex application',
      person: 'Application for person',
      vehicle: 'Application for vehicle',
    },
    table: {
      format: 'DD.MM.YYYY HH:mm',
      permits: {
        format: "DD.MM.YYYY",
        headers: {
          number: {
            text: 'Number',
            sortable: true,
          },
          type: {
            text: 'Pass type',
            sortable: true,
          },
          status: {
            text: 'Status',
            sortable: true,
          },
          startTime: {
            text: 'Validity',
            sortable: true,
          },
          markup: {
            text: 'Markup',
            sortable: false,
          },
        },
        currentStatus: {
          Active: 'Active',
          Block: 'Block',
          Deactivated: 'Deactivated',
        },
        type: {
          PermitPerson: 'Private person',
        }
      },
      orders: {
        format: "DD.MM.YYYY",
        headers: {
          number: {
            text: 'Number',
            sortable: true,
          },
          type: {
            text: 'Order type',
            sortable: false,
          },
          status: {
            text: 'Status',
            sortable: true,
          },
          dateValidFrom: {
            text: 'Date of application',
            sortable: true,
          },
          markup: {
            text: 'Markup',
            sortable: false,
          },
        },
      },
      actions: {
        format: "DD.MM.YYYY",
        headers: {
          date: {
            text: 'Date and time',
            sortable: true,
          },
          subject: {
            text: 'Subject',
            sortable: true,
          },
          location: {
            text: 'Location',
            sortable: true,
          },
          type: {
            text: 'Event type',
            sortable: true,
          },
          violation: {
            text: 'Violation',
            sortable: false,
          },
          file: {
            text: 'File',
            sortable: false,
          },
        },
      },
    },
  },
};
