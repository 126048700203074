export default {
  "wp-vehicle-card": {
    name: "Карточка ТС",
    notSet: "Не назначен",
    title: {
      new: "Новое ТС",
      update: "Редактирование ТС",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      passport: {
        topLabel: "Паспорт ТС",
        brand: 'Марка, модель',
        licensePlate: 'Госномер',
        bodyType: 'Тип',
        attachPhoto: 'Прикрепите фото',
        color: 'Цвет',
        securityList: 'Список доступа'
      },
      securityList: 'Список доступа'
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        maxLength: "Значение должно занимать не более {0} символов",
        certainSymbols: "Содержит недопустимые символы. Допустимы {0}",
        uniqueLicensePlate: "ТС с таким гос. номером уже есть",
      },
    },
  },
};
