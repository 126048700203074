const ScenariosTranslations = {
  header: "Сценарии визирования",
  add: "Создание",
  edit: "Редактирование",
  remove: "Удалить сценарий визирования?",
  save: "Сохранить",
  cancel: "Отменить",
  exit: "Все несохраненные изменения будут потеряны",
  searchPanel: {
    search: "Поиск"
  },
  labels: {
    name: "Название сценария",
    startСondition: "Стартовое условие",
    endСondition: "Конечное условие",
    stageName: "Название состояния",
    startConditionName: "Название стартового условия",
    endConditionName: "Название конечного условия",
    conditionName: "Название условия",
    roles: "Роли",
    organization: "Организация",
    users: "Пользователи",
    scenario: "Сценарий",
    startReason: "Причина запуска",
    determineApprover: "Определять визирующего на основании",
    determineApproverReason: "Основание",
    timeToComplete: "Время на завершение",
    activeAlways: "Состояние активно всегда",
    editRight: "Права на изменения",
    checkpoint: "Точки доступа",
    accessdate: "Время доступа",
    securitylist: "Списки доступа",
    inspection: "Досмотр",
    periodToComplete: {
      days: "Дней",
      hours: "Часов",
    },
  },
  buttons: {
    save: "Сохранить",
    cancel: "Отменить",
    exit: "Выйти",
  },
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Удалить сценарий {0}",
      verifed: "Корректный сценарий",
      unverifed: "В сценарии есть ошибки",
    }
  },
  errors: {
    requiredIfEmptyRoles: "Обязательное поле если не выбраны роли",
    requiredIfEmptyDetermineApprover: "Обязательное поле если не выбрано основание",
  },
}

export default ScenariosTranslations
