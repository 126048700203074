import Plugin from '../../plugin';
import PanelApi from './panel-api';

class PanelPlugin extends Plugin {
    static install(Vue) {
        Vue.prototype.$panel = new PanelApi(Vue.prototype.$eventBuss);
    }

    constructor() {
        super();
    }
}

export default PanelPlugin;
