const RoleGroupsTranslation = {
  header: "Группы ролей",
  add: "Добавление группы",
  edit: "Редактирование группы",
  remove: "Удалить группу?",
  errorMessages: {
    groupHasRoles: "Нельзя удалить группу с привязанными ролями",
    deleteError: "Не удалось удалить группу",
    loadError: "Не удалось загрузить группу",
  },
  deleteGroupDialog: {
    text: "Это действие нельзя отменить",
    title: "Удалить группу ролей?",
  },
}

export default RoleGroupsTranslation
