const CONNECTION = 'connection';
const CONNECT    = 'connect';
const DISCONNECT = 'disconnect';
const REQUEST    = 'wp:request';
const RESPONSE   = 'wp:response';
const WIDGET_SUBSCRIBE = "wp:widget_subscribe";
const WIDGET_UNSUBSCRIBE = "wp:widget_unsubscribe";

const values = {
  CONNECTION,
  CONNECT,
  DISCONNECT,
  REQUEST,
  RESPONSE,
  WIDGET_SUBSCRIBE,
  WIDGET_UNSUBSCRIBE,
};
export default values
