const EventReceiverSettingsTranslations = {
  header: "Event settings",
  labels: {
    title: "Add settings",
    streamName: "Stream name",
  },
  errorMessages: {
    loading: "Settings does not load",
    updating: "Settings does not load",
    cannotToggleStream: "The stream isn't changed",
  },
  infoMessages: {
    updateSuccess: "Settings are successfully saved",
  },
}

export default EventReceiverSettingsTranslations
