import Plugin from "../../plugin";
import Rooms from './rooms';

class RoomsPlugin extends Plugin {

  static install(Vue) {
    Vue.prototype.$rooms = new Rooms();
  }

  constructor() {
    super();
  }
}

export default RoomsPlugin;
