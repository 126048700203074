export default {
  "wp-controller-board": {
    name: 'Табло контроллера',
    settings: {
      cellNumber: 'Номер ячейки',
    },
    errors: {
      emptyCell: 'Cell number isn\'t selected',
      serverError: 'Server error',
      dataError: 'Invalid data'
    },
  }
}
