<template>
  <v-btn
    fab
    x-small
    dark
    elevation="0"
    class="eva-icon-btn"
    :style="{ backgroundColor: color }"
  >
    <v-icon>{{ icon }}</v-icon>
  </v-btn>

</template>

<script>
export default {
  name: 'eva-icon-btn',

  props: {
    color: {
      type: String
    },
    icon: {
      type: String
    }
  }
}
</script>

<style lang="less">
.eva-icon-btn {
  height: 28px!important;
  width: 28px!important;
  color: white!important;
  .v-icon {
    font-size: 16px!important;
  }
}
</style>
