export default {
  'wp-vehicle-list': {
    name: 'Vehicle list management',
    labels: {
      name: 'Vehicle',
      licensePlate: 'License plate',
      brand: 'Brand/Model',
      color: 'Color',
      bodyType: 'Body Type',
      organization: 'Organization',
      drawerLabel: 'Vehicle',
      isCurrent: 'Current vehicle',
      placeholderSearchBar: 'Search by license plate, brand/model or organization',
    },
  },
}
