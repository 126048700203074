import Vue from "vue";
import evaApp from "@eva/client/app";
import createContext from './context';
import plugins from './plugins';

plugins.install(Vue);

class App {

  components(...components) {
    evaApp.components(...components);
    return this;
  }

  route(path, component, props) {
    evaApp.routeManager.route(path, component, props);
    return this;
  }

  get router() {
    return evaApp.router;
  }

  get root() {
    return evaApp.root;
  }

  async init() {
    Vue.prototype.$context = await createContext(Vue.prototype.$http);

    await Vue.prototype.$userStorage.initialize();

    await plugins.init(this);

    this.$eventBuss.init();
    this.$rooms.init(this.$eventBuss.socket, this.$eventBuss.vueInstance);
  }

  async bootstrap(component, handler) {

    await evaApp.init();

    await this.init();

    if (handler) {
      await handler();
    }

    evaApp.bootstrap(component);
  }

  get $dataAccess() {
    return evaApp.$dataAccess
  }

  get $widgets() {
    return Vue.prototype.$widgets
  }

  get $locale() {
    return Vue.prototype.$locale
  }

  get $context() {
    return Vue.prototype.$context
  }

  get $eventBuss() {
    return Vue.prototype.$eventBuss
  }

  get $rooms() {
    return Vue.prototype.$rooms
  }
}

export default App;
