import Plugin from "../../plugin";
import LocalStorage from './local-storage';

class LocalStoragePlugin extends Plugin {

    static install(Vue) {
        Vue.prototype.$storage = new LocalStorage();
    }

    constructor() {
        super();
    }
}

export default LocalStoragePlugin;
