import enLocale from "./en";
import ruLocale from "./ru";

const ru = Object.assign(ruLocale, {});
const en = Object.assign(enLocale, {});

export default {
  ru,
  en
};
