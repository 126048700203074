export default {
  "wp-vehicle-card": {
    name: "Car details",
    notSet: "Not set",
    title: {
      new: "New vehicle",
      update: "Update vehicle",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      passport: {
        topLabel: "Vehicle's passport",
        brand: "Brand, model",
        licensePlate: "License plate",
        bodyType: "Body type",
        attachPhoto: "Attach photo",
        color: "Color",
        securityList: 'Security list'
      },
      securityList: "Security list"
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        certainSymbols: "Contains unavailable symbols. Available {0}",
        uniqueLicensePlate: "There is already a vehicle with this license plate",
      },
    },
  },
};
