const DesktopGroupsTranslations = {
  header: "Desktop groups",
  columns: {
    name: "Name",
    sort_order: "Sort order",
    roles: "Roles"
  },
  add: "Add desktop group",
  edit: "Edit desktop group",
  remove: "Delete desktop group ?"
}

export default DesktopGroupsTranslations
