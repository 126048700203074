const OrganizationTranslations = {
  header: "Организации",
  title: {
    new: "Новая организация",
    update: "Редактирование организации",
  },
  searchPanel: {
    search: "Поиск"
  },
  labels: {
    name: "Название",
    add: "Создать организацию",
    edit: "Редактировать организацию",
    remove: "Удалить организацию?",
    details: {
      topLabel: "Реквизиты",
      name: "Название",
      tin: "ИНН",
      iec: "КПП",
      psrn: "ОГРН",
    },
    contracts: {
      topLabel: "Договоры",
      withoutContract: "Действует без договора",
      archive: "Архивная",
    },
  },
  buttons: {
    saveButton: "Сохранить",
  },
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      tin: {
        text: "ИНН",
        sortable: false,
      },
      iec: {
        text: "КПП",
        sortable: false,
      },
      psrn: {
        text: "ОГРН",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Редактировать {0}",
      remove: "Удалить {0}",
    }
  },
  validationRules: {
    defaults: {
      required: "Требуется ввести значение",
      minLength: "Значение должно быть не менее {0} символов",
      maxLength: "Значение должно быть не более {0} символов",
      numeric: 'Значением может быть только число',
      lengthTin: "Длина должна быть {0} или {1} символов",
      minLengthTin: "Длина должна быть {0} символов",
      maxLengthTin: "Длина должна быть {0} символов",
      lengthIec: "Длина должна быть {0} символов",
      lengthPsrn: "Длина должна быть {0} или {1} символов",
      minLengthPsrn: "Длина должна быть {0} символов",
      maxLengthPsrn: "Длина должна быть {0} символов",
      uniqueTin: "Организация с такими данными уже существует",
      uniqueIec: "Организация с такими данными уже существует",
      uniquePsrn: "Организация с такими данными уже существует",
      certainSymbols: "Ввёден запрещённый символ",
    },
    tin: {
      unique: "Организация с таким ИНН уже существует",
    },
  },
}

export default OrganizationTranslations
