export default {
  'wp-passage-access-queue': {
    name: 'Очередь турникета',
    settings: {
      header: 'Заголовок',
      eventSettings: 'Настройки событий',
      directObjs: "Источники события",
      subjects: "Субъекты",
      locations: "Места события",
    },
    inputs: {
      refreshList: 'Обновление списка событий'
    },
    rules: {},
    fields: {
      entry: 'Вход',
      exit: 'Выход',
      records: 'записей',
      from: 'из',
      shown: 'Показано'
    },
    categoriesEmployee: {
      Visitor: 'Гость',
      Contractor: 'Подрядчик',
      Subcontractor: 'Субподрядчик',
      Employee: 'Работник',
      Security: 'Охранник',
      Chief: 'Руководитель',
      Household: 'Домовладелец'
    },
    errors: {
      configWidget: "События виджета не настроены или настроены некорректно. Пожалуйста, обратитесь к администратору"
    }
  }
}
