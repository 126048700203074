const installed = [];

class Plugin {
  static async init(app) {
    for (let i = 0; i < installed.length; i++) {
      await installed[i].init(app);
    }
  }

  constructor() {
    installed.push(this);
  }

  async init(app) {
    this.app = app;
  }
}

export default Plugin;
