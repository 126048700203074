<template>
  <v-icon
    class="eva-icon"
    :color="color"
  >
    {{ value }}
  </v-icon>
</template>

<script>
/* TODO
  1. Убрать использование v-icon
*/

export default {
  name: 'eva-icon',

  props: {
    color: {
      type: String
    },
    value: {
      type: String
    }
  }
}
</script>

<style lang="less">
.eva-icon {

}
</style>
