import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaPage from "./components/layouts/EvaPage";
import EvaLayout from "./components/layouts/EvaLayout";
import EvaOverlay from "./components/layouts/EvaOverlay";
import EvaGrid from "./components/layouts/EvaGrid";
import EvaSpacer from "./components/layouts/EvaSpacer";
import EvaRowLayout from "./components/layouts/EvaRowLayout";
import EvaColumnLayout from "./components/layouts/EvaColumnLayout";
import EvaHeader from "./components/layouts/EvaHeader";
import EvaDrawer from "./components/layouts/EvaDrawer";
import EvaWidget from "./components/widgets/EvaWidget";
import EvaText from "./components/typography/EvaText";
import EvaPre from "./components/typography/EvaPre";
import EvaIcon from "./components/media/EvaIcon";
import EvaImage from "./components/media/EvaImage";
import EvaList from "./components/repeaters/list/EvaList";
import EvaRepeaterHeader from "./components/repeaters/repeater/EvaRepeaterHeader";
import EvaRepeaterFooter from "./components/repeaters/repeater/EvaRepeaterFooter";
import EvaRepeater from "./components/repeaters/repeater/EvaRepeater";
import EvaTable from "./components/repeaters/table/EvaTable";
import EvaIconBtn from "./components/buttons/EvaIconBtn";
import EvaAlert from "./components/messages/EvaAlert";
import EvaJsonEditor from "./components/json/EvaJsonEditor";
import EvaBtn from "./components/buttons/EvaBtn";


class CoreEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  install() {
    this.app.components(
      EvaPage,
      EvaLayout,
      EvaOverlay,
      EvaGrid,
      EvaSpacer,
      EvaRowLayout,
      EvaColumnLayout,
      EvaHeader,
      EvaDrawer,
      EvaWidget,
      EvaIcon,
      EvaText,
      EvaPre,
      EvaImage,
      EvaList,
      EvaRepeaterHeader,
      EvaRepeaterFooter,
      EvaRepeater,
      EvaTable,
      EvaIconBtn,
      EvaAlert,
      EvaJsonEditor,
      EvaBtn
    );
  }
}

export default CoreEvaPlugin;
