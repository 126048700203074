export default {

  locale: {
    code: 'en',
    name: 'English'
  },

  panel: {
    needCloseConfirm: "All unsaved changes will be lost."
  },

  confirmDialog: {
    defaultTitle: 'Confirm action'
  },

  app: {
    name: 'Rublevo-Arhangel\'skoe',
    copyright: '&copy; ' + new Date().getFullYear() + '. All rights reserved.',
    policy: 'Personal data processing policy and portal terms of use'
  },

  theme: {
    dark: 'Dark',
    light: 'Light'
  },

  login: {
    header: 'Login to the system',
    user: 'User',
    password: 'Password',
    login: 'Login',
    logout: 'Logout',
    register: 'Register'
  },

  mainMenu: {
    myOrg: 'My organization',
    desktops: 'Desktops',
    admin: 'Administration'
  },

  loading: 'Loading...',
  logged: 'Logged in',
  notlogged: 'Not logged in',
  adminPanel: 'Admin panel',
  workPanel: 'Back to desktops',

  common: {
    table: {
      noDataText: 'No data',
      itemsPerPage: 'Items per page'
    },
    dialog: {
      ok: 'OK',
      cancel: 'Cancel'
    },
    loading: 'Loading...',
    logged: 'Logged in',
    notlogged: 'Not logged in',
    adminPanel: 'Admin panel',
    workPanel: 'Back to desktops',
    desktop: {
      icon: 'Icon:',
      selectIcon: 'Select'
    },
    version: "Version",
    btn: {
      save: "Save",
      close: 'Close',
      reset: "Reset",
    },
    dates: {
      from: "From",
      to: "To",
      dateFormat: "MM.DD.YYYY",
      dateTimeFormat: "HH:mm, MM.DD.YYYY",
      dateTimeWithSecFormat: "HH:mm:ss, MM.DD.YYYY",
    },
    components: {
      searchPanel: {
        search: "Search"
      },
    },
    titles: {
      filters: "Filters",
    },
    eventConfig: {
      eventTypes: {
        eventCatalogTypes: 'Event types',
        eventCatalogs: 'Events',
      },
      directObjs: "Objects",
      subjects: "Subjects",
    },

  },

  admin: {
    integrations: {
      header: "Integrations",
      actions: {
        remove: {
          title: 'Remove object',
          confirm: 'Do you really want to delete an object',
        },
        create: {
          title: 'Create object',
          selectPlaceholder: 'Select the object type',
        },
        edit: {
          save: 'Save'
        },
        command: {
          title: 'Execute command',
          execute: 'Execute',
          error: 'The command could not be executed',
          selectPlaceholder: 'Select a command',
        },
      }
    },
    subscriptions: {
      header: 'Subscriptions',
      columns: {
        id: 'ID',
        name: 'Description',
        status: "Status",
        url: 'Endpoint',
        filter: 'Filters'
      },
      add: 'Add subscription',
      remove: 'Remove subscription ?',
      edit: "Edit subscription"
    },
    algorithms: {
      header: "Algorithms"
    },
    desktops: {
      header: 'Desktops',
      newWidget: 'New widget ',
      newDesktop: 'New desktop',
      saved: 'Changes saved',
      saveError: 'Error on save changes',
      columns: {
        name: 'Desktop Name',
        column_count: 'Column count',
      },
      rows: {
        row_count: 'Row count'
      },
      buttons: {
        save: "Save",
        cancel: "Cancel"
      },
      errors: {
        required: "Value required",
        integerRequired: "Value must be integer",
        valueInRange: "Value must be in (1, 200)",
        lengthMaximum: "Maximum length exceeds 50 symbols"
      },
      add: 'Add desktop',
      edit: 'Edit desktop',
      remove: 'Remove desktop ?',

    },
    widgets: {
      columns: {
        name: 'Name'
      },
      tabs: {
        common: "Common",
        settings: "Settings",
        inputs: "Inputs",
        outputs: "Outputs",
        eventFilters: "Events",
      },
      edit: 'Edit widget',
      remove: 'Remove widget?',
      eventSettings: {
        addEventFilter: "Add event filter",
        editEventFilter: "Edit event filter",
        allEventFilter: "Listen all events",
        defaultFilters: "Default filters",
        customFilters: "Custom filters",
        name: "Name",
        dialogs: {
          deleteFilter: {
            text: "This action cannot be cancelled.",
            title: "Remove filter?",
          },
        },
      },
    },
    inputs: {
      name: "Inputs",
      notSelected: "Not selected",
      useEvents: "Use events as source"
    },
    outputs: 'Outputs',
    selectIcon: 'Select icon'
  },

  main: {
    noDesktops: 'No desktops available',
    noWidgets: 'Widgets not configured'
  },

  pageNotFound: 'Page not found',
  errorPage: 'ПроAn error has occurred. Contact your administrator.',
}
