import Plugin from "../../plugin";
import UserStorage from './user-storage';

class UserStoragePlugin extends Plugin {

    static install(Vue) {
        Vue.prototype.$userStorage = new UserStorage();
    }

    constructor() {
        super();
    }
}

export default UserStoragePlugin;
