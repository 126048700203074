const ReportConfiguratorTranslation = {
  header: "Конфигуратор отчетов",
  config: "Конфигурация",
  errorMessages: {
    isError: 'Пожалуйста, исправьте ошибку в конфигурации',
  },
  infoMessages: {
    usedDefaultConfiguration: 'Создание новой конфигурации, использован шаблон по умолчанию',
  },
}

export default ReportConfiguratorTranslation
