<template>
  <v-app class="app-main" style="background: no-repeat center/cover url('/api/v1/assets/login_background')">
    <v-dialog
        v-model="open"
        persistent
        width="440px"
        dense
        :hide-overlay="true"
    >
      <v-card elevation="0">
        <v-card-title class="headline d-flex justify-center">
          <v-card class="d-flex justify-center mt-4 mb-4" flat>
            <img :src="logoUrl" alt="logo" class="app-logo mr-4">
            <div class="app-login">
              {{ orgName }}
            </div>
          </v-card>
        </v-card-title>
        <v-card-text style="padding-bottom: 0">
          <v-container>
            <v-form>
              <v-text-field
                maxLength="64"
                :label="$t('login.user')"
                v-model.trim="user.login"
                type="text"
                id="login_text"
              />
              <v-text-field
                maxLength="64"
                :label="$t('login.password')"
                v-model.trim="user.password"
                :append-icon="iconPass"
                :type="passwordEditType"
                v-on:keyup.enter="login"
                @mousedown="showPassword"
                @mouseup="hidePassword"
                id="pass_text"
              />
            </v-form>
            <v-alert v-if="error" type="error" dense>Ошибка: {{ error }}</v-alert>
          </v-container>
        </v-card-text>
        <v-card-actions class="app-logo-container app-actions">
          <v-container class="d-flex justify-center" style="padding-bottom: 0">
            <v-btn
                id="btn_enter"
                color="primary"
                class="app-button"
                @click="login"
                width="126px"
            >
              <v-icon dense left>mdi-exit-to-app</v-icon>
              {{ $t("login.login") }}
            </v-btn>
          </v-container>
        </v-card-actions>
        <v-container style="padding-bottom: 24px">
          <v-card-text
              class="d-flex justify-center app-copyright"
              style="padding-bottom: 0"
              v-html="copyright"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "app-login",
  components: {
  },
  data() {
    return {
      open: true,
      user: {
        login: "",
        password: "",
      },
      passwordEditType: "password",
      iconPass: "mdi-eye-off",
      error: null,
    };
  },
  computed: {
    orgName() {
      return this.$context.platform?.platformName ? this.$context.platform?.platformName[this.$locale.current] : this.$t("app.name");
    },
    logoUrl() {
      return '/api/v1/assets/login_logo';
    },
    copyright() {
      return this.$t("app.copyright");
    },
    policy() {
      return this.$t("app.policy");
    },
  },
  created() {
    this.$vuetify.theme.themes.dark.primary = "#3CB17E";
    this.$vuetify.theme.themes.light.primary = "#3CB17E";
  },
  methods: {
    async login() {
      this.error = null;
      if (this.user.login.length === 0 || this.user.password.length === 0) {
        this.error = this.$t("login.empty");
      } else {
        try {
          const res = await this.$http.post("/api/v1/users/login", this.user);
          const error = res && res.data && res.data.error;
          if (error) {
            this.error = this.$te(error);
          } else {
            this.open = false;
            window.location.href = "/main/#/";
          }
        } catch (error) {
          this.error = this.$te(error);
        }
      }
    },
    changeLocale() {
      if (this.$locale.current === "en") {
        this.$locale.current = "ru";
      } else {
        this.$locale.current = "en";
      }
    },
    changeTheme() {
      if (this.$theme.current === "dark") {
        this.$theme.current = "light";
      } else {
        this.$theme.current = "dark";
      }
    },
    showPassword() {
      this.passwordEditType = "text";
      this.iconPass = "mdi-eye";
    },
    hidePassword() {
      this.passwordEditType = "password";
      this.iconPass = "mdi-eye-off";
    },
  },
};
</script>

<style lang="less" scoped>
.app-login {
  font-weight: 400;
  font-size: x-large;
  font-family: "Roboto";
  color: #3cb17e;
  display: flex;
  align-content: center;
  align-items: center;
  //  text-align: center;
}

.app-text {
  font-weight: 300;
  font-size: 36pt;
  font-family: "Roboto";
  text-align: center;
}

.app-logo {
  fill: #3cb17e;
  height: 72px;
  width: 72px;
}

.app-logo-container {
  padding-bottom: 0;
  padding-top: 0;
}

.app-policy {
  text-decoration: underline;
  color: #666;
  font-family: "Roboto";
  font-size: 9pt;
}

.app-copyright {
  color: #333;
  font-family: "Roboto";
  margin-bottom: 0;
  margin-top: 0;
}

.app-logo-icon-bottom {
  filter: grayscale(100%) brightness(110%) opacity(40%);
  height: 48px;
}

.app-button {
  font-family: "Roboto" !important;
  font-size: small;
}

.app-actions {
  padding-left: 24px;
  padding-right: 24px;
}
</style>
