export default {
  'wp-order-list': {
    name: 'Order List',
    settings: {
      configEntitiesFields: "Entities' fields settings (creation/show)",
      configVehicle: {
         fieldsToShow: {
           brand: "Brand",
           photo: "Photos",
           category: "Vehicle type",
           color: "Color",
         },
      },
      configPerson: {
        fieldsToShow: {
          photo: "Photos",
          birthday: "Birthday",
          categoryLaw: "Category law",
          identityDocument: "Identity document",
          phone: "Phone number",
          email: "Email",
          birthPlace: "Birth place",
        },
      },
      configOrderForm: {
        showOrder: "Show the selected order type",
        selectedOrder: "Available organizations for ordering",
        titlePeriodLimitSettings: 'Limiting the validity of the pass from the current date of order',
        timeDimension: {
          day: 'Day',
          month: 'Month',
          year: 'Year',
        },
        processingPersonalData: 'Consent to process personal data is required',
        fieldsToShow: {
          purpose: "Purpose",
        },
      },
      headers: {
        person: "Person data",
        vehicle: "Vehicle data",
        order: "Order data",
        visibility: "Visibility",
        required: "Required"
      },
      orderType: "Order type",
    },
    inputs: {
      refreshList: "Refresh order list",
    },
    outputs: {
      selectedOrder: 'Selected order'
    },
    filters: {
      statuses: 'Statuses',
      orderTypes: 'Order types',
      organizations: 'Organizations',
      checkpoints: 'Access',
      dateCreation: 'Order creation date',
      dateFormat: 'MM/DD/YYYY',
      datePassValid: 'Period of validity of pass',

      quickFilters: {
        createdInToday: "In today",
        createdInAWeek: "In a week",
        createdInAMonth: "In a month",
        expiredOrders: "Expired",
        inProcessOrders: "Under approval",
      },
    },
    labels: {
      filter: "Filter",
      name: 'List of orders',
      requester: 'Requester: ',
      dateCreation: 'Order creation date ',
      dateFrom: 'Order active: from ',
      dateTo: 'to ',
      organization: 'Organization: ',
      number: "Number",
      statuses: 'Statuses',
      orderTypes: "Order types",
      contracts: 'Contracts',
      organizations: 'Organizations',
      checkpoints: 'Access',
      isCurrent: 'Current order',
      placeholderSearchBar: 'Search by vehicle or person',
      createOrderBtn: 'Create order',
      createOrderGuestsBtn:'Create visitor order',
      hasNotMatching: 'Order has not stage of checking. Please, check algorithms',
      orderInUndefinedState: 'Order is in undefined state. Please, check algorithms',
      noTime: 'Invalid time. Please, check algorithms',
      hasNoPersonName: 'The person has no name',
      hasNoVehicleName: 'The vehicle has no name',
      lastUpdate: 'Last update: ',
      checkPoints: 'Checkpoints',
      filterBtn: 'Filter',
      resetFilterBtn: 'Reset',
      filters: {
        from: 'from',
        to: 'to',
      },
      btns: {
        recreate: "Recreate",
        cancel: "Cancel",
      },
      titles: {
        recreate: "Recreate order",
        selectOrderType: "Select order type",
      },
      vehicleOrdersHeader: 'Vehicles',
      personOrdersHeader: 'Persons',
      newDateTimePass: "Add a new period of validity of pass",
      checkSubOrders: "Check order containing",
      shouldStayInNewOrder: "Stay in new order",
    },
    table: {
      headers: {
        number: {
          text: "Number",
          sortable: true,
        },
        objects: {
          text: "Items",
          sortable: false,
        },
        requester: {
          text: "Requester",
          sortable: false,
        },
        organization: {
          text: "Organization",
          sortable: false,
        },
        waitAction: {
          text: "Waiting action",
          sortable: false,
        },
        status: {
          text: "Status",
          sortable: false,
        },
        lastStage: {
          text: "Last update",
          sortable: false,
        },
        creationDate: {
          text: "Creation date",
          sortable: true,
        },
        orderType: {
          text: "Order type",
          sortable: false,
        },
      }
    },
    orderView: {
      stageName: "Stage",
      startTime: "Start time",
      endTime: "End time",
      visedBy: "Vised by",
    },
    errorMessages: {
      required: 'Field is required',
      numeric: 'Field is only numeric',
      orderCannotBeEmpty: 'The order can not be empty. Please add a vehicle or a person',
      missingOrderId: "Missing order identification",
      missingUserOrg: "Missing user organization",
      failGetOrder: "Fail to get order",
      cantCreateOrderDiffOrg: "Can't recreate order from another organization",
      corruptedOrder: "There are corrupted data in order",
      recreate: {
        emptyOrder: "Can't recreate order: order {0} is empty",
        corruptedSubOrders: "Can't recreate order: there are corrupted data in order {0} about",
        corruptedVehicles: "vehicles",
        corruptedPersons: "persons",
      },
      failCreateVehicle: "Fail to create vehicle",
      failCreatePerson: "Fail to create person",
      failLoadOrg: "Fail to load organizations",
      failLoadPerson: "Fail to load persons",
      failLoadVehicle: "Fail to load vehicles",
    },
    timeFormat: 'MM/DD/YYYY HH:mm',
    dateFormat: 'MM/DD/YYYY',
  },
}
