const RolesTranslation = {
  header: "Роли",
  table: {
    headers: {
      id: {
        text: "ID",
        sortable: true,
      },
      name: {
        text: "Название",
        sortable: true,
      },
      admin: {
        text: "Администратор",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    }
  },
  columns: {
    id: "ID",
    name: "Название",
    admin: "Администратор",
  },
  admin: "Администратор",
  roleGroup: "Группа",
  add: "Добавление роли",
  edit: "Редактирование роли",
  remove: "Удалить роль?"
}

export default RolesTranslation
