class User {
  constructor(data) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get isAuth() {
    return !!this.id && !!this.enabled;
  }

  get isAdmin() {
    return !!this.ref_roles && this.ref_roles.some((r) => r.admin);
  }

  get isSuperAdmin() {
    return this.isAdmin && this.ref_organization?.id === 'Organization:0';
  }
}

export default User;
