import axios from "axios";
import {stringify} from "querystring";
import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import DataAccess from "./DataAccess";
import Vue from "vue";

class HttpEvaPlugin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.http = axios;
  }

  init() {
    this.app.$dataAccess = new DataAccess();
    Vue.prototype.$dataAccess = this.app.$dataAccess;
  }

  async getItem(url) {
    let res = await this.http.get(url);
    return this.getResult(res);
  }

  async getList(url, query) {
    if (query) {
      url = `${url}?${stringify(query)}`;
    }
    let res = await this.http.get(url);
    return this.getResult(res);
  }

  async post(url, data) {
    let res = await this.http.post(url, data);
    return this.getResult(res);
  }

  getResult(res) {
    return res && res.data;
  }
}

export default HttpEvaPlugin;
