const LogoSettingsTranslations = {
  header: "Platform name and logo",
  labels: {
    title: "Add settings",
    platformName: {
      ru: "Platform name (ru)",
      en: "Platform name (en)",
    },
    logoUrl: "Url logo",
    faviconUrl: 'Favicon',
    loginBackgroundUrl: "Login background"
  },
  errorMessages: {
    loading: "Settings does not load",
    updating: "Settings does not load",
  },
  infoMessages: {
    updateSuccess: "Settings are successfully saved",
  },
}

export default LogoSettingsTranslations
