import ContractCardTranslation from './ru/contract-card'
import OrgCardTranslation from './ru/org-card'
import PersonCardTranslation from './ru/person-card'
import FileCardTranslation from './ru/file-card'
import PermitPersonCardTranslation from './ru/permit-person-card'
import PermitVehicleCardTranslation from './ru/permit-vehicle-card'
import VehicleCardTranslation from './ru/vehicle-card'
import CheckpointTranslation from './ru/checkpoint'
import ControllerBoardTranslation from './ru/controller-board'
import EventDetailTranslation from './ru/event-detail'
import EventLogTranslation from './ru/event-log'
import VideoStreamTranslation from './ru/video-stream'
import PersonListTranslation from './ru/person-list'
import VehicleListTranslation from './ru/vehicle-list'
import VerificationPanelTranslation from './ru/verification-panel'
import CommonTranslation from './ru/common'
import MyOrgTranslation from './ru/my-org'
import UserProfileTranslation from './ru/user-profile'
import ContentFrameTranslation from "./ru/content-frame";
import PassageAccessQueueTranslation from './ru/passage-access-queue'
import ReportsTranslation from './ru/reports'
import AnalitycsTranslation from './ru/analitycs'
import CardCardTranslation from './ru/card-card'
import CheckPointCardTranslation from './ru/check-point-card'
import LevelPermitCardTranslation from './ru/level-permit-card'
import PassOfficeTranslation from './ru/pass-office'

import ComponentsLocales from '../components/locales/ru'
import ErrorMessagesLocale from "./ru/error-messages";

export default Object.assign({},
  ContractCardTranslation,
  OrgCardTranslation,
  PersonCardTranslation,
  PermitPersonCardTranslation,
  PermitVehicleCardTranslation,
  VehicleCardTranslation,
  CheckpointTranslation,
  ControllerBoardTranslation,
  EventDetailTranslation,
  EventLogTranslation,
  VideoStreamTranslation,
  PersonListTranslation,
  VehicleListTranslation,
  VerificationPanelTranslation,
  CommonTranslation,
  MyOrgTranslation,
  UserProfileTranslation,
  ComponentsLocales,
  ContentFrameTranslation,
  PassageAccessQueueTranslation,
  ReportsTranslation,
  AnalitycsTranslation,
  CardCardTranslation,
  CheckPointCardTranslation,
  LevelPermitCardTranslation,
  ErrorMessagesLocale,
  PassOfficeTranslation,
  FileCardTranslation,
)
