const DesktopTranslations = {
  header: "Рабочие столы",
  newWidget: "Новый виджет ",
  newDesktop: "Новый рабочий стол",
  saved: "Изменения сохранены",
  exit: "Все несохраненные изменения будут потеряны",
  saveError: "Ошибка при сохранении изменений",
  unknownWindgetType: "Неизвестный тип виджета",
  searchPanel: {
    search: "Поиск"
  },
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      ref_desktop_group: {
        text: "Группа",
        sortable: false,
      },
      sort_order: {
        text: "Порядок сортировки",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Удалить рабочий стол",
    }
  },
  columns: {
    name: "Наименование",
    column_count: "Количество колонок",
    row_count: "Количество строк",
    group: "Группа",
    sort_order: "Порядок сортировки",
    roles: "Роли",
  },
  rows: {
    row_count: "Количество строк",
    group: "Группа",
  },
  buttons: {
    save: "Сохранить",
    exit: "Выйти",
  },
  errors: {
    required: "Требуется значение",
    integerRequired: "Требуется целочисленное значение",
    valueInRange: "Значение должно быть в интервале (1, 200)",
    lengthMaximum: "Значение не может превышать длину в 50 символов"
  },
  add: "Добавление рабочего стола",
  edit: "Редактирование рабочего стола",
  remove: "Удалить рабочий стол?",
}

export default DesktopTranslations
