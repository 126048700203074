const CatalogTranslations = {
  header: "Справочники",
  remove: "Удалить элемент?",
  add: "Добавить элемент",
  create: "Создание",
  edit: "Редактирование",
  save: "Сохранить",
  cancel: "Отмена",
  searchPanel: {
    search: "Поиск"
  },
  columns: {
    id: "ID",
    name: 'Название',
    nameRu: "Название [ru]",
    nameEn: "Название [en]",
    params: "Параметры",
    sys_name: "Системное имя",
    description: "Описание"
  },
  labels: {
    name: "Название каталога",
  },
  buttons: {
    save: "Сохранить",
    cancel: "Отменить",
    exit: "Выйти",
    back: "Назад",
  },
  params: {
    name: "Статус",
    icon: "Иконка",
    color: "Цвет",
  },
  mainTable: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      id: {
        text: "ID",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Редактировать каталог",
      remove: "Удалить каталог",
    }
  },
  table: {
    headers: {
      name: {
        text: "Название",
        sortable: true,
      },
      params: {
        text: "Параметры",
        sortable: false,
      },
      sys_name: {
        text: "Системное имя",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      edit: "Редактировать элемент",
      remove: "Удалить элемент",
    }
  },
  errors: {
    required: "Обязательное поле",
    alpha: "Значение должно состоять только из латинских символов",
    uniqueId: "Тип каталога с таким ID уже существует",
    uniqueName: "Тип каталога с таким именем уже существует",
  },
}

export default CatalogTranslations
