<template>
  <v-dialog v-model="dialog" @keydown.esc="cancel" @click:outside="cancel" max-width="600px">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ $t(title) }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4">
        <slot/>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('common.dialog.ok') }}</v-btn>
        <v-btn color="grey" text @click.native="cancel">{{ $t('common.dialog.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'app-dialog',

  props: {
    closeAfterAgree: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      title: '',

      actions: null,
    };
  },

  methods: {
    show(title, actions) {
      this.title = title;
      this.dialog = true;
      if (actions) {
        this.actions = actions
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    close() {
      this.dialog = false;
    },
    async agree() {
      let validate = this.actions && this.actions.find((a) => a.name === 'validate');
      try {
        let result
        if (validate) {
          result = await validate.handler()
        } else {
          result = true
        }
        // let result = validate && await validate.handler() || true;
        this.dialog = !result;
        if (result) {
          this.resolve(result);
          if (this.closeAfterAgree) {
            this.close();
          }
        }

      } catch (error) {
        this.error = error;
      }
    },
    cancel() {
      this.resolve(false);
      this.close();
    }
  }
}
</script>

<style scoped>

</style>
