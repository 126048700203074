import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import moment from "moment";

class ToolsEvaPlugin extends EvaPlugin {

  constructor(app) {
    super(app);
  }

  resolveValue(value) {
    if (value == null) {
      return value;
    }
    if (typeof value === 'function') {
      value = value();
    }
    return value;
  }

  handleKeys(obj, condition, action = null) {
    if (action == null) {
      action = condition;
      condition = null;
    }
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = obj[key];
      if (!condition || condition(value)) {
        action(value, key);
      }
    }
  }

  async handleKeysAsync(obj, condition, action = null) {
    if (action == null) {
      action = condition;
      condition = null;
    }
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = obj[key];
      if (!condition || condition(value)) {
        await action(value, key);
      }
    }
  }

  formatDate(ts) {
    return ts && moment(new Date(parseInt(ts))).format('DD.MM.YYYY HH:mm') || '';
  }

  navigate(url) {
    location.href = url;
  }

  async wait(count) {
    return new Promise(res => setTimeout(res, count));
  }

  debounce(callback, limit = 300) {
    let timeout = null;
    let state = null;

    function wrapper() {
      if (state) {
        state.cancel = true;
        state = null;
      }
      clear();
      timeout = setTimeout(() => invoke(), limit);
      return wrapper;
    }

    function invoke() {
      timeout = null;
      state = {
        cancel: false
      }
      callback(state);
    }

    function clear() {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
        return true;
      }
    }

    function flush() {
      clear() && invoke();
    }

    wrapper.clear = clear;
    wrapper.flush = flush;

    return wrapper;
  }
}

export default ToolsEvaPlugin;
