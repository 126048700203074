<template>
  <div class="eva-tree">
    <div
        v-for="(node, index) in nodes"
        :key="index"
        class="eva-tree__node"
    >
      {{ node.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'eva-tree',

  props: {
    loadNodes : {
      type: Function
    }
  },

  data() {
    return {
      nodes   : [],
      loading : false
    }
  },

  watch: {
    loadNodes: {
      async handler() {
        await this.reload();
      },
      immediate: true
    }
  },

  methods: {
    async reload() {
      this.nodes = [];

      if (!this.loadNodes) {
        return;
      }

      try {
        this.loading = true;
        this.nodes = await this.loadNodes();
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="less">
  .eva-tree {

  }
</style>