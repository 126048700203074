import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-tree"},[_c('div',{staticClass:"app-tree__header"},[_c(VToolbar,{attrs:{"dense":""}},[_c('h5',[_vm._v(_vm._s(_vm.header))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),(_vm.search)?_c(VTextField,{staticClass:"v-btn--search",attrs:{"outlined":"","dense":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"app-table__actions"},[_vm._t("actions")],2)],1)],1),_vm._v(" "),_c('div',{staticClass:"app-tree__main mt-1"},[_c('div',{staticClass:"app-tree__tree"},[_c(VCard,{staticClass:"app-tree__tree-inner"},[(!_vm.itemsLoading)?_c(VTreeview,{attrs:{"active":_vm.active,"items":_vm.items,"open":_vm.open,"load-children":_vm.getLoadChildren(),"selectable":_vm.selectable,"selection-type":"independent","activatable":"","dense":"","search":_vm.searchText},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(item.icon_id)?_c('div',{staticClass:"v-image-container"},[_c('img',{attrs:{"src":`./api/v1/images/${item.icon_id}`}})]):_c(VIcon,[_vm._v(_vm._s(item.icon))])]}},{key:"append",fn:function({ item }){return [_c(VIcon,{attrs:{"color":item.append_color}},[_vm._v(_vm._s(item.append_icon))])]}}],null,false,2946757799),model:{value:(_vm.groupSelection),callback:function ($$v) {_vm.groupSelection=$$v},expression:"groupSelection"}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"app-tree__content"},[_c('div',{staticClass:"app-tree__content-inner"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }