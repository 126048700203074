export default {
  "wp-analitycs": {
    name: "Analitycs",
    components: {
      orders: {
        total: 'Total orders',
        vehicles: 'Vehicles',
        persons: 'Persons',
        byStatus: 'By status'
      },
      permits: {
        day: 'Day',
        time: 'Time',
        week: 'Week',
        dayOfWeek: 'Day of week',
        month: 'Month',
        date: 'Date',
        year: 'Year',
        barrier: 'Barrier',
        buildReport: "Build report",
        dates: {
          startTime: 'Start time',
          endTime: 'End time',
        },
        preferences: {
          dateFormat: "MM.DD.YYYY",
        },

        total: 'Total permits',
        vehicles: 'Vehicle permits',
        persons: 'Person permits',
        byStatus: 'Permit statuses'
      },
      accesses: {
        day: 'Day',
        time: 'Time',
        week: 'Week',
        dayOfWeek: 'Day of week',
        month: 'Month',
        date: 'Date',
        year: 'Year',
        barrier: 'Barrier',
        buildReport: "Build report",
        dates: {
          startTime: 'Start time',
          endTime: 'End time',
        },
        preferences: {
          dateFormat: "MM.DD.YYYY",
        },
      },
    }
  },
  "wp-analitycs-config": {
    name: "Configuration",
    header: "Name",
    type: "Type",
    types: {
      "ordersSummary": "Order (summary)",
      "permitsSummary": "Permits (summary)",
      "permitsTimeline": "Permits (timeline)",
      "accessTimeline": "Access (timeline)",
      "personAccessTimeline": "Passages (timeline)",
    },
  },
};
