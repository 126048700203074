export default {
  locale: {
    code: "ru",
    name: "Русский"
  },

  panel: {
    needCloseConfirm: "Все несохраненные изменения будут потеряны."
  },

  confirmDialog: {
    defaultTitle: 'Подтвердите действие'
  },

  app: {
    name: "Рублево-Архангельское",
    copyright: '&copy; ' +new Date().getFullYear()+'. Все права защищены.',
    policy:
        "Политика обработки персональных данных и правила пользования порталом"
  },

  theme: {
    dark: "Темная",
    light: "Светлая"
  },

  mainMenu: {
    myOrg: 'Моя организация',
    desktops: "Рабочие столы",
    admin: "Администрирование"
  },

  loading: "Загрузка...",
  logged: "Вход выполнен",
  notlogged: "Вход не выполнен",
  adminPanel: "Администратор",
  workPanel: "Рабочие столы",
  common: {
    table: {
      noDataText: "Нет данных",
      itemsPerPage: 'Записей на странице'
    },
    dialog: {
      ok: "OK",
      cancel: "Отмена"
    },
    loading: "Загрузка...",
    logged: "Вход выполнен",
    notlogged: "Вход не выполнен",
    adminPanel: "Администратор",
    workPanel: "Рабочие столы",
    filtersTitle: "",
    desktop: {
      icon: "Иконка:",
      selectIcon: "Выбрать"
    },
    version: "Версия",
    btn: {
      save: "Сохранить",
      close: 'Закрыть',
      reset: "Сбросить",
    },
    dates: {
      from: "С",
      to: "По",
      dateFormat: "DD.MM.YYYY",
      dateTimeFormat: "HH:mm, DD.MM.YYYY",
      dateTimeWithSecFormat: "HH:mm:ss, DD.MM.YYYY",
    },
    components: {
      searchPanel: {
        search: "Поиск"
      },
    },
    titles: {
      filters: "Фильтры",
    },
    eventConfig: {
      eventTypes: {
        eventCatalogTypes: 'Типы событий',
        eventCatalogs: 'События',
      },
      directObjs: "Источники события",
      subjects: "Субъекты",
    }
  },

  roles: {
    admin: "Администратор",
    user: "Пользователь"
  },

  admin: {
  },
  users: {
    header: "Пользователи",
    columns: {
      email: "Электронная почта",
      name: "Имя пользователя",
      roles: "Роли",
      disabled: "Заблокирован",
      admin: "Администратор",
      password: "Пароль"
    },
    add: "Добавление пользователя",
    edit: "Редактирование пользователя",
    remove: "Удалить пользователя ?"
  },
  desktops: {
    header: "Рабочие столы",
    columns: {
      name: "Название"
    },
    add: "Добавление рабочего стола",
    edit: "Редактирование рабочего стола",
    remove: "Удалить рабочий стол ?"
  },
  widgets: {
    columns: {
      name: "Название"
    },
    edit: "Редактирование виджета",
    remove: "Удалить виджет ?"
  },
  inputs: "Входные параметры",
  outputs: "Выходные параметры",
  main: {
    noDesktops: "Нет доступных рабочих столов",
    noWidgets: "Виджеты не настроены"
  },
  widgetInputs: {
    notSelected: 'Не выбрано',
    serverSourceObject: 'Серверный источник (для экземпляра)',
    serverSourceType: 'Серверный источник (для типа)'
  },
  pageNotFound: 'Страница не найдена',
  errorPage: 'Произошла ошибка. Обратитесь к администратору.',
};
