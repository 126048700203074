const LoginTranslation = {
  login: {
    header: "Вход в систему",
    user: "Имя пользователя",
    password: "Пароль",
    login: "Войти",
    logout: "Выйти",
    register: "Не могу войти",
    empty: "Укажите логин/пароль"
  },
}
export default LoginTranslation
