export default {
  'wp-person-list': {
    name: 'Управление списками персон',
    labels: {
      name: 'ФИО',
      birthDate: 'Дата рождения',
      organization: 'Организация',
      drawerLabel: 'Частное лицо',
      isCurrent: 'Текущее ЧЛ',
      placeholderSearchBar: 'Искать по ФИО или организации',
    },
  },
}
