<template>
    <v-card class="f-image-edit" @click="selectFile">
        <v-img
            :src="model && model[imageField] && model[imageField].url"
            :max-width="maxWidth"
            :max-height="maxHeight"
            contain
            class="f-image-edit__image"
        />
        <div class="f-image-edit__bottom">
            <span class="f-image-edit__caption">{{ title }}</span>
            <v-icon v-if="isEnableDownload" class="f-image-edit__button" @click.stop="downloadFile">mdi-download</v-icon>
            <v-icon v-if="isEnableDownload" class="f-image-edit__button" @click.stop="removeFile">mdi-delete</v-icon>
        </div>
        <input class="f-image-edit__file" type="file" ref="uploader" @change="fileSelected" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|images/*">
    </v-card>
</template>

<script>
import { v4 } from 'uuid';
export default {
    name: 'app-image-edit',

    props: {
        model : {
            type: Object,
            default() {
                return {
                    image : {}
                }
            }
        },
        imageField : {
            type : String,
            default : 'image'
        },
        maxWidth : {
            type : String
        },
        maxHeight : {
            type : String
        },
        url: {
            type : String
        },
        titleSource: {
            type: String
        },
        confirmRemove : {
            type: String
        }
    },

    computed: {
        title() {
            if (this.titleSource) {
                return this.$t(this.titleSource);
            }
            return this.$t('common.images.clickToChange');
        },
        isEnableDownload() {
            let url = this.model && this.model[this.imageField] && this.model[this.imageField].url || '';
            if (!url) {
                return false;
            }
            return url.toLowerCase().startsWith('/api/');
        }
    },

    methods: {
        selectFile() {
            this.$refs.uploader.click();
        },

        async fileSelected($event) {
            let files = $event.target.files || $event.dataTransfer.files;
            let file = files && files[0];
            if (!file) {
                return;
            }

            let itemData = new FormData();
            itemData.append(this.imageField, file, file.name);

            let res = await this.$http.put(`/api/v1/images/${v4()}`, itemData);

            this.$emit('reload');//??


        },

        downloadFile() {
            if (!this.isEnableDownload) {
                return;
            }
            let win = window.open(this.model[this.imageField].url, '_blank');
            win.focus();
        },

        async removeFile() {
            if (!this.isEnableDownload) {
                return;
            }

            if (this.confirmRemove && !(await this.$dialog.$confirmAsync(this.$t(this.confirmRemove)))) {
                return;
            }

            await this.$http.delete(`${this.url}/${this.model.id}/${this.imageField}`);

            this.$emit('reload');
        },
    }
}
</script>

<style lang="less">
.f-image-edit {
    cursor: pointer;
    .f-image-edit__image {
        margin: auto;
    }
    .f-image-edit__bottom {
        display: flex;
        .f-image-edit__caption {
            display: flex;
            margin: 10px auto 0 0;
        }
        .f-image-edit__button {
            display: flex;
            margin: 10px 0 0 5px;
        }
    }
    .f-image-edit__file {
        display: none;
    }
}
</style>