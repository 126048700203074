const RoleGroupsTranslation = {
  header: "Role groups",
  add: "Add group",
  edit: "Edit group",
  remove: "Remove group?",
  errorMessages: {
    groupHasRoles: "Can't delete group with roles",
    deleteError: "Can't delete role group",
    loadError: "Can't load role group",
  },
  deleteGroupDialog: {
    text: "This action can not be cancelled",
    title: "Delete group?",
  },
}

export default RoleGroupsTranslation
