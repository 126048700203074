import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{staticClass:"eva-list",attrs:{"dark":_vm.dark}},[_vm._l((_vm.items),function(item,index){return [(item.divider)?_c(VDivider,{key:index}):(item.items)?_c(VListGroup,{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,[_vm._v("\n            "+_vm._s(item.icon)+"\n          ")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n            "+_vm._s(_vm.$eva.$t(item[_vm.nameField]))+"\n          ")])],1)]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((item.items),function(child){return _c(VListItem,{key:child.to,attrs:{"link":"","to":child.to}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n            "+_vm._s(child.icon)+"\n          ")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$eva.$t(child[_vm.nameField]))}})],1)],1)})],2):_c(VListItem,{key:index,attrs:{"link":"","to":item.to},on:{"click":function($event){$event.stopPropagation();return _vm.runCommand(item)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("\n          "+_vm._s(item.icon)+"\n        ")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n          "+_vm._s(_vm.$eva.$t(item[_vm.nameField]))+"\n        ")])],1)],1)]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }