import Vue from "vue";
import DataAccessAdapter from './DataAccessAdapter';

class DataAccess {
  constructor() {
    this.$http = Vue.prototype.$http;
    this.$locale = Vue.prototype.$locale;
    this.$serverRequest = Vue.prototype.$serverRequest
    this._adapters = [];
  }

  registerAdapter(name, url, methods = {},DataAccessAdapterConstructor = null) {
    const DaClass = DataAccessAdapterConstructor || DataAccessAdapter;
    let adapter = new DaClass(this, url, methods);
    this._adapters.push(adapter);
    this[name] = adapter;
  }

  getResult(res) {
    let data = res && res.data;
    if (!data) {
      return null;
    }

    if (data.error) {
      console.error(data.error);
      throw this.$locale.getErrorMessage(data.error);
    }

    return data;
  }
}

export default DataAccess;
