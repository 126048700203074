export default {
  'error-messages': {
    required: "Требуется ввести значение",
    minLength: "Значение должно быть не менее {0} символов",
    maxLength: "Значение должно занимать не более {0} символов",
    numeric: 'Значением может быть только число',
    certainFormatFile: "Неверный формат файла. Допустимо: {0}",
    alpha: 'Имя должно состоять только из латинских символов',
    cyrillic: 'Имя должно состоять только из кириллических символов',
    alphaAndNumber:'Имя должно состоять только из латинских символов и/или цифр',
    uniqueId: "Тип каталога с таким id уже существует",
    uniqueName: "Тип каталога с таким именем уже существует",
    certainSymbols: "Можно вводить в верхнем и нижнем регистре: A-z (еn), АВЕКМНОРСТУХ (ru) и цифры",
    validJson: "Не валидная структура JSON",
  }
}
