import moment from "moment";

// todo: make all func chainable (return numbers), write tests
function setDateToStart (dateTime, format) {
  const newDateTime = moment.utc(dateTime, format)
  newDateTime.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  return newDateTime
}

function setDateToEnd (dateTime, format) {
  const newDateTime = moment.utc(dateTime, format)
  newDateTime.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  })
  return newDateTime
}

function setValueDateToStart (dateTime, format) {
  const newDateTime = setDateToStart(dateTime, format)
  return newDateTime.valueOf()
}

function setValueDateToEnd (dateTime, format) {
  const newDateTime = setDateToEnd(dateTime, format)
  return newDateTime.valueOf()
}

export { setDateToStart, setDateToEnd, setValueDateToStart, setValueDateToEnd }
