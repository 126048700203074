const ReportConfiguratorTranslation = {
  header: "Report configurator",
  config: "Configuration",
  errorMessages: {
    isError: 'Please, fix an error in configuration',
  },
  infoMessages: {
    usedDefaultConfiguration: "It's creation of new config, default template is used",
  },
}

export default ReportConfiguratorTranslation
