export default {
    "wp-task-list-action-buttons": {
      name: "Кнопки действий",
      actionButtons: {
        accept: "В работу",
        complete: "Завершить",
        decline: "Отклонить",
        pause: "Приостановить"
      }
    }
  };