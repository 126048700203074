class Files {

  async downloadByUrl(url) {
    try {
      const link    = document.createElement('a');
      link.download = item.name;
      link.target   ="_blank";
      link.href     = url;
      link.setAttribute('download', item.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
    }
  }
}

export default Files;
