<template>
    <v-dialog v-model="dialog" @keydown.esc="cancel" :max-width="width" :scrollable="scrollable" :content-class="classes">
        <v-card class="app-dialog__card">
            <v-toolbar dark color="primary" dense flat style="flex-grow: 0">
                <v-toolbar-title class="white--text">{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pa-4 pb-0" style="flex-grow: 1; overflow-y: auto">
                <slot/>
            </v-card-text>

            <v-card-text class="pa-4 py-0" v-if="error">
                <v-alert type="error">
                    {{ error }}
                </v-alert>
            </v-card-text>

            <v-card-actions class="pt-0" style="flex-grow: 0">
                <v-btn v-for="action in customActions" :key="action.name" :color="action.color" text @click.native="runAction(action)">
                    {{ action.text }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click.native="agree">{{ $t('common.dialog.ok') }}</v-btn>
                <v-btn color="grey" text @click.native="cancel">{{ $t('common.dialog.cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'app-dialog',

    props : {
        width : {
            type    : String,
            default : '600px'
        },
        height : {
            type    : String,
            default : 'auto'
        },
        scrollable : {
            type    : Boolean,
            default : false
        }
    },

    data() {
        return {
            dialog  : false,
            resolve : null,
            reject  : null,
            title   : '',
            error   : null,
            actions : []
        };
    },

    computed : {
        classes() {
            let result = 'app-dialog';
            if (this.height === '100%') {
                result += ' app-dialog--full_height';
            }
            return result;
        },
        customActions() {
            return this.actions && this.actions.filter((a) => a.name !== 'validate') || [];
        }
    },

    methods: {
        show(title, actions = null) {
            this.title   = title;
            this.error   = null;
            this.dialog  = true;
            this.actions = actions;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            })
        },
        async runAction(action) {
            this.error = null;
            try {
                let result = await action.handler();
                this.dialog = !result;
                this.resolve(result);
            } catch (error) {
                this.error = error;
            }
        },
        async agree() {
            this.error   = null;
            let validate = this.actions && this.actions.find((a) => a.name === 'validate');
            try {
                let result = validate && await validate.handler() || true;
                this.dialog = !result;
                this.resolve(result);
            } catch (error) {
                this.error = error;
            }
        },
        async cancel() {
            this.resolve(false);
            this.dialog = false;
        }
    }
}
</script>

<style lang="less">
    .app-dialog {
        &.app-dialog--full_height {
            height: 100%
        }
        .app-dialog__card {
            display: flex;
            flex-direction: column;
            height: 100%
        }
    }
</style>
