export default {
  'wp-events-event-detail': {
    name: 'Подробности события',
    settings: {
      header: "Заголовок"
    },
    emptyEvent: 'Событие не выбрано',
    labels: {
      typeEvent: 'Тип',
      time: 'Время',
      description: 'Описание',
      object: 'Объект',
      subject: 'Субъект',
      location: 'Место события',
    },
    format: 'DD.MM.YYYY HH:mm:ss'
  }
}
