<template>
<pre class="eva-pre" :class="classes" :style="{ color: color }">
{{ text }}
</pre>
</template>

<script>
export default {
  name: 'eva-pre',

  props: {
    text: {
      type: String
    },
    bolder: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    }
  },

  computed: {
    classes() {
      return {
        'eva-pre--bolder': this.bolder,
        'eva-pre--bold': this.bold
      }
    }
  }
}
</script>

<style lang="less">
.eva-pre {
  align-self: center;
  &.eva-pre--bolder {
    font-weight: bolder;
  }
  &.eva-pre--bold {
    font-weight: bold;
  }
}
</style>
