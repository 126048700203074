export default {
  "wp-person-card": {
    name: "Карточка физлица",
    notSet: "Не задано",
    title: {
      new: "Новое физлицо",
      update: "Редактирование физлица",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      information: {
        topLabel: "Сведения",
        firstName: "Имя",
        lastName: "Фамилия",
        middleName: "Отчество (при наличии)",
        attachPhoto: "Прикрепите фото",
        birthDate: "Дата рождения",
        birthPlace: "Место рождения",
        organization: "Организация",
        user: "Учетная запись",
        categoryLaw: {
          Citizen: 'Гражданин РФ',
          ForeignCitizen: 'Иностранный гражданин',
          Statelessness: 'Без гражданства'
        },
        categoryLawLabel: "Категория гражданства",
        docType: {
          Passport: "Паспорт",
          ForeignPassport: "Загранпаспорт",
          DriverLicense: "Водительское удостоверение"
        }
      },
      contacts: {
        topLabel: "Контакты",
        telephone: "Телефон",
        email: "Электронная почта",
      },
      document: {
        topLabel: "Документ, удостоверяющий личность",
        typeDocument: "Тип документа",
        seriesDocument: "Серия",
        numberDocument: "Номер",
        issuedDocument: "Учреждение, выдавшее документ",
        dateDocument: "Дата выдачи",
      },
      chiefBus: "Старший автобуса",
      securityList: "Список доступа",
      workPermitDocs: 'Разрешение на работу',
    },
    preferences: {
      dateFormat: "DD.MM.YYYY",
    },
    validationRules: {
      defaults: {
        required: "Требуется ввести значение",
        maxLength: "Значение должно занимать не более {0} символов",
        correctNumber: "Некорректный номер",
        email: "Некорректный email",
      },
    },
  },
};
