export default {
  "wp-task-view": {
    name: "Task view",
    labels: {
      creator: "Creator",
      from: "Period of validity: from",
      to: "to",
      contractor: "Contractor",
      description: "Description",
      stages: "Stages",
      location: 'Location',
      expired: 'Expired',
      source: 'Object'
    },
    format: "YYYY-DD-MM HH:mm",
    components: {
      taskStage: {
        labels: {
          startDate: "Stage started",
          endDate: "Stage completed",
          status: "Status",
          comment: "Comment"
        },
        format: "YYYY.MM.DD HH:mm"
      },
      source: {
        vehicle: {
          brand: 'Brand',
          color: 'Color',
          model: 'Model',
          number: 'Number plate',
        }
      }
    }
  }
};
