export default {
  "wp-check-point-card": {
    name: "Check Point",
    notSet: "Not Set",
    title: {
      new: "New Check Point",
      update: "Edit Check Point",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Information",
        name: "Name",
      },
    },
  },
};
