export default {
  'wp-reports': {

    name: 'Reports',

    accessReportConfig: {
      readerInputs: "Input readers",
      readerOutputs: "Output readers",
      timeAccess: "Merge events by period",
      timeType: {
        min: "Minutes",
        hour: "Hours",
        day: "Days",
      },
    },

    'checkpoints-report' : {
      title : 'Checkpoint workload'
    },

    'access-person-report' : {
      title: 'Passages',
    },

    'orders-report' : {
      title : 'Applications for passes'
    },

    'permits-report' : {
      title : 'Permits'
    },

    filters: {
      objectTypeLabel: 'Object type',
      objectTypes: {
        Vehicle: 'Vehicle',
        Person: 'Person',
      },
      validatedObjectTypes: {
        ValidatedVehicle: 'Vehicle',
        ValidatedPerson: 'Person',
      },
      dateFormat: 'DD.MM.YYYY',
      statuses: 'Statuses',
      organizations: 'Organizations',
      checkpoints: 'Access',
      orderTypes: "Order types",
      dateCreation: "Order creation date",
      datePassValid: "Period of validity of pass",
      isInsidePeriod: "Validity only inside period",
      reportDate: 'Report date',
      startReportDate: 'Report date from',
      endReportDate: 'Report date to',
    },

    config: {
      isChanged: "Warning: configuration was changed",
      isUpdating: "Saving",
      isSaved: "Configuration successfully saved",
      configForReportBuild: "Configuration for report building",
      inBuildProcess: "Report building in process",
      btn: {
        rollback: "Rollback to last saved version",
      },
      confirmRollback: {
        title: "Confirm rollback?",
        text: "This action can not be cancelled",
      },
      confirmToCreate: {
        title: "Create new config?",
        text: "Changes will not be saved",
      },
      error: {
        notSaved: "Configuration saving failed",
        notLoaded: "Configuration loading failed",
        notParsed: "Configuration handling failed",
        notHaveConfigBuild: "Configuration of report building does not set",
      },
    },

    table: {
      orders: {
        headers: {
          date_valid_from: "Create date",
          number: "Number",
          object: 'Object',
          requester_organization: 'Organization',
          requester: "Requester",
          status_name: 'Status',
          start_pass_time: 'Access from',
          end_pass_time: 'Access to',
          checkpoints: 'Access zones',
          orderType: "Order type",
          orderReportActions: "Actions",
        },
        item: {
          format: 'DD.MM.YYYY'
        },
        pdf: {
          number: `Order №`,
          object: {
            header: `Object`,
          },
          organization: {
            header: `Organization`,
          },
          checkpoints: {
            header: `Access zones`,
          },
          access: {
            header: `Access period`,
            timeRange: `from {start} to {end}`,
          },
        },
      },
      permits: {
        headers: {
          object: 'Object',
          object_organization: 'Object Organization',
          requester: 'Requester',
          requester_organization: 'Requester Organization',
          date_valid_from: 'Date valid from',
          status_name: 'Status',
          start_pass_time: 'Access from',
          end_pass_time: 'Access to',
          checkpoints: 'Access zones'
        },
        item: {
          format: 'DD.MM.YYYY'
        }
      },
      checkpoints: {
        headers: {
          subject: 'Object',
          time_entry: 'Entry time',
          type_entry: 'Entry access',
          reader_entry: 'Entry',
          time_departure: 'Departure time',
          type_departure: 'Departure access',
          reader_departure: 'Departure',
        },
        item: {
          format: 'h:mm'
        }
      },
      personAccess: {
        headers: {
          subject: 'Full name',
          organization: 'Organization',
          time_entry: 'Entry time',
          type_entry: 'Entry access',
          reader_entry: 'Entry zone',
          time_departure: 'Departure time',
          type_departure: 'Departure access',
          reader_departure: 'Departure zone',
        },
        item: {
          format: 'hh:mm'
        }
      }
    },
    total: 'Total',
    components: {
      searchPanel: {
        search: "Search"
      },
    },
  },
};
