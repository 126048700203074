<template>
  <div class="v-image-field" :style="style">
    <v-file-input
      v-model="file"
      prepend-icon=""
      accept="image/*"
      outlined
      dense
      :clearable="false"
    >

      <template v-slot:selection="{ file }">
        <div class="v-image-field__container">
          <img :src="createUrl(file)" class="v-image-field__img">
        </div>
      </template>

    </v-file-input>
  </div>
</template>

<script>
import {v4} from "uuid";

export default {
  name: 'v-image-field',

  props: {
    value: {},
    width: {},
    height: {}
  },

  data() {
    return {
      file : null
    };
  },

  computed: {
    style() {
      let result = {};
      if (this.width) {
        result.width = this.width;
      }
      if (this.height) {
        result.height = this.height;
      }
      return result;
    }
  },

  watch: {
    value: {
      handler(icon_id) {
        if (icon_id) {
          this.file = {
            icon_id
          }
        }
      },
      immediate: true
    },
    async file(value) {
      if (value && value.icon_id) {
        return;
      }
      let itemData = new FormData();
      itemData.append('image', value, value.name);
      let res = await this.$http.put(`/api/v1/images/${v4()}`, itemData);
      this.$emit('input', res.data.id)
    }
  },

  methods : {
    createUrl(file) {
      if (file instanceof File) {
        return URL.createObjectURL(file);
      } else {
        return `./api/v1/images/${file.icon_id}?ts=${new Date().getTime()}`;
      }
    }
  }
}
</script>

<style lang="less">
.v-image-field {
  display: flex;

  width: 40px;
  height: 40px;

  .v-file-input {
    width: 100%;
    height: 100%;
    .v-input__control {
      width: 100%;
      height: 100%;
      .v-input__slot {
        cursor: pointer;
        padding: 0!important;
        width: 100%;
        height: 100%;
        .v-text-field__slot {
          padding: 0!important;
        }
        fieldset {
          z-index: 1000;
        }
      }
    }
  }

  .v-image-field__container {
    display: flex;
    width: 100%;
    height: 100%;
    .v-image-field__img {
      display: flex;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>