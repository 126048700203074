const LogoSettingsTranslations = {
  header: "Название и логотип",
  labels: {
    title: "Укажите необходимые настройки",
    platformName: {
      ru: "Название платформы (ru)",
      en: "Название платформы (en)",
    },
    logoUrl: "Url логотипа",
    faviconUrl: 'Значок сайта',
    loginBackgroundUrl: "Фон страницы логина"
  },
  errorMessages: {
    loading: "Не удалось загрузить данные настроек",
    updating: "Не удалось сохранить настройки",
  },
  infoMessages: {
    updateSuccess: "Настройки успешно обновлены",
  },
}

export default LogoSettingsTranslations
