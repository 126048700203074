import SocketEvents from "./socket-events";
import WidgetEventFilter from "./widget-event-filter";
import { AllEventsFilter } from "./all-events-filters";

const isPromise = h => h && typeof h.then === "function"
const reservedSocketNames = [SocketEvents.CONNECT, SocketEvents.DISCONNECT, SocketEvents.REQUEST, SocketEvents.RESPONSE];
// todo: remove after switch on rooms completely
const ROOM_PREFIX = "room_"

class Rooms {

  init(socket, vue) {
    this._socket = socket
    this._vue = vue
    this._handlers = new Map();
    this._subscribeOnSocket()
    this._eventHandler = (event) => this.processEvent(event)
  }

  _subscribeOnSocket() {
    this._socket.onAny((eventName, data) => {
      if (reservedSocketNames.includes(eventName) || !eventName.includes(ROOM_PREFIX)) {
        return
      }
      this._processEvent(eventName, data)
    })
  }

  _processEvent(eventName, data) {
    if (Array.isArray(data)) {
      for (const event of data) {
        this.emit(eventName, event)
      }
    } else {
      this.emit(eventName, data)
    }
  }

  emit(eventName, event) {
    const roomEvent = {
      roomEventName: eventName,
      event
    }
    this._vue.$emit(eventName, roomEvent);
  }

  subscribeDesktop(desktop, cb) {
    const currentDesktopFilter = new WidgetEventFilter({
      eventCatalogTypes: [],
      eventCatalogs: [],
      directObjs: [{ id: desktop.id }],
      subjects: [],
      uuid: `Desktop-service_filter-${desktop.id}`,
    })

    this.subscribeWidget({
      filters: [currentDesktopFilter]
    }, cb);
  }
  unsubscribeDesktop(desktop, cb) {
    const currentDesktopFilter = new WidgetEventFilter({
      eventCatalogTypes: [],
      eventCatalogs: [],
      directObjs: [{ id: desktop.id }],
      subjects: [],
      uuid: `Desktop-service_filter-${desktop.id}`,
    })
    this.unsubscribeWidget({
      filters: [currentDesktopFilter]
    }, cb);
  }
  subscribeWidget({ filters = [], isSubscribedAllEvents = false }, handler) {
    if (filters.length === 0 && !isSubscribedAllEvents) {
      return
    }
    const filterObjs =
      isSubscribedAllEvents
        ? [AllEventsFilter]
        : [...filters.map((f) => new WidgetEventFilter(f))]
    // there was await, but why?
    this._socket.emit(SocketEvents.WIDGET_SUBSCRIBE, {
      filters: filterObjs,
    });
    this.createWidgetHandlers(filterObjs, handler)
  }
  unsubscribeWidget({ filters = [], isSubscribedAllEvents = false }, handler) {
    if (filters.length === 0 && !isSubscribedAllEvents) {
      return
    }
    const filterObjs =
      isSubscribedAllEvents
        ? [AllEventsFilter]
        : [...filters.map((f) => new WidgetEventFilter(f))]
    this._socket.emit(SocketEvents.WIDGET_UNSUBSCRIBE, {
      filters: filterObjs,
    });
    this.destroyWidgetHandlers(filterObjs, handler)
  }

  processEvent(roomEvent) {
    const eventName = roomEvent.roomEventName
    const event = roomEvent.event
    const handlerOptions = this._handlers.get(eventName) || [];
    const matchedHandlers = handlerOptions.filter((options) => options.filter.isMatchEvent(event))
    if (matchedHandlers.length === 0) {
      return
    }
    for (const handlerOptions of matchedHandlers) {
      const preparedEvent = handlerOptions.filter.prepareEvent(event)
      if (isPromise(handlerOptions.handler)) {
        // асинхронный обработчик
        handlerOptions.handler(preparedEvent).then(() => {});
      } else {
        handlerOptions.handler(preparedEvent);
      }
    }
  }

  getEventNames (filter) {
    return filter.getEventNames()
  }

  createWidgetHandlers (filters, handler) {
    for (const filter of filters) {
      const events = this.getEventNames(filter)
      for (const eventName of events) {
        const hasHandlers = this._handlers.has(eventName)
        const handlersCached = this._handlers.get(eventName) || [];
        const handlerOptions = {
          handler,
          filter,
        }
        handlersCached.push(handlerOptions);
        this._handlers.set(eventName, handlersCached);
        if (!hasHandlers) {
          this._vue.$on(eventName, this._eventHandler);
        }
      }
    }
  }
  destroyWidgetHandlers (filters, handler) {
    for (const filter of filters) {
      const events = this.getEventNames(filter)
      for (const eventName of events) {
        const handlers = this._handlers.get(eventName) || [];
        const idx = handlers.findIndex((options) => options.handler === handler);
        if (idx >= 0) {
          handlers.splice(idx, 1);
        }
        if (handlers.length === 0) {
          this._handlers.delete(eventName);
          this._vue.$off(eventName, this._eventHandler)
        }
      }
    }
  }

}

export default Rooms
