export default {
  'wp-common': {
    labels: {
      filters: 'Filters',
    },
    buttons: {
      itemsPerPageText: 'Rows',
      filterBtn: 'Find',
      resetFilterBtn: 'Reset',
    },
    lists: {
      whiteList: 'White list',
      grayList: 'Gray list',
      blackList: 'Black list',
      noneList: 'No list',
    },
    infoMessages: {
      noDataText: 'No data available',
      noResultsText: 'No elements found. Consider changing the search query',
      loadingText: 'Loading data...',
    },
    dateFormat: 'MM/DD/YYYY',
    timeFormat: 'HH:mm',
  },
}
