export default {
  'wp-videos-video-stream' : {
    name : 'Video stream',
    settings : {
      header          : 'Header',
      videoSource     : 'Video source',
      iceServer       : 'List ICE servers with delimiter ;',
      videoServerPath : 'Video source path',
      videoWidth      : 'Video width in pixels',
      videoHeight     : 'Video height in pixels',
      videoAspectRatio: 'Video aspect ratio'

    },
    inputs : {
      videoSourceInput : 'Video source'
    }
  }
}
