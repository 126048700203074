import Vue from "vue";
import DataAccessPlugin from "./plugins/dataAccess/DataAccessPlugin";

Vue.use(DataAccessPlugin);

function $debounce(callback, limit) {
    let timeout = null;
    return function () {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(function () {
            timeout = null;
            callback();
        }, limit);
    }
}

Vue.prototype.$debounce = $debounce;

class App {
    components(...components) {
        components.forEach((component) => {
            Vue.component(component.name, component)
        })
        return this
    }

    get $da() {
        return Vue.prototype.$da
    }

    get $locale() {
        return Vue.prototype.$locale
    }
}

export default App
