export default {
  'wp-verification-panel': {
    name: 'Панель верификации',
    settings: {
      header: 'Заголовок',
      eventSettings: 'Настройки событий',
      inactiveImage: 'Изображение в ожидании',
      inactiveText: 'Текст в ожидании',
      timeout: 'Таймаут вывода (мс)',
      maxImageSize: 'Максимальный размер изображения (от 13% до 80%)',
      deviceIdList: 'Список ID источников события',
    },
    inputs: {
      refreshList: 'Обновление списка событий'
    },
    rules: {
      required: 'Значение отсутствует',
      isnumber: 'Необходимо число',
      positive: 'Значение должно быть положительным',
      maxOneHundred: 'Значение должно быть не больше "80"',
      minThirteen: 'Значение должно быть больше "13"',
      isInteger: 'Необходимо целое число'
    },
    fields: {
      fullName: 'ФИО',
      post: 'Должность',
      subdivision: 'подразделение',
      name: 'Имя',
      email: 'Email',
      telephone: 'Телефон',
      orgName: 'Организация',
      tin: 'ИНН',
      categoryEmployee: 'Категория',
      numberEmployee: 'Табельный номер',
      iec: 'КПП',
      depName: 'Отдел',

      fullNameVehicle: 'Наименование ТС',
      bodyType: 'Тип ТС',
      brand: 'Бренд ТС',
      color: 'Цвет ТС',
      licensePlate: 'Номер ТС',
      note: 'Комментарий',
      ownerName: 'Владелец ТС',
      carNumber: 'Номер',
      owner: 'Владелец'
    },
    permitFields: {
      name: 'Наименование',
      startTime: 'Действует',
      endTime: 'Действует по',
      permitType: 'Тип пропуска',
      status: 'Статус',
      enter: 'Вход',
      permitInfo: 'Информация о пропуске',
      numberAndDateOfIssue: 'Дата выдачи',
      emptyNumber: '"Номер отсутствует"',
      unknownPermitStatus: "Неизвестный статус пропуска",
    },
    permitLevelFields: {
      name: 'Уровень доступа'
    },
    categoriesEmployee: {
      Visitor: 'Гость',
      Contractor: 'Подрядчик',
      Subcontractor: 'Субподрядчик',
      Employee: 'Работник',
      Security: 'Охранник',
      Chief: 'Руководитель',
      Household: 'Домовладелец'
    },
    statuses: {
      Active: 'Активирован',
      Block: 'Заблокирован',
      Deactivated: 'Деактивирован'
    },
    accessGranted: 'Доступ разрешен',
    accessDenied: 'Доступ запрещен',
    permitLabel: 'Пропуск',
    levelUnknown: 'Неизвестно',
    from: 'от',
    with: 'с',
    until: 'до',
    in: 'в',
    time: "Время",
    source: "Источник события",
    location: "Расположение",
    unknownObject: "Неизвестный объект",
  }
}
