export default {
  'wp-videos-video-stream' : {
    name : 'Видео поток',
    settings : {
      header          : 'Заголовок',
      videoSource     : 'Источник видео',
      iceServer       : 'Список ICE серверов ( разделитель ;)',
      videoServerPath : 'Путь до сервера',
      videoWidth      : 'Ширина изображения в пикселях',
      videoHeight     : 'Высота изображения в пикселях',
      videoAspectRatio: 'Соотношение сторон изображения'
    },
    inputs : {
      videoSourceInput : 'Источник видео'
    }
  }
}
