export default {
  'wp-order-list': {
    name: 'Список заявок',
    settings: {
      configEntitiesFields: "Настройки полей при создании/просмотре",
      configVehicle: {
        fieldsToShow: {
          brand: "Марка, модель",
          photo: "Фотографии",
          category: "Тип ТС",
          color: "Цвет",
        },
      },
      configPerson: {
        fieldsToShow: {
          photo: "Фотографии",
          birthday: "Дата рождения",
          categoryLaw: "Категория гражданства",
          identityDocument: "Документ, удостоверяющий личность",
          phone: "Телефон",
          email: "Email",
          birthPlace: "Место рождения",
        },
      },
      configOrderForm: {
        showOrder: "Отображать выбранный тип заявки",
        selectedOrder: "Доступные организации для заявки",
        titlePeriodLimitSettings: 'Ограничение срока действия пропуска от текущей даты подачи заявки',
        timeDimension: {
          day: 'День',
          month: 'Месяц',
          year: 'Год',
        },
        processingPersonalData: 'Требуется согласие на обработку персональных данных',
        fieldsToShow: {
          purpose: "Цель",
        },
      },
      headers: {
        person: "Поля персоны",
        vehicle: "Поля ТС",
        order: "Поля заявки",
        visibility: "Видимость",
        required: "Обязательность"
      },
      orderType: "Тип заявки",
    },
    inputs: {
      refreshList: "Обновление реестра заявок",
    },
    outputs: {
      selectedOrder: 'Выбранная заявка'
    },
    filters: {
      statuses: 'Статусы',
      orderTypes: 'Типы заявок',
      organizations: 'Организации',
      checkpoints: 'Доступ',
      dateCreation: 'Дата подачи заявки',
      dateFormat: 'DD.MM.YYYY',
      datePassValid: 'Срок действия пропуска',

      quickFilters: {
        createdInToday: "За сегодня",
        createdInAWeek: "За неделю",
        createdInAMonth: "За месяц",
        expiredOrders: "Просроченные",
        inProcessOrders: "На согласовании",
      },
    },
    labels: {
      name: 'Реестр заявок',
      requester: 'Заявитель: ',
      dateCreation: 'Дата подачи заявки ',
      dateFrom: 'Срок действия заявки: с ',
      dateTo: 'по ',
      organization: 'Организация: ',
      number: "Номер",
      statuses: 'Статусы',
      orderTypes: "Типы заявок",
      contracts: 'Договоры',
      organizations: 'Организации',
      checkpoints: 'Доступ',
      placeholderSearchBar: 'Искать по ТС или физлицу',
      createOrderBtn: 'Создать заявку',
      createOrderGuestsBtn: 'Создать гостевую заявку',
      hasNotMatching: 'Нет этапа визирования. Пожалуйста, проверьте алгоритмы',
      orderInUndefinedState: 'Заявка в неизвестном состоянии. Пожалуйста, проверьте алгоритмы',
      noTime: 'Некорректное время. Пожалуйста, проверьте алгоритмы',
      hasNoPersonName: 'У данного физлица не обнаружено ФИО',
      hasNoVehicleName: 'У данного ТС не обнаружены данные',
      lastUpdate: 'Последнее изменение: ',
      checkPoints: 'Доступ',
      filters: {
        from: 'с',
        to: 'по',
      },
      btns: {
        recreate: "Пересоздать",
        cancel: "Отменить",
      },
      titles: {
        recreate: "Пересоздать заявку",
        selectOrderType: "Выберите тип заявки",
      },
      vehicleOrdersHeader: 'Транспортные средства',
      personOrdersHeader: 'Физлица',
      newDateTimePass: "Введите новый срок действия пропуска",
      checkSubOrders: "Проверьте состав заявки",
      shouldStayInNewOrder: "Оставить в заявке",
    },
    table: {
      headers: {
        number: {
          text: "Номер",
          sortable: true,
        },
        objects: {
          text: "Объекты",
          sortable: false,
        },
        requester: {
          text: "Заявитель",
          sortable: false,
        },
        organization: {
          text: "Организация",
          sortable: false,
        },
        waitAction: {
          text: "Ожидает визирование",
          sortable: false,
        },
        status: {
          text: "Статус",
          sortable: false,
        },
        lastStage: {
          text: "Последнее изменение",
          sortable: false,
        },
        creationDate: {
          text: "Дата создания",
          sortable: true,
        },
        orderType: {
          text: "Тип заявки",
          sortable: false,
        },
      }
    },
    orderView: {
      stageName: "Этап",
      startTime: "Время начала",
      endTime: "Время окончания",
      visedBy: "Согласующий",
    },
    errorMessages: {
      required: 'Поле обязательно для заполнения',
      numeric: 'Поле может иметь только числовое значение',
      orderCannotBeEmpty: 'Заявка не может быть пустой. Пожалуйста, добавьте ТС или физлицо',
      missingOrderId: "Отсутствует идентификатор заявки",
      missingUserOrg: "Отсутствует организация у пользователя",
      failGetOrder: "Не удалось получить заявку",
      cantCreateOrderDiffOrg: "Нельзя создавать заявки от другой организации",
      corruptedOrder: "В заявке отсутствуют данные",
      recreate: {
        emptyOrder: "Пересоздание невозможно: заявка {0} пуста",
        corruptedSubOrders: "Пересоздание невозможно: в заявке {0} отсутствуют данные о",
        corruptedVehicles: "транспортных средствах",
        corruptedPersons: "физлицах",
      },
      failCreateVehicle: "Не удалось создать ТС",
      failCreatePerson: "Не удалось создать физлицо",
      failLoadOrg: "Не удалось загрузить организации",
      failLoadPerson: "Не удалось загрузить персон",
      failLoadVehicle: "Не удалось загрузить машины",
    },
    timeFormat: 'DD.MM.YYYY HH:mm',
    dateFormat: 'DD.MM.YYYY',
  },
}
