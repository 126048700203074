const DesktopTranslations = {
  header: "Desktops",
  newWidget: "New widget",
  newDesktop: "New desktop",
  saved: "Changes saved",
  exit: "All unsaved changes will be lost",
  saveError: "Error saving changes",
  unknownWindgetType: "Unknown widget type",
  searchPanel: {
    search: "Search"
  },
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: true,
      },
      ref_desktop_group: {
        text: "Group",
        sortable: false,
      },
      sort_order: {
        text: "Sort order",
        sortable: true,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Remove desktop",
    }
  },
  columns: {
    name: "Name",
    column_count: "Column count",
    row_count: "Row count",
    roles: "Roles",
    group: "Group",
    sort_order: "Sort order",
  },
  rows: {
    row_count: "Row count",
    group: "Group",
  },
  buttons: {
    save: "Save",
    exit: "Exit",
  },
  errors: {
    required: "Field is required",
    integerRequired: "An integer value is required",
    valueInRange: "The value must be in the range (1, 200)",
    lengthMaximum: "Value cannot exceed 50 characters"
  },
  add: "Add desktop",
  edit: "Edit desktop",
  remove: "Remove desktop?"
}

export default DesktopTranslations
