<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ $t(title) }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ $t(message) }}</v-card-text>
      <v-card-text v-if="options.form && options.form.items && options.form.items.length" class="pb-0 pa-4">
        <v-textarea
          v-for="item in options.form.items" :key="item.field"
          v-model="options.form.model[item.field]"
          :label="item.label"
          persistent-placeholder
          outlined
          dense
          autofocus
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">{{ $t('common.dialog.ok') }}</v-btn>
        <v-btn color="grey" text @click.native="cancel">{{ $t('common.dialog.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'app-toir-confirm-dialog',

  data() {
    return {
      dialog  : false,
      resolve : null,
      reject  : null,
      message : null,
      title   : null,
      options : {
        color  : 'primary',
        width  : 350,
        zIndex : 200,
        form   : null
      }
    };
  },

  methods: {
    open(message, title = null, options = null) {
      this.dialog = true;
      this.title = title || 'app.name';
      this.message = message;

      this.options = Object.assign(this.options, options);
      if (!options || !options.form) {
        this.options.form = null;
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
}
</script>
