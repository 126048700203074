export default {

  'wp-toir-device-list': {
    name: 'Список оборудования'
  },

  'wp-toir-map': {
    name: 'Карта'
  },

  'wp-toir-schedule': {
    name: 'График обслуживания'
  },

  'wp-toir-regulation-list': {
    name: 'Регламентные работы'
  },

  'wp-toir-guide-list': {
    name: 'Справочники'
  },

  'wp-toir-report-list': {
    name: 'Отчеты'
  },

  'wp-toir-device-state-report': {
    name: 'Состояние оборудования'
  },

  'wp-toir-expendable-material-report': {
    name: 'Расходные материалы'
  },

  'wp-toir-schedule-task-report': {
    name: 'Перечень работ'
  },

  locale : {
    code : 'ru',
    name : 'Русский'
  },

  app : {
    name : 'ЕВА Сервис',
  },

  common : {
    table : {
      noDataText : 'Нет данных'
    },
    dialog : {
      ok     : 'OK',
      cancel : 'Отмена'
    },
    loading : 'Загрузка...',
    images : {
      clickToChange : 'Изменить изображение'
    }
  },

  guides : {
    deviceTypes : {
      header  : 'Типы оборудования',
      columns : {
        icon_id : ' ',
        name    : 'Название',
        code    : 'ESM тип'
      },
      add    : 'Добавление типа оборудования',
      edit   : 'Редактирование типа оборудования',
      remove : 'Удалить тип оборудования ?'
    },
    deviceStates : {
      header  : 'Состояния оборудования',
      columns : {
        name    : 'Название',
        code    : 'Код'
      },
      add    : 'Добавление состояния оборудования',
      edit   : 'Редактирование состояния оборудования',
      remove : 'Удалить состояния оборудования ?'
    },
    expendableMaterials : {
      header  : 'Расходные материалы',
      columns : {
        name         : 'Название',
        unit_type_id : 'Единицы измерения',
      },
      add    : 'Добавление расходного материала',
      edit   : 'Редактирование расходного материала',
      remove : 'Удалить расходный материал ?'
    },
    qualificationTypes : {
      header  : 'Квалификации персонала',
      columns : {
        name         : 'Название',
        description  : 'Описание'
      },
      add    : 'Добавление квалификации персонала',
      edit   : 'Редактирование квалификации персонала',
      remove : 'Удалить квалификацию персонала ?'
    },
    regulationTypes : {
      header  : 'Типы регламента',
      columns : {
        name  : 'Название',
        code  : 'Код',
        color : 'Цвет'
      },
      add    : 'Добавление типа регламента',
      edit   : 'Редактирование типа регламента',
      remove : 'Удалить тип регламента ?'
    },
    technicalMeans : {
      header  : 'Инструменты и оборудование',
      columns : {
        name : 'Название'
      },
      add    : 'Добавление инструмента или оборудования',
      edit   : 'Редактирование инструмента или оборудования',
      remove : 'Удалить инструмент или оборудование ?'
    },
    unitTypes : {
      header  : 'Единицы измерения',
      columns : {
        name       : 'Название',
        short_name : 'Сокращение'
      },
      add    : 'Добавление единицы измерения',
      edit   : 'Редактирование единицы измерения',
      remove : 'Удалить единицу измерения ?'
    },
  },

  regulations : {
    periods : {
      header  : 'Периоды',
      columns : {
        period_type  : 'Тип',
        period_value : 'Количество'
      },
      add    : 'Добавление периода',
      edit   : 'Редактирование периода',
      remove : 'Удалить период ?'
    },
    qualifications : {
      header  : 'Квалифицированные специалисты',
      columns : {
        name  : 'Название',
        count : 'Кол-во'
      }
    },
    technicalMeans : {
      header  : 'Инструменты и оборудование',
      columns : {
        name  : 'Название',
      }
    },
    expendableMaterials: {
      header  : 'Расходные материалы',
      columns : {
        name  : 'Название',
        count : 'Кол-во',
        unit : 'Единицы измерения'
      }
    },
    actions: {
      header  : 'Порядок действий',
      columns : {
        id          : '#',
        name        : 'Название',
        count       : 'Кол-во',
        description : 'Описание',
      },
      add    : 'Добавление действия',
      edit   : 'Редактирование действия',
      remove : 'Удалить действие ?'
    }
  },

  devices : {
    regulations : {
      header : 'Обслуживание',
      columns : {
        ts_from              : 'Назначено',
        regulation_name      : 'Регламент',
        state                : 'Состояние',
        done_ts              : 'Выполнено',
        done_user_name       : 'Выполнил',
        regulation_type_name : 'Тип регламента',
        regulation_duration  : 'Длит., чел./мин.'
      }
    },
    fault_periods : {
      header : 'Периоды неисправности',
      columns : {
        ts_from : 'Начало периода',
        ts_to   : 'Окончание периода'
      }
    }
  },

  reports : {
    expendableMaterials : {
      header  : 'Расходные материалы',
      columns : {
        year           : 'Год',
        name           : 'Название',
        count_total    : 'Общее количество',
        count_expended : 'Израсходовано',
        unit_type_name : 'Единицы измерения'
      }
    },
    scheduleTasks : {
      header : 'Перечень работ',
      columns : {
        device_name          : 'Название',
        device_type_name     : 'Тип',
        ts_from              : 'Назначено',
        regulation_name      : 'Регламент',
        state                : 'Состояние',
        done_ts              : 'Выполнено',
        done_user_name       : 'Выполнил',
        regulation_type_name : 'Тип',
        regulation_duration  : 'Длит., чел./мин.',
      }
    },
    deviceStates : {
      header : 'Состояние оборудования',
      columns : {
        name             : 'Название',
        device_type_name : 'Тип',
        path             : 'Зона',
        task_total       : 'Задачи: всего',
        task_done        : 'Завершено',
        task_expired     : 'Просрочено',
        task_not_done    : 'На исполнение',
        state            : 'Состояние',
        fault_periods    : 'Периоды неисправности'
      }
    }
  },

  schedule : {
    dayRegulations : {
      header  : 'Список задач',
      columns : {
        device_name         : 'Устройсто',
        device_type_name    : 'Тип устройства',
        regulation_name     : 'Проводимая работа',
        regulation_duration : 'Длит., чел./мин.',
        state_name          : 'Состояние',
        done_ts             : 'Выполнено',
        done_user_name      : 'Выполнил',

      },
      add    : 'Добавление типа устройства',
      edit   : 'Редактирование типа устройства',
      remove : 'Удалить тип устройства ?'
    }
  }
}
