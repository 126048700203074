
const PermissionsTranslations = {
  header: "Права доступа",
  labels: {
    titles: {
      services: "Сервисы",
      permissionRules: "Правила доступа",
      createRule: "Создать правило",
      editRule: "Редактировать правило",
    },
    name: "Название правила",
    autoGenName: "Автосгенерированное имя",
    isAllOrganizationsAvailable: "Доступно всем организациям",
    organization: "Доступно организации",
    roleGroups: "Доступно группам ролей",
    isAddedRoleWithoutGroups: "Добавить в список роли без группы",
    roles: "Доступно ролям",
    isAllUsersAvailable: "Доступно всем пользователям",
    user: "Доступно пользователю",
    visionType: "Область видимости",
    visionTypeValues: {
      member: "Только сущности своей организации",
      all: "Все сущности",
      owner: "Созданные текущим пользователем",
    },
    action: "Доступные методы",
    isAllResourcesAvailable: "Доступны все сущности данного сервиса",
    resource: "Доступны сущности сервиса",
    save: "Сохранить",
    dialogs: {
      deleteRuleDialog: {
        text: "Это действие нельзя отменить",
        title: "Удалить правило доступа?",
      },
      closeRuleFormDialog: {
        text: "Все несохраненные изменения будут потеряны",
        title: "Закрыть?",
      },
    },
  },
  errorMessages: {
    role_group: {
      required: "Необходимо заполнить это поле, либо выбрать роли или пользователя",
    },
    role: {
      required: "Необходимо заполнить это поле, либо выбрать группы ролей или пользователя",
    },
    user: {
      required: "Необходимо заполнить это поле, либо выбрать роли или группы ролей",
      requiredRoles: "Пожалуйста, сначала выберите роли из выбранных групп",
    },
    required: "Требуется ввести значение",
    maxLength: "Значение должно занимать не более {0} символов",
  },
  infoMessages: {
  },
}

export default PermissionsTranslations
