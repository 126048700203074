<template>
  <div class="eva-spacer"/>
</template>

<script>
export default {
  name: 'eva-spacer'
}
</script>

<style lang="less">
.eva-spacer {
  flex-grow: 1;
}
</style>
