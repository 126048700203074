export default {
  "wp-file-card": {
    name: "File card",
    title: {
      update: "Update file",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Details",
        name: "File name",
      },
    },
    errors: {
      required: "Required field",
    },
  }
};
