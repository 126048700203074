import Vue from "vue";

class EvaPlugin {

  constructor(app) {
    this.app = app;
  }

  install() {

  }

  async init() {

  }

  initReactive({ data, computed, watch }) {
    let settings = {};
    if (data) {
      settings.data = () => data;
      this.defineInstanceProperties(data);
    }
    if (computed) {
      settings.computed = computed;
      this.defineInstanceProperties(computed);
    }
    if (watch) {
      settings.watch = watch;
    }
    this.instance = new Vue(settings);
  }

  defineInstanceProperties(keys) {
    Object.keys(keys).forEach((key) => {
      Object.defineProperty(this, key, {
        get: () => this.instance[key],
        set: (value) => this.instance[key] = value
      })
    });
  }
}

export default EvaPlugin;
