<template>
  <div class="eva-column-layout" :class="tClass">

    <slot></slot>

  </div>
</template>

<script>
export default {
  name: 'eva-column-layout',

  props: {
    scrollY: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tClass() {
      return this.scrollY ? 'eva-test' : ''
    }
  }
}
</script>

<style lang="less">
.eva-column-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
