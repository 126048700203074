import Plugin from "../plugin";
import LocalStoragePlugin from "./local-storage/local-storage-plugin";
import SessionStoragePlugin from "./session-storage/session-storage-plugin";
import UserStoragePlugin from "./user-storage/user-storage-plugin";
import LocalePlugin from "./locale/locale-plugin";
import ToastPlugin from "./toast/toast-plugin";
import HttpPlugin from "./http/http-plugin";
import EventBusPlugin from "./event-bus/event-bus-plugin";
import DataAccessPlugin from "./data-access/data-access-plugin";
import ThemePlugin from "./theme/theme-plugin";
import PanelPlugin from "./panel/panel-plugin";
import ToolsPlugin from "./tools/tools-plugin";
import RoomPlugin from "./rooms/rooms-plugin";

const plugins = [
  LocalStoragePlugin,
  SessionStoragePlugin,
  UserStoragePlugin,
  LocalePlugin,
  ToolsPlugin,
  ToastPlugin,
  HttpPlugin,
  EventBusPlugin,
  DataAccessPlugin,
  ThemePlugin,
  PanelPlugin,
  RoomPlugin,
];

export default {
  install(Vue) {
    plugins.forEach((p) => p.install(Vue));
  },
  async init(app) {
    await Plugin.init(app);
  }
}
