const IconListTranslations = {
  header: "Icons",
  add: "Add",
  edit: "Edit",
  remove: "Remove icon?",
  save: "Save",
  cancel: "Cancel",
  table: {
    headers: {
      name: {
        text: "Name",
        sortable: false,
      },
      extension: {
        text: "Extension",
        sortable: false,
      },
      download_url: {
        text: "Url",
        sortable: false,
      },
      actions: {
        text: "",
        sortable: false,
      },
    },
  item: {
      remove: "Remove icon {0}",
    }
  },
  columns: {
    name: "Name",
  },
  dialogs: {
    changeCheckpoint: {
      title: "Are you sure to change icon?",
    },
  },
  options: {
    itemsPerPage: "Items per page",
  },
  errors: {
    required: "Field is required",
  }
}

export default IconListTranslations
