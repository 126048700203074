export default {
  "wp-pass-office": {
    name: "Бюро пропусков",
    inputs: {
      refresh: "Источник события",
    },
    tabs: {
      persons: "Персоны",
      vehicles: "Транспорт",
      passes: "Пропуска",
      violations: "Нарушения",
    },
    labels: { 
      createPassBtn: "Новый пропуск",
      createSubject: "Создание субъекта",
      createPermit: "Создание пропуска",
      createCard: "Создание карты",
      createViolation: "Создание нарушения",
      editSubject: "Редактирование субъекта",
      updateSubject: "Обновление субъекта",
      editPermit: "Редактирование пропуска",
      editCard: "Редактирование карты",
      editViolation: "Редактирование нарушения",
      name: "Название",
      createCardBtn: "Создать карту",
      save: 'Сохранить',
      update: 'Обновить',
      filters: {
        organizationList: "Организации",
        securityList: "Список",
        violations: "Нарушения",
        actions: {
          apply: "Применить",
          clear: "Очистить",
        }
      },
      card: {
        title: "Карта",
        topLabel: "Информация",
        cardType: "Тип карты",
        format: "Формат",
        code: "Код карты",
        levelPermit: "Уровни доступа",
        unpin: {
          title: 'Открепить карту',
          confirm: 'Вы действительно хотите открепить карту?',
        },
        remove: {
          title: 'Удалить карту',
          confirm: 'Вы действительно хотите удалить карту?',
        },
      },
      violation: {
        topLabel: "Информация",
        violationTime: {
          date: "Дата нарушения",
          time: "Время нарушения",
        },
        violationType: "Тип нарушения",
        name: "Автор",
        note: "Комментарий",
      },
      personInformation: {
        topLabel: "Информация",
        name: "ФИО",
        firstName: "Имя",
        middleName: "Отчество",
        lastName: "Фамилия",
        birthDate: "Дата рождения",
        subject: "Субъект",
        status: "Статус",
        attachPhoto: "Прикрепите фото",
        organization: "Организация",
        position: "Должность",
        securityList: "Список",
        documentLabel: "Документ",
        citizenship: "Гражданство",
        document: {
          seriesDocument: 'Серия',
          numberDocument: 'Номер',
          dateDocument: 'Дата выдачи',
          departmentCode: 'Код подразделения',
          issuedDocument: '​Учреждение, выдавшее документ',
        },
      },
      vehicleInformation: {
        topLabel: "Информация",
        name: "Информация о ТС",
        licensePlate: "Гос. номер",
        securityList: "Список",
        brand: "Марка ТС",
        color: "Цвет ТС",
        type: "Тип ТС",
        subject: "Субъект",
        status: "Статус",
        inspect: "Досмотрена",
        attachPhoto: "Прикрепите фото",
      },
      orderFilter: {
        datePeriodPass: "Срок действия",
        from: "с",
        to: "по",
        format: "DD.MM.YYYY",
      },
    },
    filters: {
      violations: {
        haveViolations: {
          text: "Есть нарушения",
          value: 'violation_count__gt__0',  
        },
        noViolations: {
          text: "Нет нарушений",
          value: 'violation_count__eq__0',
        }
      },
    },
    table: {
      title: "Список карт",
      headers: {
        ref_card_type: {
          text: "Тип карты",
          sortable: false,
        },
        ref_format: {
          text: "Формат",
          sortable: false,
        },
        code: {
          text: "Код карты",
          sortable: true,
        },
        actions: {
          sortable: false,
        }
      },
      unpinTip: "Вы уверены, что хотите открепить карту?",
      removeTip: 'Вы уверены, что хотите удалить карту?',
      actionsTips: {
        removeItem: "Удалить {0}",
      },
    },
    searchPanel: {
      search: "Поиск",
    },
    actions: {
      saveButton: "Сохранить",
    },
    preferences: {
      dateFormat: "DD.MM.YYYY",
      timeFormat: 'HH:mm',
    },
    listItem: {
      headers: {
        'validated.name': {
          text: "Субъект",
          sortable: true,
        },
        'validated.ref_organization': {
          text: "Организация",
          sortable: true,
        },
        ref_security_list: {
          text: "Список",
          sortable: true,
        },
        active_permit: {
          text: "Пропуск",
          sortable: true,
        },
        violation_count: {
          text: "Нарушения",
          sortable: true,
        },
        actions: {
          text: "",
          sortable: false,
        },
      },
      remove: {
        title: 'Удалить субъект',
        confirm: 'Вы действительно хотите удалить субъект?',
      },
      removeActive: {
        title: 'Удалить субъект',
        confirm: 'Вы действительно хотите удалить субъект? У субъекта есть активный пропуск.',
      },
      editTip: 'Редактировать субъект',
      removeTip: 'Вы уверены, что хотите удалить субъект?',
      actionsTips: {
        removeItem: "Удалить {0}",
      },
    },
    dialogListItem: {
      headers: {
        name: {
          text: "Субъект",
          sortable: true,
        },
        organization: {
          text: "Организация",
          sortable: false,
        },
        ref_security_list: {
          text: "Список",
          sortable: true,
        },
        active_permit: {
          text: "Пропуск",
          sortable: true,
        },
      },
    },
    passItem: {
      headers: {
        number: {
          text: "Номер",
          sortable: true,
        },
        status: {
          text: "Статус",
          sortable: false,
        },
        validity: {
          text: "Срок действия",
          sortable: false,
        },
        cards: {
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      remove: {
        title: 'Удалить пропуск',
        confirm: 'Вы действительно хотите удалить пропуск?',
      },
      removeActive: {
        title: 'Удалить пропуск',
        confirm: 'Вы действительно хотите удалить активный пропуск?',
      },
      status: "Статус",
      createBtn: "Создать пропуск",
      haveCards: "Есть карты доступа",
      removeTip: 'Вы уверены, что хотите удалить пропуск?',
      actionsTips: {
        removeItem: "Удалить {0}",
      },
      format: "DD.MM.YYYY",
    },
    violationItem: {
      headers: {
        type: {
          text: "Тип",
          sortable: false,
        },
        violation_time: {
          text: "Время",
          sortable: true,
        },
        closed: {
          text: "Статус",
          sortable: false,
        },
        actions: {
          sortable: false,
        }
      },
      open: "Открыто",
      closed: "Закрыто",
      close: {
        title: 'Закрыть нарушение',
        confirm: 'Вы действительно хотите закрыть нарушение?',
      },
      createBtn: "Создать нарушение",
      closeTip: 'Вы действительно хотите закрыть нарушение?',
      actionsTips: {
        closeViolation: "Закрыть нарушение {0}",
      },
      timeFormat: 'DD.MM.YYYY HH:mm',
    },
  },
};
