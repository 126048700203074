<template>
  <div class="eva-repeater-footer">

    <v-select
      v-model="itemsPerPage"
      :disabled="options.state.loading"
      :items="itemsPerPages"
      outlined
      class="eva-repeater-footer__select"
    >

      <template v-slot:item="{ item }">
        <eva-text bold :text="$eva.$t('$t.core.paginations.itemsPerPage', [item])"/>
      </template>

      <template v-slot:selection="{ item }">
        <eva-text bold :text="$eva.$t('$t.core.paginations.itemsPerPage', [item])"/>
      </template>

    </v-select>

    <eva-text bold :text="fromToItems"/>

    <eva-spacer/>

    <v-pagination
      v-if="pageCount > 1"
      v-model="page"
      :disabled="options.state.loading"
      :length="pageCount"
      total-visible="7"
    />

  </div>
</template>

<script>
export default {
  name: 'eva-repeater-footer',

  props: {
    settings: {
      type: Object
    },
    options: {
      type: Object
    }
  },

  data() {
    return {
      itemsPerPages: [
        15,
        20,
        50,
        100
      ]
    }
  },

  computed: {
    itemsPerPage: {
      get() {
        return this.options.filter.limit;
      },
      set(value) {
        console.log('itemsPerPage', value)
        this.options.filter.offset = 0;
        this.options.filter.limit = value;
      }
    },
    page: {
      get() {
        return Math.floor(this.options.filter.offset / this.options.filter.limit) + 1;
      },
      set(value) {
        console.log('page', value)
        this.options.filter.offset = this.options.filter.limit * (value - 1);
      }
    },
    fromToItems() {
      let r;
      if (this.options.state.count) {
        let startItem = this.options.filter.offset + 1;
        let endItem = this.options.filter.offset + this.options.state.count;
        if (endItem > this.options.state.total) {
          endItem = this.options.state.total;
        }
        r = this.$eva.$t(`$t.core.paginations.fromToItems`, [
          `${startItem} - ${endItem}`,
          this.options.state.total,
        ]);
      } else {
        r = this.$eva.$t(`$t.core.paginations.fromToItems`, [0, 0]);
      }
      console.log(r)
      return r;
    },
    pageCount() {
      return Math.ceil(this.options.state.total / this.options.filter.limit);
    }
  }
}
</script>

<style lang="less">
.eva-repeater-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: @eva-header;
  gap: @eva-padding;
  .eva-repeater-footer__select {
    max-width : 150px;
    margin: 0!important;
    .v-input__append-inner,
    .v-input__prepend-inner {
      margin-top: 6px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;;
      min-height: 36px!important;
    }
    .v-text-field__details {
      display: none!important;;
    }
    .v-select__selections {
      padding: 0!important;
    }
  }
  .v-pagination {
    li:last-child {
      .v-pagination__navigation:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
