import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":_vm.width,"scrollable":_vm.scrollable,"content-class":_vm.classes},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"app-dialog__card"},[_c(VToolbar,{staticStyle:{"flex-grow":"0"},attrs:{"dark":"","color":"primary","dense":"","flat":""}},[_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t(_vm.title)))])],1),_vm._v(" "),_c(VCardText,{staticClass:"pa-4 pb-0",staticStyle:{"flex-grow":"1","overflow-y":"auto"}},[_vm._t("default")],2),_vm._v(" "),(_vm.error)?_c(VCardText,{staticClass:"pa-4 py-0"},[_c(VAlert,{attrs:{"type":"error"}},[_vm._v("\n                "+_vm._s(_vm.error)+"\n            ")])],1):_vm._e(),_vm._v(" "),_c(VCardActions,{staticClass:"pt-0",staticStyle:{"flex-grow":"0"}},[_vm._l((_vm.customActions),function(action){return _c(VBtn,{key:action.name,attrs:{"color":action.color,"text":""},nativeOn:{"click":function($event){return _vm.runAction(action)}}},[_vm._v("\n                "+_vm._s(action.text)+"\n            ")])}),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.dialog.ok')))]),_vm._v(" "),_c(VBtn,{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('common.dialog.cancel')))])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }