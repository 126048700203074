import WidgetEventFilter from "./widget-event-filter";

const AllEventsFilter = new WidgetEventFilter({
  eventCatalogTypes: [{ name: 'AllEventsFilter' }],
  eventCatalogs: [],
  directObjs: [],
  subjects: [],
  name: "Фильтр на все события",
  uuid: `AllEventsFilter-default_filter`,
});


export {
  AllEventsFilter,
}
