export default {
  'wp-events-event-detail': {
    name: 'Event detail',
    settings: {
      header: "Header"
    },
    emptyEvent: 'Event is not selected',
    labels: {
      typeEvent: 'Type',
      time: 'Time',
      description: 'Description',
      object: 'Object',
      subject: 'Subject',
      location: 'Location',
    },
    format: 'YYYY-DD-MM HH-mm'
  }
}
