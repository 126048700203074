<template>
  <div class="app-tree">
    <div class="app-tree__header">
      <v-toolbar dense>
        <h5>{{ header }}</h5>

        <v-spacer/>

        <v-text-field
            v-if="search"
            v-model="searchText"
            outlined
            dense
            clearable
            class="v-btn--search"
            prepend-inner-icon="mdi-magnify"
        />

        <div class="app-table__actions">
          <slot name="actions"/>
        </div>
      </v-toolbar>
    </div>
    <div class="app-tree__main mt-1">
      <div class="app-tree__tree">
        <v-card class="app-tree__tree-inner">
          <v-treeview
              v-if="!itemsLoading"
              :active.sync="active"
              :items="items"
              :open.sync="open"
              :load-children="getLoadChildren()"
              :selectable="selectable"
              selection-type="independent"
              v-model="groupSelection"
              activatable
              dense
              :search="searchText"
          >

            <template v-slot:prepend="{ item }">
              <div v-if="item.icon_id" class="v-image-container">
                <img :src="`./api/v1/images/${item.icon_id}`">
              </div>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </template>

            <template v-slot:append="{ item }">
              <v-icon :color="item.append_color">{{ item.append_icon }}</v-icon>
            </template>

          </v-treeview>
        </v-card>
      </div>
      <div class="app-tree__content">
        <div class="app-tree__content-inner">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'app-tree',

  props : {
    header     : {},
    adapter    : {},
    parentIcon : {},
    childIcon  : {},
    search : {
      type    : Boolean,
      default : false
    },
    predefined: {

    },
    isLoadChildren: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    filter: {

    },
  },

  data() {
    return {
      items          : [],
      active         : [],
      open           : [],
      selected       : {},
      itemsLoading   : false,
      searchText     : '',
      groupSelection : []
    }
  },

  watch : {
    active() {
      this.selected = this.active.length && this.findById(this.active[0]) || null;
      this.$emit('on-select', this.selected);
    },
    searchText() {
      if (!this.search) {
        return;
      }
      this.loadItems();
    },
    predefined() {
      this.loadItems();
    },
    groupSelection : {
      handler() {
        this.$emit('on-group-select', this.groupSelection);
      },
      deep : true
    }
  },

  methods: {
    async loadItems() {
      this.itemsLoading = true;
      this.selected     = null;

      let filter = {
        order_by: 'name'
      };
      if (this.search) {
        let searchText = (this.searchText || '').trim();
        if (searchText) {
          filter.search = searchText;
        }
      }

      if (this.predefined) {
        this.items = this.predefined;
      } else {
        try {
          let { items } = await this.adapter.list(filter);
          this.items = items;
        } catch (error) {
          this.error = error;
        }
      }

      this.itemsLoading = false;
    },

    getLoadChildren() {
      if (this.isLoadChildren) {
        return (item) => this.loadChildren(item);
      }  else {
        return null;
      }
    },

    async loadChildren(item) {
      return new Promise((resolve) => {
        let done = async (children) => {
          children.forEach((c) => c.isChild = true);
          item.children = [...children];
          resolve();
        }
        this.$emit('load-children', { item, done });
      });
    },

    async reloadItem(parent_id, child_id) {
      let parent = this.findById(parent_id);
      await this.loadChildren(parent);
      if (this.open.indexOf(parent_id) < 0) {
        this.open.push(parent_id);
      }
      if (child_id) {
        this.active = [child_id];
      } else if (child_id !== false) {
        this.active = [parent_id];
      }
    },

    async selectItem(id) {
      if (id) {
        this.active = [id];
      } else {
        this.active = [];
      }
    },

    clearGroup() {
      this.groupSelection = [];
    },

    openItem(...ids) {
      if (ids) {
        for (let i = 0; i < ids.length; i++) {
          if (this.open.indexOf(ids[i]) < 0) {
            this.open.push(ids[i]);
          }
        }
      }
    },

    findById(sid, children = null) {
      if (!sid) {
        return null;
      }
      if (!children) {
        children = this.items;
      }
      let item = children.find((i) => i.id === sid);
      if (item) {
        return item;
      }
      for (let i = 0; i < children.length; i++) {
        item = children[i].children && this.findById(sid, children[i].children);
        if (item) {
          return item;
        }
      }
      return null;
    },
  },

  mounted() {
    this.loadItems();
  },
}
</script>

<style lang="less">
.app-tree {
  height: 100%;
  display: flex;
  flex-direction: column;

  .v-image-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 2px;
    img {
      display: flex;
      margin: auto;
      max-width: 27px;
      max-height: 27px;
    }
  }

  .v-treeview--dense {
    .v-treeview-node__root {
      min-height: 32px;
    }
  }

  .app-tree__header {
    display: flex;
    height: 48px;
    .v-toolbar {
      width: 100%;
      .v-btn--search {
        max-width : 350px;
      }
      .v-input__slot {
        margin-bottom: 0!important;
        min-height: 36px!important;
      }
    }
  }
  .app-tree__main {
    display: flex;
    flex-direction: row;
    height: calc(100% - 52px);
    .app-tree__tree {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 360px;
      flex-shrink: 0;
      .app-tree__tree-inner {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
        min-height: 0;
        .v-treeview {
          width: 100%;
          .v-treeview-node__label {
            cursor: pointer;
          }
        }
      }
    }
    .app-tree__content {
      display: flex;
      width: calc(100% - 360px);
      flex-direction: column;
      height: 100%;
      .app-tree__content-inner {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
        min-height: 0;
        padding: 8px;
      }
    }
  }
}
</style>