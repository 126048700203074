export default {
  core: {
    locales: {
      ru: {
        header: 'Russian'
      },
      en: {
        header: 'English'
      },
    },

    themes: {
      dark: {
        header: 'Dark'
      },
      light: {
        header: 'Light'
      }
    },

    commands: {
      navigateMain: {
        header: 'Back to desktops'
      },
      navigateAdmin: {
        header: 'Admin panel'
      },
      logout: {
        header: 'Logout'
      }
    },

    tables: {
      noData: 'No data',
      loading: 'Loading...'
    },

    paginations: {
      itemsPerPage: '{0} rows',
      fromToItems: 'Shown {0} of {1} entries'
    },

    errors: {
      fields: {
        require: 'Field cannot be empty',
        regex: 'Field does not match given expression',
        email: 'Field is not an email address',
        passport: 'Field is not a passport number'
      }
    }
  }
}
