export default {
  "wp-level-permit-card": {
    name: "Уровень доступа",
    notSet: "Не задано",
    title: {
      new: "Новый уровень доступа",
      update: "Редактирование уровня доступа",
    },
    actions: {
      saveButton: "Сохранить",
    },
    labels: {
      details: {
        topLabel: "Сведения",
        name: "Название",
      },
    },
  },
};
