export default {
  "wp-org-card": {
    name: "Organization card",
    title: {
      new: "New organization",
      update: "Edit contractor",
      myOrg: "My organization",
      selection: "Add contractor"
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      details: {
        topLabel: "Details",
        contractor: "Name",
        tin: "TIN",
        iec: "IEC",
        psrn: "PSRN",
        partner: "Partners",
      },
      contacts: {
        topLabel: "Contacts",
      },
      address: {
        topLabel: "Address",
      },
      contracts: {
        topLabel: "Contracts",
        withoutContract: "Operate without contract",
        archive: "Archive",
      },
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        minLength: "Length must be more than {0} characters",
        maxLength: "Length must be less than {0} characters",
        numeric: 'Value can be only numeric',
        lengthTin: "Length must be {0} or {1} characters",
        minLengthTin: "Length must be {0} characters",
        maxLengthTin: "Length must be {0} characters",
        lengthIec: "Length must be {0} characters",
        lengthPsrn: "Length must be {0} or {1} characters",
        minLengthPsrn: "Length must be {0} characters",
        maxLengthPsrn: "Length must be {0} characters",
        uniqueTin: "Organization with such data already exists",
        uniqueIec: "Organization with such data already exists",
        uniquePsrn: "Organization with such data already exists",
        certainSymbols: "Invalid character entered",
      },
      tin: {
        unique: "There is another organization with that TIN",
      },
    },
  },
};
