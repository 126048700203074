<template>
  <div class="eva-table1">
    <div v-if="!hideHeader" dense class="eva-table__header v-toolbar1">
      <h3>{{ header }}</h3>

      <v-text-field
        v-if="search"
        v-model="searchText"
        outlined
        dense
        clearable
        class="v-btn--search"
        prepend-inner-icon="mdi-magnify"
      />

      <div class="app-table__actions pa-4">
        <v-btn
          v-if="filter2"
          fab
          dark
          x-small
          color="grey"
          @click="showFilter"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>

      <v-spacer/>

      <slot/>

      <div v-if="enableAction('add') || download" class="app-table__actions pa-4">
        <v-btn
          v-if="enableAction('add')"
          fab
          dark
          x-small
          color="green"
          @click="addItem()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-btn
          v-if="download"
          fab
          dark
          x-small
          color="primary"
          @click="downloadItems"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>

    </div>

    <v-card-text class="pa-0 app-table__content">
      <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>

      <v-data-table
        v-model="checked"
        dense
        hide-default-footer
        :headers="headers"
        :no-data-text="$t('common.table.noDataText')"
        :items="items"
        :loading="itemsLoading"
        :loading-text="$t('common.loading')"
        :options.sync="options"
        :server-items-length="itemsCount"
        @page-count="pageCount = $event"
        item-key="id"
        fixed-header
        :expanded.sync="expanded"
        class="app-table__table"
      >

        <template v-slot:header.selects>
          <v-simple-checkbox
            v-model="checkAll"
          />
        </template>

        <template v-slot:item="data">
          <tr @click="editItem(data.item)" :class="itemColor" :style="{ 'background-color' : data.item === selected ? '#00BCD4' : null }">

            <template v-for="header in headers">
              <td
                v-if="header.value === 'actions'"
                class="app-table__cell"
              >
                <div v-if="!readonly" class="app-table__actions">
                  <v-tooltip
                    v-for="(rowAction, index) in rowActions"
                    :key="index"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="d-inline-flex"
                      >
                        <v-btn
                          v-if="!rowAction.visible || rowAction.visible(data.item)"
                          fab
                          :dark="!(rowAction.enable && !rowAction.enable(data.item))"
                          :disabled="rowAction.enable && !rowAction.enable(data.item)"
                          x-small
                          :color="rowAction.color"
                          @click.stop="rowAction.handle(data.item)"
                        >
                          <v-icon>{{ rowAction.icon }}</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <pre>{{ typeof rowAction.tooltip == 'string' ? rowAction.tooltip : rowAction.tooltip(data.item) }}</pre>
                  </v-tooltip>

                  <v-btn
                    v-if="enableAction('edit')"
                    fab
                    dark
                    x-small
                    color="primary"
                    @click.stop="editItem(data.item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="enableAction('delete')"
                    fab
                    dark
                    x-small
                    color="red"
                    @click.stop="removeItem(data.item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
              <td
                v-else-if="header.value === 'selects'"
                class="app-table__cell"
              >
                <v-simple-checkbox
                  v-if="!canSelect || canSelect(data.item)"
                  :value="checked.indexOf(data.item) >= 0"
                  @input="checkItem(data.item)"
                />
              </td>
              <td
                v-else
                class="app-table__cell"
              >
                <slot :name="`item.${header.value}`" :item="data.item">
                  {{ data.item[header.value] }}
                </slot>
              </td>
            </template>

          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <slot name="expanded-item" :item="item" v-if="expandable"/>
        </template>

      </v-data-table>

    </v-card-text>

    <v-spacer/>

    <div v-if="pagination && pageCount > 1" class="app-table__pagination">
      <v-pagination
        v-model="options.page"
        :length="pageCount"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: "eva-table1",

  props: {
    resource: {},
    columns: {},
    adapter: {},
    adapterArguments: {},
    predefined : {},
    pagination : {
      type    : Boolean,
      default : true
    },
    openFirst: {
      type    : Boolean,
      default : false
    },
    expandable : {
      type    : Boolean,
      default : false
    },
    itemColor :  {},
    selectable : {
      type    : Boolean,
      default : false
    },
    readonly : {
      type    : Boolean,
      default : false
    },
    search : {
      type    : Boolean,
      default : false
    },
    filter : {
      type    : Object,
      default : null
    },
    onLoad : {
      type : Function,
      default : null
    },
    download : {
      type : Boolean,
      default : false
    },
    hideHeader : {
      type : Boolean,
      default : false
    },
    rowActions : {
      type: Array,
      default: () => []
    },
    showSelect : {
      type: Boolean,
      default: false
    },
    canSelect: {
      type: Function
    },
    filter2: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {

      initialized: false,

      items: [],
      itemsCount: 0,
      itemsLoading: false,

      headers: [],

      options: {
        page: 1,
        itemsPerPage: 16,
        sortBy: [],
        sortDesc: [],
        mustSort: true
      },

      expanded: [],

      searchText : '',

      pageCount: 0,
      totalVisible: 7,

      error: null,

      selected : null,

      checked : [],
      checkAll: false
    };
  },

  watch: {

    checkAll() {
      if (!this.showSelect) {
        return;
      }
      if (this.checkAll) {
        this.checked = [...this.items];
      } else {
        this.checked = [];
      }
    },

    columns: {
      handler() {
        let result = [];
        let sort = null;
        if (this.showSelect) {
          result.push({
            value: 'selects',
            sortable: false
          });
        }
        if (this.columns) {
          this.columns.forEach((column) => {
            if (column.name === 'actions') {
              result.push({
                text: "",
                value: column.name,
                sortable: false,
              });
            } else {
              result.push({
                text: this.$t(`${this.resource}.columns.${column.name}`),
                value: column.name,
                sortable: column.sort !== false,
              });
              if (column.sort !== false && (!sort || column.sort)) {
                sort = column;
              }
            }
          });
        }

        if (sort) {
          this.options.sortBy = [sort.name];
          this.options.sortDesc = (sort.sort && sort.sort.toUpperCase() === 'DESC') ? [true] : [];
        }

        this.headers = result;
      },
      deep: true,
      immediate: true
    },

    options: {
      async handler() {
        await this.loadItems('options');
      },
      deep: true,
    },

    searchText() {
      if (!this.search) {
        return;
      }
      this.debounceLoadItems();
    },

    async predefined() {
      await this.loadItems();
    },

    filter : {
      handler() {
        this.loadItems('filter');
      },
      deep : true
    },

    checked: {
      handler() {
        this.$emit('checked', this.checked);
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    header() {
      return this.$t(`${this.resource}.header`) + ` (${this.itemsCount})`;
    }
  },

  methods: {
    async loadItems(msg) {
      if (!this.initialized) {
        return;
      }

      this.checked = [];
      this.itemsLoading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filter = {
        limit  : itemsPerPage,
        offset : (page - 1) * itemsPerPage,
      }
      if (sortBy && sortBy.length) {
        filter.order_by = sortBy
          .map((key, index) => (sortDesc[index] ? `!${key}` : key))
          .join(",");
      }
      if (this.search) {
        let searchText = (this.searchText || '').trim();
        if (searchText) {
          filter.search = searchText;
        }
      }
      if (this.filter) {
        Object.keys(this.filter).forEach((key) => {
          filter[key] = this.filter[key];
        });
      }

      if (this.predefined) {
        this.items      = this.onLoad ? this.onLoad(this.predefined) : this.predefined;
        this.itemsCount = this.items.length;
      } else {
        try {
          if (this.adapter) {
            let args = this.adapterArguments || [];
            let {items, count} = await this.adapter.list(filter, ...args);
            this.items = this.onLoad ? this.onLoad(items) : items;
            this.itemsCount = count;

            if (this.openFirst && this.items && this.items.length) {
              this.editItem(this.items[0]);
            }
          }
        } catch (error) {
          this.error = error;
        }
      }

      if (this.expandable) {
        this.expanded = [...this.items];
      }

      this.itemsLoading = false;
    },

    addItem() {
      let done = async ({ item }) => {
        if (!item) {
          return;
        }

        try {
          this.error = null;
          if (this.predefined) {
            this.predefined.push(item);
          } else {
            await this.adapter.add(item);
          }
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      };
      this.$emit("add", { done });
    },

    editItem(toEdit) {
      this.$emit('item-click', toEdit);

      if (this.selectable) {
        this.selected = toEdit;
        this.$emit('select', toEdit);
      } else if (!this.readonly) {
        let done = async ({item}) => {
          if (!item) {
            return;
          }

          try {
            this.error = null;
            if (this.predefined) {
              Object.assign(toEdit, item);
            } else {
              await this.adapter.update(item);
            }
          } catch (error) {
            this.error = error;
          }

          await this.loadItems();
        };

        this.$emit("edit", {item : Object.assign({}, toEdit), done});
      }
    },

    async removeItem(item) {
      if (!item) {
        return;
      }

      if (await this.$root.$confirm(`${this.resource}.remove`)) {
        try {
          this.error = null;
          if (this.predefined) {
            let index = this.predefined.indexOf(item);
            if (index >= 0) {
              this.predefined.splice(index, 1);
            }
          } else {
            await this.adapter.remove(item);
          }
        } catch (error) {
          this.error = error;
        }

        await this.loadItems();
      }
    },

    expandItem(item) {
      let index = this.expanded.indexOf(item);
      if (index >= 0) {
        this.expanded.splice(index, 1);
      }  else {
        this.expanded.push(item);
      }
    },

    enableAction(name) {
      if (this.readonly) {
        return false;
      }
      let actions = this.columns && this.columns.find((c) => c.name === "actions");
      return actions && actions[name] !== false;
    },

    async downloadItems() {

      const { sortBy, sortDesc,  } = this.options;

      let filter = { };
      if (sortBy && sortBy.length) {
        filter.order_by = sortBy
          .map((key, index) => (sortDesc[index] ? `!${key}` : key))
          .join(",");
      }
      if (this.search) {
        let searchText = (this.searchText || '').trim();
        if (searchText) {
          filter.search = searchText;
        }
      }
      if (this.filter) {
        Object.keys(this.filter).forEach((key) => {
          filter[key] = this.filter[key];
        });
      }

      let args = this.adapterArguments || [];
      await this.adapter.download(this.$t(`${this.resource}.header`), filter, ...args);
    },

    checkItem(item) {
      let index = this.checked.indexOf(item);
      if (index >= 0) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(item);
      }
    },

    showFilter() {
      this.$panel.openComponent('div', {}, {
        size: 25,
        needCloseConfirm: true,
        title: 'Фильтр'
      });
    }
  },

  mounted() {
    this.debounceLoadItems = this.$debounce(() => this.loadItems(), 500);

    this.$nextTick(() => {
      this.initialized = true;
      this.loadItems('mounted');
    });

  },
};
</script>

<style lang="less">
.eva-table1 {
  height: 100%;
  display: flex!important;
  flex-direction: column;
  padding: 8px;

  .eva-table__header {
    display: flex;
    align-items: center;
  }

  th {
    white-space: nowrap!important;
  }

  .app-table__content {
    height: calc(100% - 100px);
  }

  .app-table__table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .app-table__pagination {
    height: 48px;
  }

  .v-toolbar1 {
    flex-grow: 0;
    .v-btn--search {
      max-width : 350px;
      margin-left: 24px!important;
    }
    .v-input__slot {
      margin-bottom: 0!important;
    }
    .v-toolbar__content {
      padding-right: 0!important;
    }
  }

  .app-table__cell {
    cursor: pointer;
  }
  .app-table__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    .v-btn {
      margin-left: 8px;
      &.v-btn--fab {
        width: 32px !important;
        height: 32px !important;
        .v-icon {
          font-size: 16px!important;
        }
      }
    }
  }

  .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 0!important;
  }
  .app-table__filter {
    display: flex;
    flex-direction: row;
    .v-input {
      width: 0;
      min-width: 150px;
    }
  }
}
</style>
