export default {
  'wp-common': {
    labels: {
      filters: 'Фильтры',
    },
    buttons: {
      itemsPerPageText: 'Строк',
      filterBtn: 'Найти',
      resetFilterBtn: 'Очистить',
    },
    lists: {
      whiteList: 'Белый список',
      grayList: 'Серый список',
      blackList: 'Черный список',
      noneList: 'Без списка',
    },
    infoMessages: {
      noDataText: 'Данные не найдены',
      noResultsText: 'Результатов по запросу нет. Попробуйте поменять запрос',
      loadingText: 'Загрузка данных...',
    },
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'HH:mm',
  }
}
