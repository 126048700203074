const TYPE_ENTITIES = Object.freeze({
  persons: "persons",
  vehicles: "vehicles",
  contracts: "contracts",
  files: "files",
});

const DEFAULT_CARD_CONFIGS = Object.freeze({
  [TYPE_ENTITIES.persons]: {
    visibility: {
      photo: true,
      birthday: true,
      categoryLaw: true,
      identityDocument: true,
      phone: true,
      email: true,
      birthPlace: true,
    },
    required: {
      photo: true,
      birthday: true,
    },
  },
  [TYPE_ENTITIES.vehicles]: {
    visibility: {
      brand: true,
      photo: true,
      category: true,
      color: true,
    },
    required: {
      brand: true,
    },
  },
  [TYPE_ENTITIES.contracts]: {},
});


export {
  TYPE_ENTITIES,
  DEFAULT_CARD_CONFIGS,
}
