export default {
  'wp-person-card': {
    name: 'Person card',
    notSet: "Not set",
    title: {
      new: "New person",
      update: "Update person",
    },
    actions: {
      saveButton: "Save",
    },
    labels: {
      information: {
        topLabel: 'Information',
        firstName: 'First name',
        lastName: 'Last name',
        middleName: 'Middle name (if presence)',
        attachPhoto: 'Attach photo',
        birthDate: 'Birth date',
        birthPlace: 'Birth place',
        organization: 'Organization',
        categoryLaw: {
          Citizen: 'Citizen of RF',
          ForeignCitizen: 'Foreign Citizen',
          Statelessness: 'Statelessness'
        },
        categoryLawLabel: "Category law",
        docType: {
          Passport: "Passport",
          ForeignPassport: "Foreign passport",
          DriverLicense: "Driver license"
        }
      },
      contacts: {
        topLabel: 'Contacts',
        telephone: 'Phone number',
        email: 'Email',
      },
      document: {
        topLabel: 'Personal identification document',
        typeDocument: "Type document",
        seriesDocument: 'Series',
        numberDocument: 'Number',
        issuedDocument: 'Issued departament',
        dateDocument: 'Issue date',
      },
      chiefBus: 'Bus chief',
      securityList: "Security list",
      workPermitDocs: 'Permit documents to work',
    },
    preferences: {
      dateFormat: 'YYYY-DD-MM'
    },
    validationRules: {
      defaults: {
        required: "Value is required",
        maxLength: "Length must be less than 50 characters",
        correctNumber: "Phone number is incorrect",
        email: "Email address is incorrect",
      },
    },
  }
}
