const EssenceTranslations = {
  header: "Сущности",
  exit: 'Выйти',
  removeTitle: 'Подтвердите',
  buttons: {
    add: 'Добавить',
    select: 'Выбрать из справочника'
  },
  tabs: {
    widget: 'Виджеты',
    contract: 'Договора',
    personOrder: 'Заявки на ФЛ',
    vehicleOrder: 'Заявки на ТС',
    card: 'Карты',
    organization: 'Организации',
    person: 'Персоны',
    permitPerson: 'Пропуска ФЛ',
    permitVehicle: 'Пропуска ТС',
    desktop: 'Рабочие столы',
    task: 'Реестр заданий',
    order: 'Реестр заявок',
    catalog: 'Справочники',
    taskType: 'Типы заданий',
    catalogType: 'Типы справочников',
    checkPoint: 'Точки доступа',
    vehicle: 'Транспорт',
    levelPermit: 'Уровни доступа', 
  },
  table: {
    person: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "ФИО",
          sortable: false,
        },
      },
      item: {
        remove: 'Вы уверены, что хотите удалить персону?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    permitPerson: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        ref_person_Person: {
          text: "Субъект",
          sortable: false,
        },
        status: {
          text: "Статус",
          sortable: false,
        },
        dates: {
          text: "Даты действия",
          sortable: false,
        },
        
      },
      item: {
        remove: 'Вы уверены, что хотите удалить пропуск?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    permitVehicle: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Субъект",
          sortable: false,
        },
        status: {
          text: "Статус",
          sortable: false,
        },
        dates: {
          text: "Даты действия",
          sortable: false,
        },    
      },
      item: {
        remove: 'Вы уверены, что хотите удалить пропуск?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    card: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        code: {
          text: "Код",
          sortable: false,
        },
        format: {
          text: "Формат",
          sortable: false,
        },    
      },
      item: {
        remove: 'Вы уверены, что хотите удалить карту?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    levelPermit: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        type: {
          text: "Тип",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },    
      },
      item: {
        remove: 'Вы уверены, что хотите удалить уровень доступа?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    task: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        number: {
          text: "Номер",
          sortable: false,
        },
        type: {
          text: "Тип",
          sortable: false,
        },
        author: {
          text: "Автор",
          sortable: false,
        },
        performer: {
          text: "Исполнитель",
          sortable: false,
        },    
      },
      item: {
        remove: 'Вы уверены, что хотите удалить задание?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    taskType: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        title: {
          text: "Название",
          sortable: false,
        },   
      },
      item: {
        remove: 'Вы уверены, что хотите удалить тип задания?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      }
    },
    catalog: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        sysName: {
          text: "Название",
          sortable: false,
        },
        description: {
          text: "Описание",
          sortable: false,
        },   
      },
      item: {
        remove: 'Вы уверены, что хотите удалить справочник?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    catalogType: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        type: {
          text: "Тип",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },  
      },
      item: {
        remove: 'Вы уверены, что хотите удалить тип справочника?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    vehicle: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },
        licensePlate: {
          text: "Гос. номер",
          sortable: false,
        },  
      },
      item: {
        remove: 'Вы уверены, что хотите удалить транспорт?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    organization: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },
        archived: {
          text: "Архивирована",
          sortable: false,
        },
        withoutContract: {
          text: "Без договора",
          sortable: false,
        },  
      },
      item: {
        remove: 'Вы уверены, что хотите удалить организацию?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    contract: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },
        number: {
          text: "Номер",
          sortable: false,
        },
        active: {
          text: "Активный",
          sortable: false,
        },  
      },
      item: {
        remove: 'Вы уверены, что хотите удалить договор?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    widget: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },
        desktop: {
          text: "Рабочий стол",
          sortable: false,
        }, 
      },
      item: {
        remove: 'Вы уверены, что хотите удалить виджет?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    desktop: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        },
        row_count: {
          text: "Кол-во строк",
          sortable: false,
        }, 
        column_count: {
          text: "Кол-во столбцов",
          sortable: false,
        },  
      },
      item: {
        remove: 'Вы уверены, что хотите удалить рабочий стол?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    checkPoint: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        name: {
          text: "Название",
          sortable: false,
        }, 
      },
      item: {
        remove: 'Вы уверены, что хотите удалить точку доступа?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    order: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        number: {
          text: "Название",
          sortable: false,
        },
        dateValidFrom: {
          text: "Дата заявки",
          sortable: false,
        },
        numPersonOrder: {
          text: "Кол-во физлиц",
          sortable: false,
        },
        numVehicleOrder: {
          text: "Кол-во транспорта",
          sortable: false,
        }, 
      },
      item: {
        remove: 'Вы уверены, что хотите удалить заявку?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    personOrder: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        ref_person: {
          text: "ID физлица",
          sortable: false,
        },
        dates: {
          text: "Время действия пропуска",
          sortable: false,
        },
      },
      item: {
        remove: 'Вы уверены, что хотите удалить заявку?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
    vehicleOrder: {
      headers: {
        id: {
          text: "ID",
          sortable: false,
        },
        ref_vehicle_Vehicle: {
          text: "ID транспорта",
          sortable: false,
        },
        dates: {
          text: "Время действия пропуска",
          sortable: false,
        },
      },
      item: {
        remove: 'Вы уверены, что хотите удалить заявку?',
        actionsTips: {
          removeItem: "Удалить {0}",
        }
      },
    },
  },
  components: {
    searchPanel: {
      search: "Поиск"
    },
  },
  errors: {
    persons: {
      list: 'Не удалось загрузить список персон',
      item: 'Не удалось загрузить персону',
      update: 'Не удалось изменить персону',
      add: 'Не удалось добавить персону',
      remove: 'Не удалось удалить персону'
    },
    permits: {
      list: 'Не удалось загрузить список пропусков',
      item: 'Не удалось загрузить пропуск',
      update: 'Не удалось изменить пропуск',
      add: 'Не удалось добавить пропуск',
      remove: 'Не удалось удалить пропуск'
    },
  }
}

export default EssenceTranslations
