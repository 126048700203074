import WidgetMixin from './widget-mixin'

function installMixin(component) {
  if (!component.mixins) {
    component.mixins = []
  }
  component.mixins.push(WidgetMixin)
}

class Widget {
  constructor(component, configComponent, fields, defaultFilters = []) {
    let { settings, inputs, outputs, group } = (fields || {})
    installMixin(component)
    this.component = component
    this.configComponent = configComponent
    this.widget_type = component.name
    this.settings = settings || {}
    this.inputs = inputs || {}
    this.outputs = outputs || {}
    this.group = group
    this.defaultFilters = defaultFilters
  }
}

export default Widget
