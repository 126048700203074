const UserTranslations = {
  header: "Users",
  table: {
    headers: {
      username: {
        text: "Login",
        sortable: true,
      },
      ref_person: {
        text: "Full name",
        sortable: true,
      },
      ref_organization: {
        text: "Organization",
        sortable: true,
      },
      email: {
        text: "Email",
        sortable: true,
      },
      ref_roles: {
        text: "Roles",
        sortable: false,
      },
      actions: {
        sortable: false,
      },
    },
    item: {
      remove: "Remove user {0}",
    }
  },
  columns: {
    organization: "Organization",
    person: "Person",
    email: "Email",
    username: "Username",
    roles: "Roles",
    disabled: "Disabled",
    admin: "Administrator",
    password: "Password",
    name: "Full name",
    firstName: "First name",
    lastName: "Last name",
    middleName: "Middle name",
    fullscreen: "Fullscreen",
    defaultDesktop: "Default desktop",
    trusted: "Trusted user",
  },
  add: "Add user",
  edit: "Edit user",
  remove: "Remove user?",
  save: "Save",
  cancel: "Cancel",
  searchPanel: {
    search: "Search"
  },
  errors: {
    required: "Field is required",
    email: "Incorrect email",
    uniqueEmail: "User with this email is already existed",
    uniqueLogin: "User with this login is already existed",
    uniquePerson: "User with this person is already existed",
    withoutSpaces: "Spaces does not available in password",
    validatePassword: "Password length is between 6 and 64 characters. The password can contain characters: !#@$^()_*",
    passwordNotChanged: "Password wasn't changed. Please, check the correction of old password and try again",
  },
  changePassword: "Update password",
  passwordChangedSuccess: "password is successfully updated",
  newPassword: "New password",
  oldPassword: "Old password",
  dialogs: {
    changePerson: {
      title: "Are you sure to change person?",
      bodyMsg: "Data from fields: {0} will be removed.",
    },
  },
  options: {
    itemsPerPage: "items per page",
  },
}

export default UserTranslations
